import { Grid } from "@mui/material"
import type React from "react"
import type { ReactNode } from "react"
import { isMobile } from "react-device-detect"

// Define props for our responsive layout component
interface ResponsiveLayoutProps {
  imageContent: ReactNode
  textContent: ReactNode
}

const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = ({ imageContent, textContent }) => {
  if (isMobile) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {textContent}
        </Grid>
        <Grid item xs={12}>
          {imageContent}
        </Grid>
      </Grid>
    )
  }

  // For desktop, show side by side
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {textContent}
      </Grid>
      <Grid item xs={12} md={6}>
        {imageContent}
      </Grid>
    </Grid>
  )
}

export { ResponsiveLayout }

