import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useTranslationContext } from '../../../../hooks/translations/translationsProvider';

interface ActionDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    content: string;
    amount: string;
    user: string;
}

const ActionDialog: React.FC<ActionDialogProps> = ({ open, onClose, onConfirm, title, content, amount, user }) => {
  const { t } = useTranslationContext();
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogContent>{user} - {amount}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("Annulla")}
        </Button>
        <Button onClick={onConfirm} color="error">
          {t("Conferma")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;

