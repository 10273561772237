import { useEffect } from "react";
import { Box, Avatar, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import Login from '@mui/icons-material/Login'
import { delay } from "../utilities/delay";
import { useAuth } from 'react-oidc-context';
import { BLUE_MAIN } from "../theme/colors";
import { useParams } from "react-router-dom";
import { getFromLocalStorage, setToLocalStorage } from '../helpers/localStorage';
import { useWaiter } from "../hooks/waiter/WaiterProvider";
import settings from "../utilities/settings";

export default function RegisterCompany () {
    const { t } = useTranslation();
    const auth = useAuth();
    const { userRef } = useParams();
    const ReturnUrl = encodeURIComponent(settings.Domains.ServiceClient + "/company/register/callback")
    const {setWaiterView} = useWaiter();
    let referral: string | undefined = undefined;

    useEffect(()=>{
        setWaiterView(true);
        if(userRef){
            setToLocalStorage("Referral", userRef); // scrive
            referral = userRef
        }else{
            referral = getFromLocalStorage("Referral");
        }
        delay(1000).then(()=>{
            setWaiterView(false);
            const url = referral ? settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&UserRef=${referral}`: settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}`
            window.location.href = url
        })
    },[])

    return (
        <Box sx={{
            display:'flex',
            width:'100%',
            alignItems:'center',
            flexDirection:'column'
        }}>

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: BLUE_MAIN, color: 'white', alignItems:'center' }}>
                <Login sx={{fontSize:50}} />
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                {t("Registrazione Aziende o Associazioni")}
            </Typography>
            
            
        </Box>
    )
}