
import { IDepositRequestModel, IDepositResponseModel, IDepositStatusRequestModel } from "../../models/deposit";
import httpClient from "../../utilities/http-client";

export const depositRequest = async (model: IDepositRequestModel) => {
	try {
		const { data } = await httpClient.post<IDepositResponseModel>(`/tokens/deposit/`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const depositStatusRequest = async (model: IDepositStatusRequestModel) => {
	try {
		const { data } = await httpClient.put(`/tokens/deposit/`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};


// export const withdrawConfirm = async (model: IWithdrawConfirmModel) => {
// 	try {
// 		const { data } = await httpClient.post<IWithdrawResponseModel>(`/tokens/withdraw/confirm`, model);
// 		return data;
// 	} catch (e:any) {
// 		throw e.response
// 	}
// };


// export const withdrawReject = async (model: IWithdrawConfirmModel) => {
// 	try {
// 		const { data } = await httpClient.post<IWithdrawResponseModel>(`/tokens/withdraw/reject`, model);
// 		return data;
// 	} catch (e:any) {
// 		throw e.response
// 	}
// };