import React, { createContext, useState, useContext, ReactNode } from 'react';
import { IDrawerItem } from './models';
interface IDrawerContext {
    openDrawer: (items:IDrawerItem[][],title:string) => void
    isOpen: boolean
    menuItems: (IDrawerItem[] | null)[],
    title: string
    closeDrawer: () => void
    openOrCloseDrawer: (items: (IDrawerItem[]|null)[], title:string) => void
}
const DrawerContext = createContext<IDrawerContext | undefined>(undefined);

export const useDrawer = () => {
    const ctx = useContext(DrawerContext);
    if(!ctx) {
        throw new Error('Drawer Context Error');
    }
    return ctx;
}

interface DrawerProviderProps {
	children: ReactNode
}

export const DrawerProvider:React.FC<DrawerProviderProps>  = ({ children }) => {
  const [isOpen, setDrawerOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<(IDrawerItem[] | null)[]>([]);
  const [title, setTitle] = useState<string>('');

  function openOrCloseDrawer (items: (IDrawerItem[] | null)[], title:string) {
    if(isOpen)
        closeDrawer() 
    else
        openDrawer(items,title)
  }

  const openDrawer = (items:(IDrawerItem[] | null)[], title:string) => {
    setMenuItems(items);
    setTitle(title)
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setMenuItems([]);
    setTitle('');
  };

  return (
    <DrawerContext.Provider value={{ isOpen, menuItems, title, openDrawer, closeDrawer, openOrCloseDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};