import React, { useEffect, useRef, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, Box, Checkbox, TextField, MenuItem,
  FormControlLabel,
  FormGroup,
  Grid,
  alpha
} from '@mui/material';
import { EApprovedFromAssociation, UserInfosModel } from '../../../../models/user/UserInfosModel';
import { useNavigate } from 'react-router-dom'; // Per la navigazione
import { useTranslationContext } from '../../../../hooks/translations/translationsProvider';
import { BLACK, BLUE_LIGHT_RGB_BORDER, WHITE } from '../../../../theme/colors';
import { usersPrivateList } from '../../../../hooks/actions/admin';
import { delay } from '../../../../utilities/delay';
import CustomWaiter from '../../../Waiter';
import Search from '../../../Search';
import { theme } from '../../../../theme';

interface Props {
  onUserSelect: (user: UserInfosModel) => void;
}

const PrivateUsersTable: React.FC<Props> = ({
  onUserSelect,
}) => {
  const [users, setUsers] = useState<UserInfosModel[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [enabledFilter, setEnabledFilter] = useState<boolean | null>(null);
  const [approvalFilter, setApprovalFilter] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchWaiter, setSearchWaiter] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const {t} = useTranslationContext();
  const minChars = 2;

  const fetchUsers = () => {
    usersPrivateList(page, rowsPerPage, searchTerm, approvalFilter, enabledFilter)
      .then(response => {
        console.log("users", response.users)
        setUsers(response.users);
        setTotalRecords(response.totalRecords);
      })
      .catch(e => console.error(e));

    delay(500).then(()=>{
      setSearchWaiter(false);
    })
  };

  useEffect(() => {
    fetchUsers();
  },[]); // ricerca all'avvio

   useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    setSearchWaiter(true);
    debounceTimeout.current = setTimeout(() => {
      fetchUsers();
      debounceTimeout.current = null;
    }, 1200);
  }, 
  [page, rowsPerPage, enabledFilter, approvalFilter, searchTerm]);

  const handleInput = (newString: string) => {
    if (newString.length < minChars) {
      setSearchTerm(null);
      return;
    }
    if (newString === searchTerm) return;
    if (newString.length > 20) return;
    setSearchTerm(newString);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (value: boolean | null) => {
    setEnabledFilter((prev) => (prev === value ? null : value));
  };

  useEffect(()=>{
    console.log("filtro utenti", enabledFilter)
  },[enabledFilter])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', padding: 4 }}>
        <Grid container>
          <Grid item xs={12} sm={6} display={'flex'}>
            <Search 
                handleInput={handleInput}
                search={searchTerm!!}
                setSearch={setSearchTerm}
            />
            
            <div style={{
                paddingTop:'3px',
                paddingLeft:'3px',
                width:'50px',
                height:'35px'
            }}>
                {searchWaiter && <CustomWaiter size={30} width={40}/>}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label={t("Approvazione")}
              value={approvalFilter === null ? "" : approvalFilter} 
              onChange={(e) => setApprovalFilter(e.target.value === '' ? null : parseInt(e.target.value, 10))}
              variant="outlined"
              sx={{ 
                width: '80%',
                height:'35px',
                backgroundColor: alpha(theme.palette.primary.light, 0.05),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                },
                '& .MuiInputLabel-root': {
                  marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                  color:'grey'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius:'5px',
                  border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                },
              }}
              InputProps={{
                style: {
                  height: '35px', // Imposta altezza anche alla radice del componente
                  
                },
              }}
            >
              <MenuItem value="">
                <em>{t("Tutti gli status")}</em>
              </MenuItem>
              {Object.keys(EApprovedFromAssociation).map((key) => {
                const typedKey = key as keyof typeof EApprovedFromAssociation;

                if (isNaN(Number(typedKey))) {
                  const value = EApprovedFromAssociation[typedKey];
                  const displayValue = value === 0 ? 0 : value;

                  return (
                    <MenuItem key={typedKey} value={displayValue}>
                      {typedKey === 'FirstRequest' ? 'FirstRequest' : typedKey}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}mt={2}>
            <FormGroup row sx={{justifyContent:'center'}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFilter === true}
                            onChange={() => handleFilterChange(true)}
                            color="primary"
                            sx={{
                              '&. MuiCheckbox-root': {
                              },
                            }}
                        />
                    }
                    label={
                        <span style={{ color: "black", fontSize: '0.8rem' }}>
                            {t("Utenti Abilitati")}
                        </span>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFilter === false}
                            onChange={() => handleFilterChange(false)}
                            color="primary"
                            sx={{
                              '&.MuiCheckbox-root': {
                              },
                            }}
                        />
                    }
                    label={
                        <span style={{ color: "black", fontSize: '0.8rem' }}>
                            {t("Utenti Disabilitati")}
                        </span>
                    }
                />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper} sx={{boxShadow:5}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Nome")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Cognome")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Email")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Status")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Abilitato")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Codice Fiscale")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Data di Nascita")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Indirizzo")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Comune di Nascita")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Stato di Nascita")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("CAP")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Provincia")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Nazione")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Referral Code")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Data Eliminazione dalla Associazione")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.userid} onClick={() => onUserSelect(user)} sx={{
                backgroundColor: user.enabledUser && user.approvedFromAssociation === EApprovedFromAssociation.ApprovedFromAssociation ? 'RGB(0,255,0,0.15)' : 
                                !user.enabledUser && user.approvedFromAssociation === EApprovedFromAssociation.FirstRequest ? WHITE :
                                !user.enabledUser || user.approvedFromAssociation === EApprovedFromAssociation.RemovedFromAssociation ? 'RGB(255,0,0,0.15)' : WHITE,
                cursor:'pointer'
              }}>
                <TableCell>{user.privateInfo?.nome}</TableCell>
                <TableCell>{user.privateInfo?.cognome}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{t(EApprovedFromAssociation[user.approvedFromAssociation ?? 0])}</TableCell>
                <TableCell>{user.enabledUser ? t('Sì') : t('No')}</TableCell>
                <TableCell>{user.privateInfo?.codiceFiscale}</TableCell>
                <TableCell>{user.privateInfo?.born ? new Date(user.privateInfo?.born).toLocaleDateString() : ''}</TableCell>
                <TableCell>{user.privateInfo?.address}</TableCell>
                <TableCell>{user.privateInfo?.comuneDiNascita}</TableCell>
                <TableCell>{user.privateInfo?.statoDiNascita}</TableCell>
                <TableCell>{user.privateInfo?.cap}</TableCell>
                <TableCell>{user.privateInfo?.provincia}</TableCell>
                <TableCell>{user.privateInfo?.nation}</TableCell>
                <TableCell>{user.refCode}</TableCell>
                <TableCell>
                  {user.removedFromAssociation &&
                  new Date(user.removedFromAssociation).getFullYear() !== 1
                    ? new Date(user.removedFromAssociation).toLocaleDateString()
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default PrivateUsersTable;
