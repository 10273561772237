import React, {useEffect, useRef} from 'react';
import {Box, Link, Grid, Card, CardContent, Button, IconButton} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaymentForm from '../../components/PaymentForm';
import { useUserContext } from '../../hooks/auth/UserProvider';
import { checkUserEnabled } from '../../helpers/authorizedUser';
import { ILastBuyTransaction } from '../../models/buy/Buy';
import { lastBuyTransactionsRequest } from '../../hooks/actions/buy';
import LastBuyTransactionsTable from '../../components/Buy/LastBuyTransactions';

const BuyTokens = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [lastBuyTransactions, setLastBuyTransactions] = React.useState<ILastBuyTransaction[] | null>(null)
    const {checkIsAuth} = useUserContext();
    // utente autorizzato
    const [isEnabled, setIsEnabled] = React.useState(false)
    const {user, auth} = useUserContext();
    useEffect(()=>{
        if (!auth.isLoading) {
            if (!auth.isAuthenticated) {
                navigate('/');
            } else {
                if(user){
                    if(!checkUserEnabled(user)){
                        navigate('/');
                    }
                }
                setIsEnabled(true);
                setLoading(false);
                handleLastBuyTransactions().finally();
            }
        }
        checkIsAuth().finally();
    },[auth.isAuthenticated, auth.isLoading, navigate, user])
    // fine utente autorizzato

    const handleLastBuyTransactions = async (): Promise<void> => {
        await checkIsAuth();
        try {
            const resp = await lastBuyTransactionsRequest();
            setLastBuyTransactions(resp);
        } catch (e) {
            console.error(e);
        }
    };

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                flexDirection:'column'
            }}>
                {
                    auth.isAuthenticated && isEnabled &&
                    <>
                        <PaymentForm />
                        
                        {lastBuyTransactions && <>
                            <Box sx={{marginTop:'50px'}} />
                            <LastBuyTransactionsTable transactions={lastBuyTransactions} />
                        </>
                        }
                    </>
                }
            </Box>
        </>
    )
}

export default BuyTokens;