import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { checkIban } from '../../hooks/actions/redeem';
import TextFieldWithErrorPropsCustom from '../TextField/TextFieldWithErrorPropsCustom';
import { useUserContext } from '../../hooks/auth/UserProvider';

interface IbanFormProps {
  iban: string;
  setIban: React.Dispatch<React.SetStateAction<string>>;
  errorIban: boolean;
  setErrorIban: React.Dispatch<React.SetStateAction<boolean>>;

  receiverName: string;
  setReceiverName: React.Dispatch<React.SetStateAction<string>>;
  errorReceiverName: boolean;
  setErrorReceiverName: React.Dispatch<React.SetStateAction<boolean>>;
  
  bic: string;
  setBic: React.Dispatch<React.SetStateAction<string>>;
  errorBic: boolean;
  setErrorBic: React.Dispatch<React.SetStateAction<boolean>>;
}

const IbanForm: React.FC<IbanFormProps> = ({
  
    iban, setIban, errorIban, setErrorIban, 
    receiverName, setReceiverName, errorReceiverName, setErrorReceiverName, 
    bic, setBic, errorBic, setErrorBic, 

  }) => {
    const {t} = useTranslationContext();
    const [errorIbanMessage, setErrorIbanMessage] = useState("Richiesti solo lettere, numeri");
    const {user} = useUserContext();
    const handleIbanBlur = async () => {
      // Esegui chiamata API per la verifica dell'IBAN
      try {
        if(iban.length === 0){
            setErrorIban(false);
            return;
        }
        const response = await checkIban(iban);
        setErrorIban(false);
      } catch (error:any) {
        const message = t(error?.data?.message || error?.data || error || "Errore IBAN");
        setErrorIban(true);
        setErrorIbanMessage(message);
      }
    };
  
    useEffect(()=>{
      if(user){
        if(user.isprivate === true){
          setReceiverName(user.privateInfo?.Name + " " + user.privateInfo?.Surname)
        }
        else{
          if((user.companyAssociationInfo?.Name))
            setReceiverName(user.companyAssociationInfo?.Name)
        }
      }
    },[user])
  
  return (
    <div>
      <Typography variant="h6">{t("Inserisci i dettagli dell'IBAN")}</Typography>
      <TextFieldWithErrorPropsCustom
        regex={/^[a-zA-Z0-9 ,\-\/]+$/}
        label={t("Nome Cognome/Ragione Sociale")}
        value={receiverName}
        name="name"
        onChange={(value) => setReceiverName(value)}
        error={errorReceiverName}
        setError={setErrorReceiverName}
        errorText={t("Richieste lettere, numeri, spazi, virgole, -, /")}
        required={true}
        disable={false}
        props={{
          margin:"normal",
          fullWidth:true
        }}
      />
      
      <TextFieldWithErrorPropsCustom
        regex={/^[a-zA-Z0-9]+$/}
        label={t("IBAN")}
        value={iban}
        name="iban"
        onChange={(e) => {
          setErrorIbanMessage("Richiesti solo lettere, numeri")
          setIban(e.toUpperCase())
        }}
        error={errorIban}
        setError={setErrorIban}
        errorText={t(errorIbanMessage)}
        required={true}
        disable={false}
        props={{
          margin:"normal",
          fullWidth:true,
          onBlur:handleIbanBlur
        }}
      />

      <TextFieldWithErrorPropsCustom
        regex={/^[a-zA-Z0-9]+$/}
        label={t("BIC/SWIFT")}
        value={bic}
        name="bic"
        onChange={(value) => setBic(value)}
        error={errorBic}
        setError={setErrorBic}
        errorText={t("Richiesti solo lettere, numeri")}
        required={false}
        disable={false}
        props={{
          margin:"normal",
          fullWidth: true
        }}
      />
    </div>
  );
};

export default IbanForm;
