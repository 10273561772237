import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  IconButton,
  SelectChangeEvent,
  TextFieldProps,
  Grid,
  Snackbar,
  Alert
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import TextFieldWithErrorPropsCustom from '../TextField/TextFieldWithErrorPropsCustom';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import SearchNations from '../UserInfo/searchNation';
import { BLACK, WHITE } from '../../theme/colors';
import { addNewActivity, myMarket, putActivity } from '../../hooks/actions/market';
import { useWaiter } from '../../hooks/waiter/WaiterProvider';
import AlertSaveModal from '../UserInfo/AlertSaveModal';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import settings from '../../utilities/settings';
import TagManager from './TagManager';

interface Market {
  id: string;
  marketName: string;
  marketTypeActivity: string;
  marketWebSite: string;
  marketTelephone: string;
  marketMail: string;
  marketAddress: string;
  marketCAP: number | undefined;
  marketCity: string;
  marketProvince: string;
  marketState: string;
  description: string;
  importance: number | null;
  tags: string[];
  marketType: EMarketType;
  latitude: number | undefined;
  longitude: number | undefined;
}

enum EMarketType {
  Physical = 0,
  Online = 1,
  PhysicalAndOnline = 2,
}

interface MarketFormProps {
  // marketId?: string | null | undefined;
}

export default function MarketForm({}: MarketFormProps) {
  const {setWaiterView} = useWaiter();
  const [market, setMarket] = useState<Market | undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { marketId } = useParams<{ marketId: string }>();

  const navigate = useNavigate();
  const [formState, setFormState] = useState<Market>(
    market || {
      id: '',
      marketName: '',
      marketTypeActivity: '',
      marketWebSite: '',
      marketTelephone: '',
      marketMail: '',
      marketAddress: '',
      marketCAP: undefined,
      marketCity: '',
      marketProvince: '',
      marketState: '',
      description: '',
      importance: null,
      tags: [],
      marketType: EMarketType.Physical,
      latitude: undefined,
      longitude: undefined,
    }
  );
  const [marketNameError, setMarketNameError] = useState(false);
  const [marketTypeActivityError, setMarketTypeActivityError] = useState(false);
  const [marketWebSiteError, setMarketWebSiteError] = useState(false);
  const [marketTelephoneError, setMarketTelephoneError] = useState(false);
  const [marketMailError, setMarketMailError] = useState(false);
  const [marketAddressError, setMarketAddressError] = useState(false);
  const [marketCAPError, setMarketCAPError] = useState(false);
  const [marketCityError, setMarketCityError] = useState(false);
  const [marketProvinceError, setMarketProvinceError] = useState(false);
  const [marketStateError, setMmarketStateError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [latitudeError, setLatitudeError] = useState(false);
  const [longitudeError, setLongitudeError] = useState(false);
  const [modalOpen, setmodalOpen]=useState<boolean>(false);
  const [error,setError]=useState<boolean>(false);
  const [errorMsg,setErrorMsg]=useState<string>("");
  const [showPopup, setShowPopup] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: settings.Google.Maps.ApiKeyFE,
  });

  const {t} = useTranslationContext();

  useEffect(()=>{
    setWaiterView(true)
    if(marketId){
      setIsEditing(false);
      myMarket(marketId).then(resp => {
        setMarket(resp);
        setFormState(resp);
      }).catch(error => {
        console.error(error)
      })
    }else{
      setIsEditing(true);
    }
    setWaiterView(false);
  },[])

  useEffect(()=>{
    setError(false);
    setErrorMsg("");
  },[formState])
  const handleChange = (value: string, key: string) => {
    setFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeSelect = (event: SelectChangeEvent<EMarketType>) => {
    setFormState({
      ...formState,
      marketType: event.target.value as EMarketType,
    });
  };

  const handleSubmit = () => {
    setWaiterView(true);
    setmodalOpen(false);
    //controllo form
    const areNotEmpty:boolean = !marketNameError &&
                                !marketTypeActivityError &&
                                !marketWebSiteError &&
                                !marketTelephoneError &&
                                !marketMailError && 
                                !marketAddressError &&
                                !marketCAPError &&
                                !marketCityError &&
                                !marketProvinceError &&
                                !marketStateError &&

                                formState.marketName !== '' &&
                                formState.marketTypeActivity !== '' &&
                                formState.marketMail !== '' &&
                                formState.marketAddress !== '' &&
                                formState.marketCAP !== undefined &&
                                formState.marketCity !== '' &&
                                formState.marketProvince !== '' &&
                                formState.marketState !== '';

    if(!market){
      if(areNotEmpty)
      {
        addNewActivity(formState).then(resp => {
          setMarket(resp);
          setFormState(resp);
          setIsEditing(false);

          setShowPopup(true); // apertura e chiusura del popup
          setTimeout(() => {
            setShowPopup(false);
          }, 10000);

        }).catch(error => {
          setIsEditing(true);
          const errorMex = error?.response?.data?.title || 
                            error?.response?.data?.message || 
                            error?.response?.data || 
                            error?.response || 
                            t("Errore durante la modifica dei dati")
          setError(true);
          setErrorMsg(errorMex);
          console.error(error)
        })
      }else{
        setError(true);
        setErrorMsg(t("Errori di compilazione nel form"));
      }
    }else{
      if(areNotEmpty){
        putActivity(formState).then(resp => {
          setMarket(resp);
          setFormState(resp);
          setIsEditing(false);

          setShowPopup(true); // apertura e chiusura del popup
          setTimeout(() => {
            setShowPopup(false);
          }, 10000);

        }).catch(error => {
          setIsEditing(true);
          const errorMex = error?.response?.data?.title || 
                            error?.response?.data?.message || 
                            error?.response?.data || 
                            error?.response || 
                            t("Errore durante la modifica dei dati")
          setError(true);
          setErrorMsg(errorMex);
          console.error(error)
        })
      }else{
        setError(true);
        setErrorMsg(t("Errori di compilazione nel form"));
      }

    }
    setWaiterView(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    if(market){
      setIsEditing(false);
      setFormState(market);
    }
  }

  const handleCloseModal = () => {
    setmodalOpen(false);
  }

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng && isEditing) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setFormState((prev) => ({
        ...prev,
        latitude: lat,
        longitude: lng,
      }));
    }
  };

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setFormState((prev) => ({
        ...prev,
        latitude: lat,
        longitude: lng,
      }));
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'white', borderRadius: 1, margin: 'auto'}}>

        <Box
        sx={{
          display:'flex'
        }}>
          <IconButton onClick={handleBack} sx={{ mb: 2 }}>
            <ArrowBackIcon /> 
          </IconButton>
        </Box>
        
        <Typography variant="h6" sx={{ mb: 2 }}>
          {market ? 'Modifica Attività' : 'Aggiungi nuova Attività'}
        </Typography>

        <FormControl fullWidth margin="normal" variant="outlined" sx={{ height: '56px' }}>
          <InputLabel id="market-type" sx={{
            padding:'0 15px',
            backgroundColor:WHITE
          }}>{t("Tipo di Attività")}</InputLabel>
          <Select
            labelId="market-type"
            name="marketType"
            value={formState.marketType}
            onChange={handleChangeSelect}  // Collega la funzione qui
            label=""
            sx={{ height: '100%', color: BLACK, fontSize:'1.2rem' }}
            disabled={!isEditing}
          >
            <MenuItem value={EMarketType.Physical}>{t("Fisico")}</MenuItem>
            <MenuItem value={EMarketType.Online}>{t("Online")}</MenuItem>
            <MenuItem value={EMarketType.PhysicalAndOnline}>{t("Fisico e Online")}</MenuItem>
          </Select>
        </FormControl>

        <TextFieldWithErrorPropsCustom
          label={t("Nome Attività")}
          regex={/^[a-zA-Z0-9 ,\-\/]+$/}
          name="marketName"
          value={formState.marketName || ''}
          error={marketNameError}
          setError={setMarketNameError}
          errorText={t("Richieste lettere, numeri, spazi, virgole, -, /")}
          onChange={(value) => handleChange(value, 'marketName')}
          required={true}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Tipo di Attività (ex. Parrucchiera, Ristorante, Consulete, Avvocato...)")}
          regex={/^[a-zA-Z0-9 ,\-\/]+$/}
          name="marketTypeActivity"
          value={formState.marketTypeActivity || ''}
          error={marketTypeActivityError}
          setError={setMarketTypeActivityError}
          errorText={t("Richieste lettere, numeri, spazi, virgole, -, /")}
          onChange={(value) => handleChange(value, 'marketTypeActivity')}
          required={true}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Sito Web")}
          regex={/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/}
          name="marketWebSite"
          value={formState.marketWebSite || ''}
          error={marketWebSiteError}
          setError={setMarketWebSiteError}
          errorText={t("Formato sito web non corretto")}
          onChange={(value) => handleChange(value, 'marketWebSite')}
          required={false}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Telefono")}
          regex={/^\+?(\d{1,3})?[-. ]?\(?\d{1,4}\)?[-. ]?\d{1,4}[-. ]?\d{1,9}$/}
          name="marketTelephone"
          value={formState.marketTelephone || ''}
          error={marketTelephoneError}
          setError={setMarketTelephoneError}
          errorText={t("Formato telefonico non corretto")}
          onChange={(value) => handleChange(value, 'marketTelephone')}
          required={false}
          disable={!isEditing}
        />
        
        <TextFieldWithErrorPropsCustom
          label={t("Email Info")}
          regex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
          name="marketMail"
          value={formState.marketMail || ''}
          error={marketMailError}
          setError={setMarketMailError}
          errorText={t("Formato mail non corretto")}
          onChange={(value) => handleChange(value, 'marketMail')}
          required={true}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Indirizzo")}
          regex={/^[A-Za-z0-9 ,\/.-]*$/}
          name="marketAddress"
          value={formState.marketAddress || ''}
          error={marketAddressError}
          setError={setMarketAddressError}
          errorText={t("Richieste solo lettere, spazi, numeri, virgole, punti, meno e /")}
          onChange={(value) => handleChange(value, 'marketAddress')}
          required={true}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("CAP")}
          regex={/^[0-9]+$/}
          name="marketCAP"
          value={formState.marketCAP?.toString() || ''}
          error={marketCAPError}
          setError={setMarketCAPError}
          errorText={t("Richiesti solo Numeri")}
          onChange={(value) => handleChange(value, 'marketCAP')}
          required={true}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Città")}
          regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
          name="marketCity"
          value={formState.marketCity || ''}
          error={marketCityError}
          setError={setMarketCityError}
          errorText={t("Richieste lettere e spazi")}
          onChange={(value) => handleChange(value, 'marketCity')}
          required={true}
          disable={!isEditing}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Provincia")}
          regex={/^[A-Za-z]+$/}
          name="marketProvince"
          value={formState.marketProvince || ''}
          error={marketProvinceError}
          setError={setMarketProvinceError}
          errorText={t("Richieste solo Lettere")}
          onChange={(value) => handleChange(value, 'marketProvince')}
          required={true}
          disable={!isEditing}
        />

        <SearchNations 
          label={t("Stato")}
          nazione={formState.marketState || ''} 
          setNazione={(value) => handleChange(value, 'marketState')} 
          disable={!isEditing}
          error={marketStateError}
          setError={setMmarketStateError}
        />

        <TextFieldWithErrorPropsCustom
          label={t("Descrizione Attività")}
          name="description"
          value={formState.description || ''}
          error={descriptionError}
          setError={setDescriptionError}
          errorText={t("Descrizione Obbligatoria")}
          onChange={(value) => handleChange(value, 'description')}
          required={true}
          multiline={true}
          disable={!isEditing}
        />

        {market &&
          <> 
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextFieldWithErrorPropsCustom
                  label={t("Latitudine")}
                  // regex={/^[+-]?\d+(\.\d+)?$/}
                  name="latitude"
                  value={formState.latitude?.toString()}
                  error={latitudeError}
                  setError={setLatitudeError}
                  errorText={t("Solo formato numerico")}
                  onChange={(value) => setFormState((prev) => ({
                    ...prev,
                    latitude: parseFloat(value),
                  }))}
                  disable={true}
                />

                <TextFieldWithErrorPropsCustom
                  label={t("Longitudine")}
                  // regex={/^[+-]?\d+(\.\d+)?$/}
                  name="longitude"
                  value={formState.longitude?.toString()}
                  error={longitudeError}
                  setError={setLongitudeError}
                  errorText={t("Solo formato numerico")}
                  onChange={(value) => setFormState((prev) => ({
                    ...prev,
                    longitude: parseFloat(value),
                  }))}
                  disable={true}
                />
              </ Grid>

              <Grid item xs={12} md={8}>
              {isLoaded ? 
                (
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '150px', margin: '16px 0' }}
                    center={{
                      lat: formState.latitude || 0,
                      lng: formState.longitude || 0,
                    }}
                    zoom={formState.latitude && formState.longitude ? 15 : 2}
                    onClick={handleMapClick}
                  >
                    {formState.latitude && formState.longitude && (
                      <Marker
                        position={{
                          lat: formState.latitude,
                          lng: formState.longitude,
                        }}
                        draggable={isEditing}
                        onDragEnd={handleMarkerDragEnd}
                      />
                    )}
                  </GoogleMap>
                ) : (
                  <Typography>{t('Caricamento della mappa...')}</Typography>
                )}
              </Grid>
            </Grid>

            {!isEditing ? (
              <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" color="info" onClick={()=>setIsEditing(true)} sx={{
                    margin:"10px 0 0 0",
                    height: '35px',
                    color:WHITE
                  }}>
                    {t("Modifica")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Button variant="outlined" onClick={handleCancel} sx={{
                    margin:"10px 0 0 0",
                    height: '35px'
                  }}>
                    {t("Annulla")}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" color="info" onClick={() => setmodalOpen(true)} sx={{
                    margin:"10px 0 0 0",
                    height: '35px',
                    color:WHITE
                  }}>
                    {t("Salva Modifiche")}
                  </Button>
                </Grid>
              </Grid>
            )}

            <TagManager marketId={market.id} />
          </>
        }

        {!market &&
        <>
          <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleSubmit} sx={{ 
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE }}
              > 
                { t('Aggiungi Attività')}
              </Button>
            </Grid>
          </Grid>
        </>
        }
        
        {
          error && <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
            {errorMsg}
          </Typography>
        </>
        }

      </Box>

      <AlertSaveModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSubmit}
      />

      <Snackbar open={showPopup} anchorOrigin={{vertical: 'bottom', horizontal: "right" }}>
        <Alert severity="success"
          sx={{ 
            border: '2px solid green',
            marginBottom:'30px'
          }}
          onClose={() => setShowPopup(false)}>
          {t("Salvataggio dati avvenuto con successo")}
        </Alert>
      </Snackbar>
    </>
  );
}