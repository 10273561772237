import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';


const UserCredits: React.FC = () => {
  const [mainError, setMainError] = useState<string | null>(null);

  useEffect(() => {
    // fetchMe().then(resp => {
    //   setUserInfo(resp);
    // }).catch(error =>{
    //   setErrorMsg(error);
    //   setError(true);
    // });
  }, []);


  return (
    <div>
      USER CREDITS

      {mainError && 
        <>
          <Typography
            variant="body2" 
            color="error"   
            sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
            }}
            >
            {mainError}
          </Typography>
        </>
      }
    </div>
  );
};

export default UserCredits;
