import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTranslationContext } from "../../../../hooks/translations/translationsProvider";
import PrivateUsersTable from "./PrivateUserTable";
import CompanyAssociationUsersTable from "./CompanyAssociationUsersTable";
import { UserInfosModel } from "../../../../models/user/UserInfosModel";
import AdminPrivateUser from "./AdminPrivateUser";
import AdminAssociationOrCompanyUser from "./AdminAssociationOrCompanyUser";



const UserManagement: React.FC = () => {
    const {t} = useTranslationContext();
    const [selectedPrivateUser, setSelectedPrivateUser] = useState<UserInfosModel | null>(null);
    const [selectedCompanyUser, setSelectedCompanyUser] = useState<UserInfosModel | null>(null);
    return (
        <Box >
            {!selectedPrivateUser && !selectedCompanyUser ? (
                <>
                    <Typography variant="h3" gutterBottom>
                        {t("Gestione Utenti")}
                    </Typography>

                    <Divider sx={{ mb: 3, mt:3 }} />

                    <Typography variant="h5" gutterBottom>
                        {t("Utenti Privati")}
                    </Typography>
                    <PrivateUsersTable onUserSelect={setSelectedPrivateUser}/>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h5" gutterBottom>
                        {t("Aziende o Associazioni")}
                    </Typography>
                    <CompanyAssociationUsersTable onUserSelect={setSelectedCompanyUser}/>
                </>
            ): selectedPrivateUser ? (
                
                <AdminPrivateUser 
                    user={selectedPrivateUser}
                    onBack={() => setSelectedPrivateUser(null)}
                />
            ): selectedCompanyUser ? (
                <AdminAssociationOrCompanyUser 
                    user={selectedCompanyUser}
                    onBack={() => setSelectedCompanyUser(null)}
                />
            ) : null
            }
        </Box>
    );
};

export default UserManagement;
