
import { Data } from "@react-google-maps/api";
import { IBuy, IBuyRequestModel, IBuyTransactionsResponse, ILastBuyTransaction } from "../../models/buy/Buy";
import httpClient from "../../utilities/http-client";

export const buyRequest = async (model: IBuyRequestModel) => {
	try {
		const { data } = await httpClient.post<IBuy>(`/tokens/buy/`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const lastBuyTransactionsRequest = async (): Promise<ILastBuyTransaction[] | null> => {
	try {
		const { data } = await httpClient.get<ILastBuyTransaction[]>(`/tokens/buy/last`);
		if(data && data.length > 0)
			return data;
		else
			return null;
	} catch (e:any) {
		throw e.response
	}
};

export const getBuyTransactions = async (page: number, rowsPerPage: number, dataFrom: Date, dataTo: Date) => {
	try {
		const dataFromString = dataFrom.toISOString().substring(0, 16); // yyyy-MM-dd hh:mm
    	const dataToString = dataTo.toISOString().substring(0, 16); // yyyy-MM-dd

		const from = page * rowsPerPage;
		const count = rowsPerPage;
		const { data } = await httpClient.get<IBuyTransactionsResponse>(`/tokens/buy/transactions/${from}/${count}/${dataFromString}/${dataToString}`);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const getPdfBuyTransactions = async (dataFrom: Date, dataTo: Date) => {
	try {
		const dataFromString = dataFrom.toISOString().substring(0, 10); // yyyy-MM-dd hh:mm = 16
    	const dataToString = dataTo.toISOString().substring(0, 16); // yyyy-MM-dd = 10
		const response = await httpClient.get(`/tokens/buy/transactions/pdf/${dataFromString}/${dataToString}`, {
			responseType: 'blob'
		});
		const blob = response.data;
        
        const url = window.URL.createObjectURL(blob);

		// codice che fa il download automatico
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'buy-transactions.pdf'; 
        // document.body.appendChild(a);
        // a.click(); 
        // document.body.removeChild(a); 
		
		//codice che apre nel browser la finestra pdf
		window.open(url, '_blank');

		setTimeout(() => window.URL.revokeObjectURL(url), 20000);
	} catch (e:any) {
		throw e;
	}
};