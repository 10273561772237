import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Link
} from '@mui/material';
import { styled } from '@mui/system';
import { ILastBuyTransaction } from '../../models/buy/Buy';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { BLACK } from '../../theme/colors';


const LastBuyTransactionsTable: React.FC<{transactions: ILastBuyTransaction[]}> = ({ transactions }) => {
  const theme = useTheme();
  const {t} = useTranslationContext();

  return (
    <>
      <TableContainer component={Paper} sx={{boxShadow:5}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight:'bold'}}>{t("Importo Totale")}</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>{t("Importo Netto")}</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>{t("Data di ricezione pagamento")}</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>{t("Tipo di pagamento")}</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>{t("Codice transazione")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>{transaction.amount?.toFixed(transaction.decimals)} {transaction.divisa}</TableCell>
                <TableCell>{transaction.netAmount?.toFixed(transaction.decimals)} {transaction.divisa}</TableCell>
                <TableCell>{new Date(transaction.dataReceved).toLocaleDateString()}</TableCell>
                <TableCell>{transaction.buyType}</TableCell>
                <TableCell>{transaction.refCode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{marginTop:'20px'}}>
        <Link href="/buy/transactions" sx={{ color:BLACK, textDecoration: 'none' }}>
            {t("Mostra tutto")}
        </Link>
      </Box>
    </>
  );
};

export default LastBuyTransactionsTable;

