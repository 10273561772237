import React, { useEffect, useRef, useState, useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  styled,
  Grid,
  TextField,
  alpha,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTranslationContext } from '../../../../hooks/translations/translationsProvider';
import { getEnumKey } from '../../../../helpers/getEnumKey';
import { AdminBuyResponseModel, EBuyStatus, EBuyType, IBuyModifyRequestModel, IConfirmBuyRequestModels } from '../../../../models/buy/Buy';
import Search from '../../../Search';
import CustomWaiter from '../../../Waiter';
import { theme } from '../../../../theme';
import { BLUE_LIGHT_RGB_BORDER, GRAY_LIGHT_RGB_BORDER, WHITE } from '../../../../theme/colors';
import { delay } from '../../../../utilities/delay';
import { yellow } from '@mui/material/colors';
import DatePickerCustom from '../../../DatePicker/DatePickerCustom';
import dayjs, { Dayjs } from 'dayjs';
import { buyConfirmTransaction, buyModifyRecord, buyRequestList, redeemConfirmBankTransfer, redeemModifyRecord, redeemRejectBankTransfer, redeemRequestList } from '../../../../hooks/actions/admin';
import { useWaiter } from '../../../../hooks/waiter/WaiterProvider';
import { useMemo } from 'react';
import TableCellEditable from '../TableCellEditable';
import { AdminRedeemResponseModel, ETokensRedeemStatus } from '../../../../models/redeem';
import settings from '../../../../utilities/settings';
import ActionDialog from './ActionDialog';

const TabsCellHeaderCustom = styled(TableCell)(({theme})=>({
  fontWeight:'bold', 
  whiteSpace:'nowrap',
  fontSize:'0.7rem',     
  minWidth:'150px',                                
  [theme.breakpoints.up('xs')]: {
  },
}))

const TabsCellCustom = styled(TableCell)(({theme})=>({
  fontSize:'0.7rem',   
  minWidth:'150px',                                    
  [theme.breakpoints.up('xs')]: {
  },
}))

const RedeemListTable: React.FC = () => {
  const [originalData, setOriginalData] = useState<AdminRedeemResponseModel[]>([]);
  const [editableData, setEditableData] = useState<AdminRedeemResponseModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const {t} = useTranslationContext();
  const minChars = 2;
  const [searchWaiter, setSearchWaiter] = useState<boolean>(false);
  // const [typeFilter, setTypeFilter] = useState<number | null>(null);
  const [approvalFilter, setApprovalFilter] = useState<number | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const {setWaiterView} = useWaiter();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [actionHandler, setActionHandler] = useState<'confirm' | 'reject' | null>(null);
  const [indexHandler, setIndexHandles] = useState<number | null>(null);
  const [actionInfos, setActionInfos] = useState<{
    title: string;
    content: string;
    user: string;
    amount: string;
  } | null>(null);

  const openDialog = (index: number, type: 'confirm' | 'reject', amount: string, user: string) => {
    setIndexHandles(index);
    setActionHandler(type);
    setDialogOpen(true);
    
    console.log("lo hai preso tutto nel culo")
    if (type === 'confirm') {
      setActionInfos({
        title: "Conferma Invio Bonifico",
        content: "Sei sicuro di confermare che il bonifico è stato eseguito? L'operazione è irreversibile!",
        user: user,
        amount: amount
      });
    } else if (type === 'reject') {
      setActionInfos({
        title: "Conferma Rifiuto Riscatto",
        content: "Sei sicuro di contrassegnare come Riscatto Rifiutato? L'operazione è irreversibile!",
        user: user,
        amount: amount
      });
    }
    else{
      setDialogOpen(false);
      setActionInfos(null);
      setActionHandler(null);
      setIndexHandles(null);
    }
  };

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
    setActionInfos(null);
    setActionHandler(null);
    setIndexHandles(null);
  }, []);
  
  // Simulate fetching data (replace this with your API call)
  const fetchData = useCallback(async () => {
    try {
      const response = await redeemRequestList(page, rowsPerPage, searchTerm, approvalFilter);
      setOriginalData(response);
      setEditableData(JSON.parse(JSON.stringify(response)));
      await delay(500);
      setSearchWaiter(false);
    } catch (e) {
      console.error(e);
    }
  }, [page, rowsPerPage, searchTerm, approvalFilter]);

  useEffect(() => {
    fetchData(); // inizializzazione
  },[]);

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    setSearchWaiter(true);
    debounceTimeout.current = setTimeout(() => {
      fetchData();
      debounceTimeout.current = null;
    }, 1200);
  }, [fetchData]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInput = (newString: string) => {
    if (newString.length < minChars) {
      setSearchTerm(null);
      return;
    }
    if (newString === searchTerm) return;
    if (newString.length > 20) return;
    setSearchTerm(newString);
  };

  const handleEditableChange = useCallback((
    rowIndex: number,
    field: string,
    value: string,
  ) => {
    setEditableData(prevData => {
      const updatedData = [...prevData];

      const keys = field.split('.');
      let current: any = updatedData[rowIndex];
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      // Only recalculate if amount, fixedAmount, or percentValue changed
      if (field === 'redeem.amount' || field === 'redeem.fixedAmount' || field === 'redeem.percentValue') {
        const item = updatedData[rowIndex];
        const totalAmount = Number(item.redeem.amount);
        const fixedAmount = Number(item.redeem.fixedAmount);
        const percentValue = Number(item.redeem.percentValue);
        const decimals = item.redeem.decimals;

        
        const percentAmount = Number((totalAmount * percentValue / 100).toFixed(decimals));
        const netAmount = totalAmount - percentAmount - fixedAmount;
        
        updatedData[rowIndex] = {
          ...item,
          redeem: {
            ...item.redeem,
            netAmount: netAmount,
            percentAmount: percentAmount
          }
        };
      }

      return updatedData;
    });
  }, []);

  const handleChangeDate = useCallback((
    date: Dayjs | null,
    rowIndex: number,
    field: string // Nome del campo, es. "buy.dataReceved"
  ) => {
    if (date !== null && date.isValid()) {
      const updatedData = [...editableData];
      const keys = field.split('.'); // Dividi il campo in più chiavi
      let current: any = updatedData[rowIndex];

      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]]; // Scendi nel percorso dell'oggetto
      }

      current[keys[keys.length - 1]] = date.toDate(); // Imposta il valore della data
      setEditableData(updatedData); // Aggiorna lo stato
    }
  }, []);

  const handleStatusChange = useCallback((rowIndex: number, newStatus: string | number) => {
    const updatedData = [...editableData];

    updatedData[rowIndex].redeem.status = Number(newStatus) as ETokensRedeemStatus;

    setEditableData(updatedData); // Aggiorna lo stato globale
  }, []);

  const handleEdit = useCallback((index: number) => {
    if(editRowIndex === null || editRowIndex === undefined)
      setEditRowIndex(index);
  }, []);

  const handleCancel = useCallback((index:number) => {
    const editableDataRestore = [...editableData];
    editableDataRestore[index] = JSON.parse(JSON.stringify(originalData[index]));
    setEditableData(editableDataRestore);
    setEditRowIndex(null);
  }, [editableData, originalData]);

  const handleSave = useCallback(async (index:number) => {
    try{
      setWaiterView(true);
      const newValues = editableData[index];
      
      const editableDataRestore = [...editableData];
      const originalDataRestore = [...originalData];
      const resp = await redeemModifyRecord(newValues);

      editableDataRestore[index] = JSON.parse(JSON.stringify(resp));
      originalDataRestore[index] = JSON.parse(JSON.stringify(resp));

      setOriginalData(originalDataRestore);
      setEditableData(editableDataRestore);
      setEditRowIndex(null);

    }catch(e){
      console.error(e);
    }
    setWaiterView(false);
  }, [editableData, originalData]);

  const handleConfirm = useCallback(async (index:number) => {
    try{
      setWaiterView(true);
      const newValues = editableData[index];
      
      const editableDataRestore = [...editableData];
      const originalDataRestore = [...originalData];
      const resp = await redeemConfirmBankTransfer(newValues);

      editableDataRestore[index] = JSON.parse(JSON.stringify(resp));
      originalDataRestore[index] = JSON.parse(JSON.stringify(resp));

      setOriginalData(originalDataRestore);
      setEditableData(editableDataRestore);
      setEditRowIndex(null);
      setDialogOpen(false);
      setActionInfos(null);
      setActionHandler(null);
      setIndexHandles(null);

    }catch(e){
      console.error(e);
    }
    setWaiterView(false);
  }, [editableData, originalData]);

  const handleReject = useCallback(async (index:number) => {
    try{
      setWaiterView(true);
      const newValues = editableData[index];
      
      const editableDataRestore = [...editableData];
      const originalDataRestore = [...originalData];
      const resp = await redeemRejectBankTransfer(newValues);

      editableDataRestore[index] = JSON.parse(JSON.stringify(resp)); // eliminare il record
      originalDataRestore[index] = JSON.parse(JSON.stringify(resp)); // eliminare il record

      setOriginalData(originalDataRestore);
      setEditableData(editableDataRestore);
      setEditRowIndex(null);
      setDialogOpen(false);
      setActionInfos(null);
      setActionHandler(null);
      setIndexHandles(null);

    }catch(e){
      console.error(e);
    }
    setWaiterView(false);
  }, [editableData, originalData]);

  // const calculateUpdatedData = useCallback((item: AdminBuyResponseModel) => {
  //   const totalAmount = Number(item.buy.amount);
  //   const fixedAmount = Number(item.buy.fixedAmount);
  //   const percentValue = Number(item.buy.percentValue);
  //   const decimals = item.buy.decimals;
    
  //   const percentAmount = Number((totalAmount * percentValue / 100).toFixed(decimals));
  //   const netAmount = totalAmount - percentAmount - fixedAmount;
    
  //   return {
  //     ...item,
  //     buy: {
  //       ...item.buy,
  //       netAmount: netAmount,
  //       percentAmount: percentAmount
  //     }
  //   };
  // }, []);

  useEffect(()=>{
    console.log(editableData)
  },[editableData])

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', padding: 4 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} display={'flex'}>
              <Search
                  handleInput={handleInput}
                  search={searchTerm!!}
                  setSearch={setSearchTerm}
              />
              
              <div style={{
                  paddingTop:'3px',
                  paddingLeft:'3px',
                  width:searchWaiter && searchTerm ? '50px' : '0px',
                  height:'35px'
              }}>
                  {searchWaiter && searchTerm && <CustomWaiter size={30} width={40}/>}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label={t("Status Richieste")}
                value={approvalFilter === null ? "" : approvalFilter} 
                onChange={(e) => setApprovalFilter(e.target.value === '' ? null : parseInt(e.target.value, 10))}
                variant="outlined"
                sx={{ 
                  width: '100%',
                  borderRadius:'5px',
                  backgroundColor: alpha(theme.palette.primary.light, 0.05),
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.light, 0.1),
                  },
                  '& .MuiInputLabel-root': {
                    marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                    color:'grey'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius:'5px',
                    border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                  },
                }}
                InputProps={{
                  style: {
                    height: '37px', // Imposta altezza anche alla radice del componente
                    margin:-1
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("Tutti gli status")}</em>
                </MenuItem>
                {Object.keys(ETokensRedeemStatus).map((key) => {
                  const typedKey = key as keyof typeof ETokensRedeemStatus;

                  if (isNaN(Number(typedKey))) {
                    const value = ETokensRedeemStatus[typedKey];
                    const displayValue = value === 0 ? 0 : value;

                    return (
                      <MenuItem key={typedKey} value={displayValue}>
                        {typedKey === 'RequestFromUser' ? t('RequestFromUser') : t(typedKey)}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TabsCellHeaderCustom sx={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor:WHITE,
                  minWidth:'90px !important',
                }}
                >{t("EDIT")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Info Utente")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Moneta/Divisa")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Decimali Divisa")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Rapporto Divisa/ASSO")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("ASSO riconvertiti")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Importo Lordo")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Importo Netto da Inviare")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Commissione fissa in divisa")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Commissione percentuale in divisa")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Valore Commissione in Percentuale")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Codice Univoco Causale")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("IBAN Ricevente")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("BIC/SWIFT")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Titolare IBAN Ricevente")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Data di Richiesta")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Data Invio del Pagamento")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Data Rigetto Transazione")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Info")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Riferimenti bancari sulla transazione (es. CRO)")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Info su eventuali Errori")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Info su eventuale Rigetto")}</TabsCellHeaderCustom>
                <TabsCellHeaderCustom>{t("Status")}</TabsCellHeaderCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              {editableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  const rowStatus = originalData[index].redeem.status;
                  return (
                    <TableRow key={index} 
                      sx={{
                        backgroundColor: rowStatus === ETokensRedeemStatus.Rejected ? 'rgb(255,0,0,0.15)'
                        : rowStatus === ETokensRedeemStatus.BankTransferExecuted ? 'rgb(0,255,0,0.15)'
                        : undefined
                      }}
                    >
                      <TabsCellCustom sx={{
                        position: 'sticky',
                        borderRight:'1px solid ' + GRAY_LIGHT_RGB_BORDER,
                        left: 0,
                        backgroundColor: WHITE,
                        zIndex:1,
                        minWidth:'90px !important',
                      }}
                      >
                        {editRowIndex === index ? (
                          <>
                            <IconButton onClick={() => {handleSave(index)}}>
                              <SaveIcon sx={{color:'green'}}/>
                            </IconButton>
                            <IconButton onClick={() => handleCancel(index)}>
                              <CancelIcon sx={{color:'red'}} />
                            </IconButton>
                          </>
                        ) : (
                          <Grid container>
                            <Grid item display="flex" xs={12} justifyContent={'center'}>
                              <Tooltip title={t("EDIT")} arrow>
                                <IconButton onClick={() => handleEdit(index)}>
                                  <EditIcon sx={{color:'blue'}}/>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item display="flex" xs={12} justifyContent={'space-between'}>
                              {rowStatus === ETokensRedeemStatus.RequestFromUser && 
                                <Tooltip title={t("CONFIRM")} arrow>
                                  <IconButton onClick={() => openDialog(index, 'confirm', item.redeem.amount.toFixed(item.redeem.decimals) + " ASSO", item.userName)}>
                                    <CheckCircleIcon sx={{color:'green'}}/>
                                  </IconButton>
                                </Tooltip>
                              }

                              {rowStatus === ETokensRedeemStatus.RequestFromUser && 
                                <Tooltip title={t("REJECT")} arrow>
                                  <IconButton onClick={() => openDialog(index, 'reject', item.redeem.amount.toFixed(item.redeem.decimals) + " ASSO", item.userName)}>
                                    <CloseIcon sx={{color:'red'}}/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </Grid>
                          </Grid>
                        )}

                      </TabsCellCustom>
                      <TabsCellCustom>{item.userName}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.divisa}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.decimals}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.ratioTokensForFiat}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.amountTokens.toFixed(settings.Token.Decimals)} ASSO</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.amount.toFixed(item.redeem.decimals)} ASSO</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.netAmount.toFixed(item.redeem.decimals)} {item.redeem.divisa}</TabsCellCustom>

                      <TableCellEditable
                        editRowIndex={editRowIndex}
                        index={index}
                        value={editableData[index].redeem.fixedAmount}
                        onChange={(value) => handleEditableChange(index, "redeem.fixedAmount", value)}
                        regex={new RegExp(`^\\d*(\\.\\d{0,${item.redeem.decimals}})?$`)}
                        errorText="Puoi inserire solo numeri"
                        otherText={item.redeem.divisa}
                        parsedDecimals={item.redeem.decimals}
                        disabled={rowStatus === ETokensRedeemStatus.RequestFromUser ? false : true}
                      /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                      <TabsCellCustom>{item.redeem.percentAmount.toFixed(item.redeem.decimals)} {item.redeem.divisa}</TabsCellCustom> 

                      <TableCellEditable
                        editRowIndex={editRowIndex}
                        index={index}
                        value={editableData[index].redeem.percentValue}
                        onChange={(value) => handleEditableChange(index, "redeem.percentValue", value)}
                        regex={new RegExp(`^\\d*(\\.\\d{0,${2}})?$`)}
                        errorText="Puoi inserire solo numeri"
                        otherText={"%"}
                        parsedDecimals={2}
                        disabled={rowStatus === ETokensRedeemStatus.RequestFromUser ? false : true}
                      /> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                      
                      <TabsCellCustom>{item.redeem.refCode}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.iban}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.bicswift}</TabsCellCustom>
                      <TabsCellCustom>{item.redeem.ibanOwner}</TabsCellCustom>
                      
                      <TabsCellCustom sx={{whiteSpace:'nowrap'}}>
                        {dayjs(item.redeem.dataRequest).isValid()
                        ? dayjs(item.redeem.dataRequest).format('DD/MM/YYYY HH:mm') // Formatta la data nel formato desiderato
                        : '-'}
                      </TabsCellCustom>

                      <TabsCellCustom>
                      {editRowIndex === index ? (
                        <DatePickerCustom
                          label={""}
                          selectedDate={editableData[index].redeem.dataConfirmed ? dayjs(editableData[index].redeem.dataConfirmed) : undefined}
                          handleChangeDate={(date) => handleChangeDate(date, index, "redeem.dataConfirmed")}
                          error={false}
                          errorMessage={""} 
                          disable={false}
                          stackProps={{
                            sx:{
                            }
                          }}
                          dataPickerProps={{
                            slotProps:{
                              textField:{
                                sx:{
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    height: '35px', // Altezza dell'outline
                                  },
                                },
                                inputProps:{
                                  style: {
                                    fontSize: '0.7rem', // Dimensione del testo direttamente nell'input
                                    padding:'8px 14px'
                                  },
                                }
                              }
                            }
                          }}
                        />
                      ):(
                        item.redeem.dataConfirmed && dayjs(item.redeem.dataConfirmed).isValid() &&
                        new Date(item.redeem.dataConfirmed).getFullYear() !== 1
                          ? dayjs(item.redeem.dataConfirmed).format('DD/MM/YYYY HH:mm') // Formatta la data
                          : '-'
                      )}
                      </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                      
                      <TabsCellCustom>
                        {editRowIndex === index && rowStatus !== ETokensRedeemStatus.BankTransferExecuted  ? (
                          <DatePickerCustom
                            label={""}
                            selectedDate={editableData[index].redeem.dataRejected ? dayjs(editableData[index].redeem.dataRejected) : undefined}
                            handleChangeDate={(date) => handleChangeDate(date, index, "redeem.dataReject")}
                            error={false}
                            errorMessage={""}
                            disable={false}
                            stackProps={{
                              sx:{
                              }
                            }}
                            dataPickerProps={{
                              slotProps:{
                                textField:{
                                  sx:{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      height: '35px', // Altezza dell'outline
                                    },
                                  },
                                  inputProps:{
                                    style: {
                                      fontSize: '0.7rem', // Dimensione del testo direttamente nell'input
                                      padding:'8px 14px'
                                    },
                                  }
                                }
                              }
                            }}
                          />
                        ):(
                          item.redeem.dataRejected && dayjs(item.redeem.dataRejected).isValid() &&
                          new Date(item.redeem.dataRejected).getFullYear() !== 1
                            ? dayjs(item.redeem.dataRejected).format('DD/MM/YYYY HH:mm') // Formatta la data
                            : '-'
                        )}
                      </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                      <TableCellEditable
                        editRowIndex={editRowIndex}
                        index={index}
                        value={editableData[index].redeem.info}
                        onChange={(value) => handleEditableChange(index, "redeem.info", value)}
                        regex={undefined}
                        errorText=""
                      /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                      <TableCellEditable
                        editRowIndex={editRowIndex}
                        index={index}
                        value={editableData[index].redeem.transferBankCRO}
                        onChange={(value) => handleEditableChange(index, "redeem.transferBankCRO", value)}
                        regex={undefined}
                        errorText=""
                      /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                      <TableCellEditable
                        editRowIndex={editRowIndex}
                        index={index}
                        value={editableData[index].redeem.error}
                        onChange={(value) => handleEditableChange(index, "redeem.error", value)}
                        regex={undefined}
                        errorText=""
                      /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                      <TableCellEditable
                        editRowIndex={editRowIndex}
                        index={index}
                        value={editableData[index].redeem.rejectedInfo}
                        onChange={(value) => handleEditableChange(index, "redeem.rejectedInfo", value)}
                        regex={undefined}
                        errorText=""
                        disabled={rowStatus === ETokensRedeemStatus.BankTransferExecuted ? true : false}
                      /> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                      
                      <TabsCellCustom>
                        {/* {editRowIndex === index && rowStatus !== ETokensRedeemStatus.BankTransferExecuted ? 
                          (
                            <TextField
                              select
                              value={item.redeem.status}
                              onChange={(e) => handleStatusChange(index, e.target.value)}
                              variant="outlined"
                              sx={{ 
                                width: '100%',
                                borderRadius:'5px',
                                backgroundColor: alpha(theme.palette.primary.light, 0.05),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                                },
                                '& .MuiInputLabel-root': {
                                  marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                                  color:'grey'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderRadius:'5px',
                                  border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                                },
                              }}
                              InputProps={{
                                style: {
                                  height: '37px', // Imposta altezza anche alla radice del componente
                                  margin:-1,
                                  fontSize:'0.7rem'
                                },
                              }}
                            >
                              {Object.entries(ETokensRedeemStatus)
                                .filter(([key, value]) => isNaN(Number(key)))
                                .map(([key, value]) => (
                                  <MenuItem key={value} value={Number(value)}>
                                    {t(getEnumKey(value as ETokensRedeemStatus, ETokensRedeemStatus))}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ):( */}
                            {t(getEnumKey(item.redeem.status, ETokensRedeemStatus))}
                          {/* )
                        } */}
                      </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                    </TableRow>
                  )}
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={originalData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      
      {actionHandler && indexHandler !== null && 
        <ActionDialog
          open={dialogOpen}
          onClose={closeDialog}
          onConfirm={actionHandler==='confirm' ? () => {handleConfirm(indexHandler)} : actionHandler==='reject' ? () => {handleReject(indexHandler)}: ()=>{}}
          title={actionInfos ? t(actionInfos.title) : ""}
          content={actionInfos ? t(actionInfos.content) : ""}
          amount={actionInfos ? actionInfos.amount : ""}
          user={actionInfos ? actionInfos.user : ""}
        />      
      }
    </>
  );
};

export default RedeemListTable;

