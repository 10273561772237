export interface IRedeemRequestModel {
  amount: number;
  ibanOwner: string;
  iban: string;
  bicSwift: string;
  acceptTerms: boolean;
}

export interface IRedeemResponseModel {
  netAmount: number; 
  amountTokens: number; 
  ibanOwner: string; 
  iban: string; 
  bicSwift: string; 
  refCode: string; 
}

export interface AdminRedeemResponseModel
{
  redeem:RedeemTokens;
  userName:string;
}

export interface RedeemTokens
	{
		id:string;
    userId:string;
    tokenId: string; // ForeignKey equivalent
    amount: number; // decimal(20,2)
    amountTokens: number; // decimal(20,2)
    ratioTokensForFiat: number; // decimal(20,2)
    dataRequest: Date; // datetime
    dataConfirmed: Date; // datetime
    info: string; // longtext
    transferBankCRO: string; // longtext
    iban: string; // varchar(255)
    bicswift: string; // varchar(50)
    ibanOwner: string; // longtext
    status: ETokensRedeemStatus; // int(1)
    error: string; // longtext
    refCode: string; // varchar(200), default generated
    fixedAmount: number; // decimal(20,2)
    percentValue: number; // decimal(4,2)
    percentAmount: number; // decimal(20,2)
    netAmount: number; // decimal(20,2)
    divisa: string; // varchar(20)
    decimals: number; // int
    rejectedInfo: string; // varchar(200)
    dataRejected: Date;
	}

  export enum ETokensRedeemStatus
	{
		RequestFromUser = 0,
		BankTransferExecuted = 1,
		Rejected = 2,
	}