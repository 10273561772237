import { AdminPostUserTokensModel, AdminUserTokensBalances } from "../../models/admin";
import { AdminBuyResponseModel, EBuyStatus, EBuyType, IBuyModifyRequestModel, IBuyTransactionsResponse, IConfirmBuyRequestModels } from "../../models/buy/Buy";
import { TransactionsResponse } from "../../models/dashboard/Transactions";
import { AdminRedeemResponseModel } from "../../models/redeem";
import { AssociationConfirmUserModel, CompanyAssociationInfoRequestModel, EApprovedFromAssociation, ECompanyOrAssociationStatus, PrivateUserInfoRequestModel, UserInfosModel, UsersListResponseModel } from "../../models/user/UserInfosModel";
import httpClient from "../../utilities/http-client";

export const usersPrivateList = async (from:number, count:number, search?:string | null, status?:EApprovedFromAssociation | null, approved?:boolean | null): Promise<UsersListResponseModel> => {
	try {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) {
            params.append("status", status.toString());
        }
        if (approved !== null && approved !== undefined) params.append("approved", approved.toString());

        const queryString = params.toString();
        const url = `/admin/users/private/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<UsersListResponseModel>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const associationUsersPrivateList = async (from:number, count:number, search?:string | null, status?:EApprovedFromAssociation | null, approved?:boolean | null): Promise<UsersListResponseModel> => {
	try {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) {
            params.append("status", status.toString());
        }
        if (approved !== null && approved !== undefined) params.append("approved", approved.toString());

        const queryString = params.toString();
        const url = `/admin/users/private/association/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<UsersListResponseModel>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const usersCompanyAssociationList = async (from:number, count:number, search?:string | null, status?:ECompanyOrAssociationStatus | null, approved?:boolean | null): Promise<UsersListResponseModel> => {
	try {
        const params = new URLSearchParams();
        
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) {
            params.append("status", status.toString());
        }
        if (approved !== null && approved !== undefined) params.append("approved", approved.toString());

        const queryString = params.toString();
        const url = `/admin/users/companies-associations/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<UsersListResponseModel>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const buyRequestList = async (from:number, count:number, search?:string | null, status?:EBuyStatus | null, type?:EBuyType | null, dataFrom?: Date | null, dataTo?: Date | null): Promise<AdminBuyResponseModel[]> => {
	try {
        const params = new URLSearchParams();
        if(dataFrom) params.append("dataFrom", dataFrom.toISOString().substring(0, 16));
        if(dataTo) params.append("dataTo", dataTo.toISOString().substring(0, 16));
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) params.append("status", status.toString());

        if (type !== null && type !== undefined) params.append("type", type.toString());

        const queryString = params.toString();
        const url = `/admin/buy/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<AdminBuyResponseModel[]>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const buyModifyRecord = async (model: IBuyModifyRequestModel): Promise<AdminBuyResponseModel> => {
    try{      
        const { data } = await httpClient.put<AdminBuyResponseModel>(`/admin/buy/`, model);
		return data;
    }catch(e:any){
        throw e.response;
    }
}

export const buyConfirmTransaction = async (model: IConfirmBuyRequestModels) => {
    try{
        const { data } = await httpClient.post<AdminBuyResponseModel>(`/admin/confirm/buy/`, model);
        return data;
    }catch(e:any){
        throw e.response;
    }
}

export const putAdminUserPrivate = async (model: PrivateUserInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<PrivateUserInfoRequestModel>(`/admin/users/`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const putAdminAssociationOrCompany = async (model:CompanyAssociationInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<CompanyAssociationInfoRequestModel>(`/admin/company/`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const putAssociationUserPrivate = async (model: PrivateUserInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<PrivateUserInfoRequestModel>(`/admin/users/association/`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const postAssociationConfirmUser = async (model :AssociationConfirmUserModel) => {
    try {
		debugger;
		const { data } = await httpClient.post<PrivateUserInfoRequestModel>(`/admin/confirm/user/association/`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
}

export const redeemRequestList = async (from:number, count:number, search?:string | null, status?:EBuyStatus | null, dataFrom?: Date | null, dataTo?: Date | null): Promise<AdminRedeemResponseModel[]> => {
	try {
        const params = new URLSearchParams();
        if(dataFrom) params.append("dataFrom", dataFrom.toISOString().substring(0, 16));
        if(dataTo) params.append("dataTo", dataTo.toISOString().substring(0, 16));
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) params.append("status", status.toString());

        const queryString = params.toString();
        const url = `/admin/redeem/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<AdminRedeemResponseModel[]>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const redeemConfirmBankTransfer = async (model: AdminRedeemResponseModel): Promise<AdminRedeemResponseModel> => {
    try{      
        const { data } = await httpClient.post<AdminRedeemResponseModel>(`/admin/redeem/confirm`, model);
		return data;
    }catch(e:any){
        throw e.response;
    }
}

export const redeemRejectBankTransfer = async (model: AdminRedeemResponseModel): Promise<AdminRedeemResponseModel> => {
    try{      
        const { data } = await httpClient.post<AdminRedeemResponseModel>(`/admin/redeem/reject`, model);
		return data;
    }catch(e:any){
        throw e.response;
    }
}

export const redeemModifyRecord = async (model: AdminRedeemResponseModel): Promise<AdminBuyResponseModel> => {
    try{      
        const { data } = await httpClient.put<AdminBuyResponseModel>(`/admin/redeem/`, model);
		return data;
    }catch(e:any){
        throw e.response;
    }
}

export const getUserTokens = async (userId: string, page: number, rowsPerPage: number, dataFrom: Date, dataTo: Date) => {
	try {
		const dataFromString = dataFrom.toISOString().substring(0, 16); // yyyy-MM-dd hh:mm
    	const dataToString = dataTo.toISOString().substring(0, 16); // yyyy-MM-dd

		const from = page * rowsPerPage;
		const count = rowsPerPage;
		const { data } = await httpClient.get<TransactionsResponse>(`/admin/token/transactions/${userId}/${from}/${count}/${dataFromString}/${dataToString}`);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const getUserTokensBalances = async (userId: string) => {
    try {
      const { data } = await httpClient.get<AdminUserTokensBalances>(`/admin/token/summary/${userId}`);
      return data; // Restituisce i dati elaborati
    } catch (error: any) {
      throw error;
    }
};

export const postUserTokens = async (userId: string, amount: number, info: string)=> {
    try {
        const model:AdminPostUserTokensModel = {
            userId: userId,
            amount: amount,
            info: info
        }
        const {data} = await httpClient.post<AdminPostUserTokensModel>(`/admin/token/update/`,model);
        return data;
    } catch (error:any) {
      throw error;
    }
  };