import { useState } from 'react'
import DepositForm from './DepositForm'


export default function Deposit() {
  const [statusResponse, setStatusResponse] = useState<boolean>(false)

  return (
    <>
      <DepositForm status={statusResponse} setStatus={setStatusResponse} />
      {/* <LastDepositTransactionsTable status={statusResponse} /> */}
    </>
  )
}