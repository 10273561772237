import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { BLACK, BLUE_LIGHT_RGB_BORDER, BLUE_MAIN, GRAY_LIGHT_RGB_BORDER, WHITE } from '../theme/colors';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import settings from '../utilities/settings';
import { IBuy, IBuyRequestModel } from '../models/buy/Buy';
import BankTransfer from './Buy/BankTrasfer';
import BankTransferResponse from './Buy/BankTransferResponse';
import { useWaiter } from '../hooks/waiter/WaiterProvider';
import { buyRequest } from '../hooks/actions/buy';
import CheckBoxTermsAndConditions from './CheckBoxTermsAndConditions';

const TabsCustom = styled(Tabs)(({theme})=>({
    minWidth: '85px',                                       
    [theme.breakpoints.up('xs')]: {
        maxWidth: '150px', 
    },
}))

const TabCustom = styled(Tab)(({theme})=>({
    fontSize:'0.7rem',                                      
    [theme.breakpoints.up('sm')]: {
        fontSize:'0.85rem',
    },
}))

const FormCustom = styled('form')(({theme})=>({
    width:'100%',                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

const ResponseBox = styled(Box)(({theme})=>({
    width:'100%',
    marginTop:"30px",                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

const paymentMethods = [
    { label: 'Bonifico Bancario', value: 'bankTransfer', ico: '/bank-transfer.png', type: 0},
    // { label: 'PayPal', value: 'paypal', ico: '/paypal.png', type: 1 },
    // { label: 'Carta di Credito', value: 'creditcard', ico: '/creditcard.png', type: 2 },
    // { label: 'Bitcoin', value: 'bitcoin', ico: '/bitcoin.png', type: 3 },
];

interface PaymentData {
  amount: string;
  type: number;
}

const PaymentForm: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [response, setResponse] = useState<IBuy | null>(null);
    const {t} = useTranslationContext();
    const[feeFixed, setFeeFixed] = useState<number>(0)
    const[feePerc, setFeePerc] = useState<number>(0);
    const[feePercAmount, setFeePercAmount] = useState<number>(0);
    const[netAmount, setNetAmount] = useState<number>(0);
    const[divisa, setDivisa] = useState<string>("0");
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [isSended, setIsSended] = useState(false);
    const [mainErrorMex, setMainErrorMex]=useState<string | null>(null);
    const [isChecked, setIsChecked] = useState(false); 

    const {setWaiterView} = useWaiter();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        setAmount("")
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type:number) => {
        let maxAmount;
        let minAmount;
        let fixedFee;
        let percFee;
        let divisa;
        switch(type){
            case 0:{
                maxAmount = settings.BuyTokens.Bonifico.MaxAmountInEuro;
                minAmount = settings.BuyTokens.Bonifico.MinAmountInEuro;
                fixedFee = settings.BuyTokens.Bonifico.FixedFee;
                percFee = settings.BuyTokens.Bonifico.PercentFee;
                divisa = settings.BuyTokens.Bonifico.Divisa;
                setFeeFixed(fixedFee);
                setFeePerc(percFee);
                setDivisa(divisa);
                break;
            }
        }

        setError(false);
        setErrorMessage('');
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if(value === ''){
            setAmount('');
            setError(false);
            console.log( value )
            return;
        }

        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > 2) {
                setError(false);
                return;
            }
        }

        if(!regex.test(value)){
            setErrorMessage(t("Inserisci un importo valido"))
            setError(true)
        }

        if (parseFloat(value) >= 0) {
            if(parseFloat(value) > parseFloat(maxAmount)){
                setErrorMessage(t("Puoi acquistare al massimo Euro: ") + parseFloat(maxAmount).toFixed(2) )
                setError(true)
                return;
            }
            setAmount(value);
            setError(false);
            const fee = Math.round((parseFloat(percFee) * parseFloat(value)) / 100 * 100) / 100; 
            setFeePercAmount(fee);
            const net = parseFloat(value) - fee - feeFixed
            setNetAmount(net);
        } else {
            setErrorMessage(t("Devi acquistare almeno Euro: ") + parseFloat(minAmount).toFixed(2))
            setError(true);
            return;
        }
        //console.log( parseFloat(value), type )
    };

    const handleBlur = (type:number) => {
        let maxAmount;
        let minAmount;
        let fixedFee;
        let percFee;
        let divisa;
        switch(type){
            case 0:{
                maxAmount = settings.BuyTokens.Bonifico.MaxAmountInEuro;
                minAmount = settings.BuyTokens.Bonifico.MinAmountInEuro;
                fixedFee = settings.BuyTokens.Bonifico.FixedFee;
                percFee = settings.BuyTokens.Bonifico.PercentFee;
                divisa = settings.BuyTokens.Bonifico.Divisa;
                setFeeFixed(fixedFee);
                setFeePerc(percFee);
                setDivisa(divisa);
                break;
            }
        }

        if (!error && amount) {
            if(parseFloat(amount) < parseFloat(minAmount))
            {
                setErrorMessage(t("Devi acquistare almeno Euro: ") + parseFloat(minAmount).toFixed(2))
                setError(true)
            }
            const newAmount = parseFloat(amount).toFixed(2)
            setAmount(newAmount);
            setError(false);
            const fee = Math.round((parseFloat(percFee) * parseFloat(newAmount)) / 100 * 100) / 100; 
            setFeePercAmount(fee);
            const net = parseFloat(newAmount) - fee - feeFixed
            setNetAmount(net);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {

        e.preventDefault();
        setIsSended(true);
        setWaiterView(true);
        setMainErrorMex(null);
        if(isChecked){
            const newReq:IBuyRequestModel = {
                amount: parseFloat(amount),
                buyType: selectedTab,
                acceptTerms: isChecked
            }
            try{
                const buy = await buyRequest(newReq)
                setResponse(buy);
            }catch(e:any){
                console.log(e);
                setResponse(null);
                setMainErrorMex(e.data)
                setIsSended(false);
            }
        }
        setWaiterView(false);
    };

    return (
        <Box sx={{width:'100%', overflow: 'hidden', maxWidth: '100%'}}>
            <Paper sx={{ 
                width: '100%', 
                display: 'flex', 
                minHeight: '350px', 
                border: 1, 
                borderColor: 'divider',  
                overflow: 'hidden',
                maxWidth: '100%', // Limita la larghezza massima del Paper
                boxSizing: 'border-box',
            }}>
                <TabsCustom
                    orientation="vertical"
                    variant="scrollable"
                    value={selectedTab}
                    onChange={handleTabChange}
                    sx={{ borderRight: 2, borderColor: GRAY_LIGHT_RGB_BORDER }}
                >
                    {paymentMethods.map((method, index) => (
                        <TabCustom
                            key={index}
                            icon={<img src={ method.ico} alt={method.label} style={{ width: 'auto', height: 25}} />}
                            label={t(method.label)}
                            sx={{ borderBottom: 1, borderColor: GRAY_LIGHT_RGB_BORDER }}
                        />
                    ))}
                </TabsCustom>
                <Box sx={{ flexGrow: 1, p: 3, position: 'relative', maxWidth: '100%', boxSizing: 'border-box' }}>
                    {response && (
                        <IconButton
                            onClick={() => window.print()}
                            sx={{ position: 'absolute', top: 16, right: 16 }}
                        >
                            <PrintIcon />
                        </IconButton>
                    )}

                    <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none', }}>
                        <CardContent 
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                padding: '24px 0 0 0',
                                flexDirection: 'column',
                                maxWidth: '100%', // Limita il contenuto a non superare il suo contenitore
                                boxSizing: 'border-box',
                        }}>
                            <Typography variant="h4" gutterBottom  color={BLACK}
                                sx={{
                                    maxWidth: '100%', // Impedisci l'uscita dal contenitore
                                    overflow: 'hidden',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {paymentMethods[selectedTab].label}
                            </Typography>

                            { !response &&
                                <FormCustom onSubmit={handleSubmit}>
                                    <Grid container spacing={2} sx={{marginTop:'30px', maxWidth: '100%', boxSizing: 'border-box' }}>
                                        {
                                            (
                                                paymentMethods[selectedTab].type === 0    // Bonifico
                                            ) && <>
                                                    <BankTransfer setAmount={setAmount}
                                                        setError={setError}
                                                        typeTab={paymentMethods[selectedTab].type}
                                                        handleInputChange={handleInputChange}
                                                        handleBlur={handleBlur}
                                                        amount={amount}
                                                        error={error}
                                                        errorMessage={errorMessage}
                                                        divisa={divisa}
                                                        feeFixed={feeFixed}
                                                        feePerc={feePerc}
                                                        feePercAmount={feePercAmount}
                                                        netAmount={netAmount}
                                                    />
                                                    <CheckBoxTermsAndConditions isChecked={isChecked} setIsChecked={setIsChecked}/>
                                                </>
                                        }
                                        {/** OTHER METHODS */}
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" disabled={isSended || amount === '' || !isChecked} 
                                                sx={{
                                                    color:WHITE, 
                                                    border:'0'
                                                }}>
                                                {t("Invia")}
                                            </Button>
                                        </Grid>
                                        {
                                            mainErrorMex && <Grid item xs={12}>
                                                <Typography fontSize="0.75rem" color="red" 
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3, // Limita il numero di righe del testo
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        maxWidth: '100%', // Impedisce l'uscita dal contenitore
                                                    }}
                                                >
                                                    {mainErrorMex}
                                                </Typography>
                                            </Grid>
                                        }

                                    </Grid>
                                </FormCustom>
                            }

                            { response && 
                                <ResponseBox>
                                    {
                                        paymentMethods[selectedTab].type === 0 && <BankTransferResponse divisa={divisa} response={response} />
                                    }
                                    {/** OTHER RESPONSES */}
                                </ResponseBox> 
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
        </Box>
    );
};

export default PaymentForm;
