import { useAuth } from "react-oidc-context";
import { useUserContext } from "../hooks/auth/UserProvider";
import { Button } from '@mui/material'
import settings from "../utilities/settings";
import { useLocation } from "react-router-dom";
import { useTranslationContext } from "../hooks/translations/translationsProvider";
import { useEffect, useState } from "react";
import { GREEN_LOGO, GREEN_MAIN } from "../theme/colors";

const RegisterButton = () => {
  const location = useLocation();
  const {t} = useTranslationContext();
  const [url, setUrl]= useState<string>('');
  const [disable, setDisable]= useState<boolean>(false);

  useEffect(()=>{
    setUrl(encodeURIComponent(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`))
  },[location])


  const handleRegister = () => {
    try {
      //console.log("LOCATION",url)
      setDisable(true)
      window.location.href = settings.Domains.IdentityClient + `/account/register?ReturnUrl=${url}`
      
    } catch (error) {
      // console.error("Errore durante il login:", error);
    }
    setDisable(false)
  };


  return (
    <Button 
      onClick={()=> handleRegister()} 
      disabled={disable} 
      sx={{
        border: "2px solid " + GREEN_LOGO,
        color: GREEN_LOGO
      }} 
    > {t("Registrati")} </Button> 
  );
};

export default RegisterButton;