import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  styled,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { BLACK, GRAY_LIGHT, GRAY_LIGHT_RGB_BORDER, GRAY_MAIN, GREEN_MAIN, WHITE } from '../../theme/colors';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import settings from '../../utilities/settings';
import { useWaiter } from '../../hooks/waiter/WaiterProvider';
import { delay } from '../../utilities/delay';
import { dashboard } from '../../hooks/actions/dashboard';
import CheckBoxTermsAndConditions from '../CheckBoxTermsAndConditions';

import generateRandomString from '../../helpers/randomString';
import { IRedeemRequestModel } from '../../models/redeem';
import TextFieldCustom from '../TextField/TextFieldCustom';
import TextFieldWithErrorPropsCustom from '../TextField/TextFieldWithErrorPropsCustom';
import IbanForm from './IbanForm';
import { redeemRequest } from '../../hooks/actions/redeem';

const FormCustom = styled('form')(({theme})=>({
    width:'100%',                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

const ResponseBox = styled(Box)(({theme})=>({
    width:'100%',
    marginTop:"30px",                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

interface RedeemFormProps {
    setStatus: (status: boolean) => void;
    status: boolean;
};

const RedeemForm: React.FC<RedeemFormProps> = ({setStatus, status}) => {
    const [response, setResponse] = useState(null);
    const {t} = useTranslationContext();

    const [amountAvaiable, setAmountAvaible] = useState<number>(0);

    const [amount, setAmount] = useState('');
    const [errorAmount, setErrorAmount] = useState(false);
    const [errorAmountMessage, setErrorAmountMessage] = useState('')

    const [iban, setIban] = useState('');
    const [errorIban, setErrorIban] = useState(false);

    const [bic, setBic] = useState('');
    const [errorBic, setErrorBic] = useState(false);

    const [receiverName, setReceiverName] = useState('');
    const [errorReceiverName, setErrorReceiverName] = useState(false);

    const [isSended, setIsSended] = useState(false);
    const [mainErrorMex, setMainErrorMex]=useState<string | null>(null)
    const [mainResponseMex, setMainResponseMex]=useState<string | null>(null)

    const {setWaiterView} = useWaiter();
    const [isChecked, setIsChecked] = useState(false); 

    const minAmountInEuro:number = settings.RedeemTokens.MinAmountInEuro;
    const maxAmountInEuro:number = settings.RedeemTokens.MaxAmountInEuro;
    const fixedFeeInEuro:number = settings.RedeemTokens.FixedFee;
    const percentFee:number = settings.RedeemTokens.PercentFee;
    const divisa:string = settings.RedeemTokens.Divisa;
    const tokenForFiat:number = settings.RedeemTokens.TokenForFiat;

    const minAmountInTokens:string = (minAmountInEuro * tokenForFiat).toFixed(2);
    const maxAmountInTokens:string = (maxAmountInEuro * tokenForFiat).toFixed(2);

    const [percentFeeInEuro, setPercentFeeInEuro]= useState('');
    const [NetAmountInEuro, setNetAmountInEuro] = useState('');

    useEffect(()=>{
        setWaiterView(true)
        delay(500).finally(()=>{
            dashboard()
                .then(resp => {
                    setAmountAvaible(resp.balance)
                })
                .catch(error => {
                    // initLoad.current = false
                    console.log(error)
                })
            setWaiterView(false)
        })
    },[])


    const handleInputAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        setErrorAmount(false);
        setErrorAmountMessage('');
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if(value === ''){
            setAmount('');
            setNetAmountInEuro('');
            setPercentFeeInEuro('');
            setErrorAmount(false);
            return;
        }

        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > settings.Token.Decimals) {
                setErrorAmount(false);
                return;
            }
        }

        if(!regex.test(value)){
            setErrorAmountMessage(t("Inserisci un importo valido"))
            setErrorAmount(true)
        }

        if (parseFloat(value) > 0) {
            if(parseFloat(value) > amountAvaiable){
                setAmount(amountAvaiable.toFixed(settings.Token.Decimals))
                const percInE:number = amountAvaiable / tokenForFiat * percentFee / 100
                setPercentFeeInEuro(percInE.toFixed(settings.RedeemTokens.Decimals));
                const netAmInE: number = (amountAvaiable / tokenForFiat) - percInE - fixedFeeInEuro
                setNetAmountInEuro(netAmInE.toFixed(settings.RedeemTokens.Decimals))
                return;
            }
            if(parseFloat(value) > parseFloat(maxAmountInTokens)){
                setAmount(parseFloat(maxAmountInTokens).toFixed(settings.Token.Decimals))
                const percInE:number = parseFloat(maxAmountInTokens) / tokenForFiat * percentFee / 100
                setPercentFeeInEuro(percInE.toFixed(settings.RedeemTokens.Decimals));
                const netAmInE: number = (parseFloat(maxAmountInTokens) / tokenForFiat) - percInE - fixedFeeInEuro
                setNetAmountInEuro(netAmInE.toFixed(settings.RedeemTokens.Decimals))
                return;
            }
            setAmount(value);
            const percInE:number = parseFloat(value) / tokenForFiat * percentFee / 100
            setPercentFeeInEuro(percInE.toFixed(settings.RedeemTokens.Decimals));
            const netAmInE: number = (parseFloat(value) / tokenForFiat) - percInE - fixedFeeInEuro
            setNetAmountInEuro(netAmInE.toFixed(settings.RedeemTokens.Decimals))
            setErrorAmount(false);
        } 
    };

    const handleBlurAmount = () => {
        if (!errorAmount && amount) {
            if(parseFloat(amount) < parseFloat(minAmountInTokens)){
                setAmount(minAmountInTokens);
                return;
            }
            if(parseFloat(amount) > parseFloat(maxAmountInTokens)){
                parseFloat(maxAmountInTokens) < amountAvaiable ? setAmount(maxAmountInTokens) : setAmount(amountAvaiable.toFixed(2));
                
                return;
            }
            const newAmount = parseFloat(amount).toFixed(settings.Token.Decimals)
            setAmount(newAmount);
            const percInE:number = parseFloat(newAmount) / tokenForFiat * percentFee / 100
            setPercentFeeInEuro(percInE.toFixed(settings.RedeemTokens.Decimals));
            const netAmInE: number = (parseFloat(newAmount) / tokenForFiat) - percInE - fixedFeeInEuro
            setNetAmountInEuro(netAmInE.toFixed(settings.RedeemTokens.Decimals))
            setErrorAmount(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSended(true);
        setWaiterView(true);
        setMainErrorMex(null);
        setMainResponseMex(null);

        if(parseFloat(amount) < parseFloat(minAmountInTokens))
        {
            setMainErrorMex(t("L'importo di riscatto è troppo basso."))
            return;
        }

        if(parseFloat(amount) > parseFloat(maxAmountInTokens))
        {
            setMainErrorMex(t("L'importo di riscatto è troppo alto."))
            return;
        }


        if(!errorAmount && isChecked )
        {
            const newReq:IRedeemRequestModel = {
                amount: parseFloat(amount),
                iban: iban,
                ibanOwner: receiverName,
                bicSwift: bic,
                acceptTerms: isChecked
            }
            try{
                // richiesta al db
                const redeem = await redeemRequest(newReq);
                setAmount("");
                setReceiverName("");
                setIban("");
                setBic("");
                setMainResponseMex(t("La richiesta di riscatto è stata inserita correttamente, e ha codice di riferimento: ") + redeem.refCode)
                dashboard()
                    .then(resp => {
                        setAmountAvaible(resp.balance)
                    })
                    .catch(error => {
                        // initLoad.current = false
                        console.log(error)
                    })
                
            }catch(e:any){
                setMainErrorMex(e.data || e.message)
                setIsSended(false);
            }
            setWaiterView(false);
            setIsSended(false);
        }
        setStatus(!status); // reload delle ultime transazioni
    };

    return (
        <Box sx={{width:'100%'}}>
            <Paper sx={{ width: '100%', display: 'flex', minHeight:'350px', border: 1, borderColor: 'divider', boxShadow:5}}>
                
                <Box sx={{ flexGrow: 1, p: 3, position: 'relative' }}>
                    {response && (
                        <IconButton
                            onClick={() => window.print()}
                            sx={{ position: 'absolute', top: 16, right: 16 }}
                        >
                            <PrintIcon />
                        </IconButton>
                    )}

                    <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <CardContent 
                            sx={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'100%',
                                padding:'24px 0 0 0',
                                flexDirection:'column'
                            }}
                        >
                            <Typography variant="h4" gutterBottom  color={BLACK} >
                                {t("Riscatta ASSO")}
                            </Typography>

                            { !response &&
                                <FormCustom onSubmit={handleSubmit}>
                                    <Grid container spacing={2} sx={{marginTop:'30px'}}>
                                        <>
                                            
                                            <Grid item xs={12}>
                                                <Typography color={GRAY_MAIN} textAlign={'left'} mb={5} sx={{fontSize:"0.8rem"}}>
                                                    {t("ASSO presenti nel tuo account: ")} 
                                                    <strong>{amountAvaiable} ASSO</strong>
                                                </Typography>
                                            </Grid>
                                            

                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={t("Inserisci l'importo che vuoi riscattare")}
                                                    name="amount"
                                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {(handleInputAmountChange(evt))}}
                                                    onClick={() => {
                                                        setAmount('')
                                                        setErrorAmount(false)
                                                    }}
                                                    onBlur={() => handleBlurAmount()}
                                                    value={amount}
                                                    error={errorAmount}
                                                    // disabled={}
                                                    
                                                />

                                                <Grid item xs={12} mt={2}>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={1} sx={{fontSize:"0.7rem"}}>
                                                        {t("Importo minimo riscattabile in Euro: ")} 
                                                        <strong>{minAmountInEuro.toFixed(settings.RedeemTokens.Decimals)} {divisa.toUpperCase()}</strong>
                                                    </Typography>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={1} sx={{fontSize:"0.7rem"}}>
                                                        {t("Importo massimo riscattabile in Euro: ")} 
                                                        <strong>{maxAmountInEuro.toFixed(settings.RedeemTokens.Decimals)} {divisa.toUpperCase()}</strong>
                                                    </Typography>
                                                </Grid>
                                                {amount && amount !== '' && 
                                                    <>
                                                        <Grid item xs={12} mt={2}>
                                                            <Typography color={GRAY_MAIN} textAlign={'left'} mb={1} sx={{fontSize:"0.7rem"}}>
                                                                {t("Commissione fissa in Euro: ")} 
                                                                <strong>{fixedFeeInEuro.toFixed(settings.RedeemTokens.Decimals)} {divisa.toUpperCase()}</strong>
                                                            </Typography>
                                                            <Typography color={GRAY_MAIN} textAlign={'left'} mb={1} sx={{fontSize:"0.7rem"}}>
                                                                {t("Commissione % in Euro: ")} 
                                                                <strong>{parseFloat(percentFeeInEuro).toFixed(settings.RedeemTokens.Decimals)} {divisa.toUpperCase()} ( {percentFee} % )</strong>
                                                            </Typography>
                                                            <Typography color={GRAY_MAIN} textAlign={'left'} mb={1} sx={{fontSize:"0.8rem"}}>
                                                                {t("Importo riscattato al netto delle commissioni: ")} 
                                                                <strong>{parseFloat(NetAmountInEuro).toFixed(settings.RedeemTokens.Decimals)} {divisa.toUpperCase()}</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </> 
                                                }
                                                <IbanForm
                                                    iban={iban}
                                                    setIban={setIban}
                                                    errorIban={errorIban}
                                                    setErrorIban={setErrorIban}
                                                    bic={bic}
                                                    setBic={setBic}
                                                    errorBic={errorBic}
                                                    setErrorBic={setErrorBic}
                                                    receiverName={receiverName}
                                                    setReceiverName={setReceiverName}
                                                    errorReceiverName={errorReceiverName}
                                                    setErrorReceiverName={setErrorReceiverName}
                                                />
                                                
                                            </Grid>

                                            <CheckBoxTermsAndConditions isChecked={isChecked} setIsChecked={setIsChecked}/>

                                            <Grid item xs={12}>
                                                <Button type="submit" variant="contained" disabled={isSended || amount === '' || errorAmount || !isChecked} 
                                                    sx={{
                                                        color:WHITE, 
                                                        border:'0'
                                                    }}>
                                                    {t("Invia richiesta di riscatto")}
                                                </Button>
                                            </Grid>
                                        </>
                                            
                                        {
                                            mainErrorMex && <Grid item xs={12} display="grid" marginBottom={2} marginTop={2}>
                                                <Typography fontSize="0.75rem" color="red" 
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3, // Limita il numero di righe del testo
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        maxWidth: '100%', // Impedisce l'uscita dal contenitore
                                                    }}
                                                >
                                                    {mainErrorMex}
                                                </Typography>
                                            </Grid>
                                        }

                                        {
                                            mainResponseMex && <Grid item xs={12} display="grid" marginBottom={2} marginTop={2}>
                                                <Typography fontSize="0.75rem" color={GREEN_MAIN} 
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3, // Limita il numero di righe del testo
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        maxWidth: '100%', // Impedisce l'uscita dal contenitore
                                                    }}
                                                >
                                                    {mainResponseMex}
                                                </Typography>
                                            </Grid>
                                        }


                                    </Grid>
                                </FormCustom>
                            }

                            { response && <></>
                                // <ResponseBox> {response.refCode}
                                //     {
                                //         <WithdrawResponse response={response} />
                                //     }
                                // </ResponseBox> 
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
        </Box>
    );
};

export default RedeemForm;
