import React, { useEffect, useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserInfos from '../../components/UserInfo/UserInfos';
import AccordionCustom from '../../components/AccordionCustom/AccordionCustom';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { useUserContext } from '../../hooks/auth/UserProvider';
import { useNavigate } from 'react-router-dom';
import { checkIfUserIsCompanyOrAssociation, checkUserEnabled } from '../../helpers/authorizedUser';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import MarketList from '../../components/Markets/MarketList';

const UserProfile: React.FC = () => {
  const {t} = useTranslationContext();

  // utente autorizzato
  const [isEnabled, setIsEnabled] = React.useState(false)
  const {user, auth} = useUserContext();
  const navigate = useNavigate();
  const {checkIsAuth} = useUserContext();
  const [isCompanyOrAssociation, setIsCompanyOrAssociation] = useState<boolean>(false);
  useEffect(()=>{
    setIsCompanyOrAssociation(false);
    if (!auth.isLoading) {
        if (!auth.isAuthenticated) {
            navigate('/');
        } else {
            if(user){
                if(!checkUserEnabled(user)){
                    navigate('/');
                }
              setIsCompanyOrAssociation(checkIfUserIsCompanyOrAssociation(user))
            }
            setIsEnabled(true);
        }
    }
    checkIsAuth().finally();
  },[auth.isAuthenticated, auth.isLoading, navigate, user])
  // fine utente autorizzato

  return (
    <>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* Sezione informazioni utente */}
        <AccordionCustom summayTxt={t("Info Utente")}>
          <UserInfos />
        </AccordionCustom>

        <AccordionCustom summayTxt={t("Cambio Password")}>
          <ChangePassword />
        </AccordionCustom>

        {isCompanyOrAssociation && 
          <AccordionCustom summayTxt={t("Attività e Servizi")}>
            <MarketList />
          </AccordionCustom>
        }
      </Box>
    </>
  );
};

export default UserProfile;
