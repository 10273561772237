import { useAuth } from "react-oidc-context";
import { Box, Typography, styled, alpha, Card} from '@mui/material'
import { BLACK, BLUE_LIGHT, BLUE_LIGHT_RGB_BORDER, BLUE_MAIN, GRAY_LIGHT, GREEN_LIGHT, WHITE } from "../../theme/colors";
import { useTranslationContext } from "../../hooks/translations/translationsProvider";
import { MarketItemProps } from "./interfaces";
import WebIco from '@mui/icons-material/Language';
import PhisicalIco from '@mui/icons-material/Home';
import UndefinedIco from '@mui/icons-material/HelpOutline';

const LinkBase = styled('a')(({ theme }) => ({
    textDecoration: 'none', 
    color:'inherit',
    '&:hover': {
        letterSpacing:'0.4px'
    },
}));

const MarketItem = (props:MarketItemProps) => {
    const {t} = useTranslationContext();

    function marketType () {
        if(props.type === 0) // online
        {
            return (
                <Box
                    display={'flex'}
                >
                    <WebIco sx={{
                        height:'20px',
                        color:'green'
                    }}/>
                    <Typography variant="body2" sx={{marginTop:'2px', color:'grey'}}>{t("Attività Online")}</Typography>
                </Box>
            )
        }
        else if(props.type === 1) // fisico
        {
            return (
                <Box
                    display={'flex'}
                >
                    <PhisicalIco sx={{
                        height:'20px',
                        color:'blue'
                    }}/>
                    <Typography variant="body2" sx={{marginTop:'2px', color:'grey'}}>{t("Attività Fisica")}</Typography>
                </Box>
            )
        }
        else if(props.type === 2) // sia online che fisico
        {
            return (
                <Box
                    display={'flex'}
                >
                    <WebIco sx={{
                        height:'20px',
                        color:'green'
                    }}/>
                    <PhisicalIco sx={{
                        height:'20px',
                        color:'blue'
                    }}/>
                    <Typography variant="body2" sx={{marginTop:'2px', paddingLeft:'6px', color:'grey'}}>{t("Attività Online e Fisica")}</Typography>
                </Box>
            )
        }
        else
        {
            return (
                <Box
                    display={'flex'}
                >
                    <UndefinedIco sx={{
                        height:'20px',
                        color:'orange'
                    }}/>
                    <Typography variant="body2" sx={{marginTop:'2px', color:'grey'}}>{t("Attività non definita")}</Typography>
                </Box>
            )
        }
    }


    return (
        <Box sx={{
            width:'100%',
            border:'1px solid '+ BLUE_LIGHT_RGB_BORDER,
            minHeight:'120px',
            dispaly:'flex',
            flexDirection:'column',
            margin:'10px 0',
            borderRadius:'5px'
        }}>
            <Box sx={{
                display:'flex',
                width:'100%',
                height:'30px',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor: alpha(BLUE_LIGHT, 0.05),
                borderBottom:'1px solid '+ BLUE_LIGHT_RGB_BORDER,
                fontSize:'1.2em',
                fontWeight:600
            }}>
                {props.name}
            </Box>

            <Box sx={{
                width:'100%',
                display:'flex',
                flexDirection: 'column',
                textAlign:'start',
                padding:'5px',
            }}>
                <Box
                    sx={{
                        marginLeft:'-4px',
                        marginTop:'10px',
                    }}
                >
                    <Typography sx={{
                        fontSize: '1.1rem',
                        textAlign:'center'
                    }}>
                        {props.typeActivity} 
                    </Typography>
                </Box>
                <Box
                    sx={{
                        height:'30px',
                        marginLeft:'-4px',
                        // justifyContent:'center'
                    }}
                >
                    {
                         marketType()
                    }
                </Box>
                
                <Typography variant='body2'>
                    <strong>{t("Città: ")}</strong> {props.city} {props.province}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Nazione: ")}</strong> {props.nation}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Email: ")}</strong> {props.mail ? <LinkBase href={`mailto:${props.mail}`} target="_blank">{props.mail}</LinkBase> : ''}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Telefono: ")}</strong> {props.phone}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Sito internet: ")}</strong> {props.website ? <LinkBase href={props.website} target="_blank">{props.website}</LinkBase> : ''}
                </Typography>

                <Box sx={{
                    height:'10px'
                }} />
                <Typography sx={{
                    fontSize: '1rem'
                }}>
                    <strong>{t("Descrizione: ")}</strong> {props.description }
                </Typography>

                <Box
                    sx={{
                        display:"flex",
                        width:'100%',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        marginTop: '10px',
                        marginLeft:'-4px'
                    }}
                >
                    {props.tags && props.tags.length > 0 && props.tags.map((tag, index) => (
                        <Box sx={{
                            minWidth:'20px',
                            height:'20px',
                            // border:'1px solid '+ BLACK,
                            backgroundColor: GRAY_LIGHT,
                            dispaly:'flex',
                            alignItems: 'center',
                            justifyContent:'center',
                            padding:'6px 10px 0 10px',
                            margin: '4px',
                            borderRadius:'11px'
                        }} key={index}>
                            <Typography sx={{fontSize: '12px'}}>{tag}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            
        </Box>
    );
}

export default MarketItem;