import { IWithdrawConfirmModel, IWithdrawRequestModel, IWithdrawResponseModel } from "../../models/withdraw";
import httpClient from "../../utilities/http-client";

export const withdrawRequest = async (model: IWithdrawRequestModel) => {
	try {
		const { data } = await httpClient.post<IWithdrawResponseModel>(`/tokens/withdraw/`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};


export const withdrawConfirm = async (model: IWithdrawConfirmModel) => {
	try {
		const { data } = await httpClient.post<IWithdrawResponseModel>(`/tokens/withdraw/confirm`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};


export const withdrawReject = async (model: IWithdrawConfirmModel) => {
	try {
		const { data } = await httpClient.post<IWithdrawResponseModel>(`/tokens/withdraw/reject`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};