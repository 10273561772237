import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { EApprovedFromAssociation } from '../../../../models/user/UserInfosModel';
import { Box, IconButton, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface AssociationApprovalsProps {
    approvedFromAssociation: number; // Valore iniziale passato come prop
    approvedFromAssociationReset: number;
    handleChange: (value: string, key: string) => void; // Funzione per aggiornare lo stato
}

const AssociationApproval: React.FC<AssociationApprovalsProps> = ({ approvedFromAssociation, approvedFromAssociationReset, handleChange }) => { 
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = React.useState<number>(approvedFromAssociation);
    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
        setSelectedValue(Number(value));
        handleChange(value, 'approvedFromAssociation'); // Inviamo il cambiamento al padre
    };
    
      // Funzione per resettare il valore al valore iniziale
    const handleReset = () => {
        setSelectedValue(approvedFromAssociationReset);
        handleChange(approvedFromAssociationReset.toString(), 'approvedFromAssociation'); // Ripristiniamo il valore originale
    };

    return (
        <Box 
            sx={{display:'flex'}}
        >
            <TextField
                select
                //label={t('Select approval status')} // Puoi usare la traduzione se necessario
                value={selectedValue.toString()} // Assicuriamoci che il valore sia una stringa
                onChange={handleSelectChange}
                sx={{
                    width: '100%',
                }}
                InputProps={{
                    style: {
                        marginTop: '16px',
                        marginBottom: '8px',
                    },
                }}
            >
                {Object.keys(EApprovedFromAssociation).map((key) => {
                    const typedKey = key as keyof typeof EApprovedFromAssociation;
                    if (isNaN(Number(typedKey))) {
                        const value = EApprovedFromAssociation[typedKey];
                        const displayValue = value === 0 ? 0 : value;
            
                        return (
                        <MenuItem key={typedKey} value={displayValue}>
                            {t(typedKey)}
                        </MenuItem>
                        );
                    }
                    return null;
                })}
            </TextField>
    
            {/* Bottone per resettare il valore */}
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <IconButton onClick={handleReset} sx={{ 
                    marginTop: '8px',
                    marginLeft:'8px',
                    width:'50px', 
                    height:'50px'
                 }}>
                    <ClearIcon />
                </IconButton>
            </Box>
        </Box>
      );
  }

  export default AssociationApproval;