import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import Home from './pages/Home'
import Circuito from './pages/unAuth/Circuito';
import CallBack from './components/CallBack';
import Mercato from './pages/unAuth/Mercato';
import Contacts from './pages/Contacts';
import HomeAuth from './pages/auth/HomeAuth';
import AssociationsList from './pages/AssociationsList';
import RegisterCompany from './components/RegisterCompany';
import RegisterCallBack from './components/RegisterCallBack';
import MarketsList from './pages/MarketsList';
import NotFound from './components/NotFound';
import MarketsGeo from './pages/MarketsGeo';
import TransactionsAuth from './pages/auth/TransactionsAuth';
import BuyTokens from './pages/auth/BuyTokens';
import WithdrawTokens from './pages/auth/WithdrawTokens';
import AcceptRejectWithdraw from './components/Withdraw/AcceptRejectWithdraw';
import UserProfile from './pages/auth/UserProfile';
import DefaultCompanyAndAssociationPage from './pages/auth/DefaultCompanyAndAssociationPage';
import MarketForm from './components/Markets/MarketForm';
import Deposit from './components/Deposit';
import Redeem from './components/Redeem';
import DefaultAdminOrAssociationPage from './pages/auth/DefaultAdminOrAssociationPage';
import Asso from './pages/unAuth/Asso';
import Platform from './pages/unAuth/Platform';
import RegisterPrivate from './pages/unAuth/RegisterPrivate';
import RegisterEconomicsCompany from './pages/unAuth/RegisterEconomicsCompany';
import RegisterAssociation from './pages/unAuth/RegisterAssociation';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout children={<Home />} />} /> 
        <Route path='/private/register' element={<Layout children={<RegisterPrivate />} />} />
        <Route path='/economics-activities/register' element={<Layout children={<RegisterEconomicsCompany />} />} />
        <Route path='/associations/register' element={<Layout children={<RegisterAssociation />} />} />
        <Route path="/asso" element={<Layout children={<Asso />} />} /> 
        <Route path="/platform" element={<Layout children={<Platform />} />} /> 
        <Route path="/network" element={<Layout children ={<Circuito />}/>} />
        <Route path="/associations" element={<Layout children ={<AssociationsList />}/>} />
        <Route path="/markets" element={<Layout children ={<MarketsList />}/>} />
        <Route path="/markets/geo" element={<Layout children ={<MarketsGeo />}/>} />
        <Route path="/market" element={<Layout children ={<Mercato />}/>} />
        {/* <Route path="/contacts" element={<Layout children ={<Contacts />}/>} /> */}
        <Route path="/authorize/callback" element={<Layout children={<CallBack />} />} />
        <Route path="/company/register/:userRef" element={<Layout children={<RegisterCompany/>} />} />
        <Route path="/company/register/" element={<Layout children={<RegisterCompany/>} />} />
        <Route path="/company/register/callback" element={<Layout children={<RegisterCallBack />} />} /> 
        <Route path="/withdraw/:action" element={<Layout children={<AcceptRejectWithdraw />} />} />

        {/** AUTH */}
        <Route path="/home" element={<Layout children={<HomeAuth />} />} /> 
        <Route path="/transactions" element={<Layout children={<TransactionsAuth type={0}/>} />} /> 
        <Route path="/buy" element={<Layout children={<BuyTokens />} />} /> 
        <Route path="/buy/transactions" element={<Layout children={<TransactionsAuth type={1}/>} />} /> 
        <Route path="/withdraw" element={<Layout children={<WithdrawTokens />} />} />
        <Route path="/profile" element={<Layout children={<UserProfile />} />} />
        <Route path="/activity" element={<Layout children={<DefaultCompanyAndAssociationPage children={<MarketForm />} />} />} />
        <Route path="/activity/:marketId" element={ <Layout children={<DefaultCompanyAndAssociationPage children={<MarketForm />} />} />} />
        <Route path="/deposit" element={ <Layout children={<DefaultCompanyAndAssociationPage children={<Deposit/>} />} />} />
        <Route path="/redeem" element={ <Layout children={<DefaultCompanyAndAssociationPage children={<Redeem/>} />} />} />

        {/** AUTH SUPERADMIN, DEVSUPERADMIN, OR ASSOCIATION */}
        <Route path="/admin" element={ <Layout children={<DefaultAdminOrAssociationPage/>} />} />

        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<Layout children={<NotFound/>} />} /> 
      </Routes>
    </>
  );
}

export default App;