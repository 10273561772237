import React, { useEffect, useState } from 'react';
import { UserInfosModel } from '../../models/user/UserInfosModel';
import AlertSaveModal from '../UserInfo/AlertSaveModal';
import { Alert, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { WHITE } from '../../theme/colors';
import TextFieldPasswordCustom from '../TextField/TextFieldPasswordCustom';
import { changePasswordRequest } from '../../hooks/actions/changePassword';
import { UpdateUserPasswordRequestModel } from '../../models/changePassword/changePassword';
import { useWaiter } from '../../hooks/waiter/WaiterProvider';

const ChangePassword: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfosModel | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [error, setError] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  
  const [password, setPassword]=useState<string>("");
  const [newPw, setNewlPW]=useState<string>("");
  const [confirmNewPw, setConfirmNewlPW]=useState<string>("");
  const [confirmNewPwErrorMsg, setConfirmNewlPWErrorMsg]=useState<string>("");
  const [confirmNewPwError, setConfirmNewlPWError]=useState<boolean>(false);
  const [authorizeChange, setAutorizeSave] = useState<boolean>(false);
  const {t} = useTranslationContext();
  const {setWaiterView} = useWaiter();

  const regex:RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\-_+=])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()\\-_+=]+$/;
  const minPasswordLenght = 8;

  const handleChangePassword = async () => {
    const model:UpdateUserPasswordRequestModel={
      newPassword:newPw,
      oldPassword:password
    }

    try{
      setError(false);
      setErrorMsg("");
      setWaiterView(true);
      const resp = await changePasswordRequest(model);
      setWaiterView(false);

      setShowPopup(true); // apertura e chiusura del popup
      setTimeout(() => {
        setShowPopup(false);
      }, 10000);

      setPassword("");
      setNewlPW("");
      setConfirmNewlPW("")

    }catch(error:any){
      setWaiterView(false);
      setError(true);
      const errorMex =  error.data ||
                        error?.response?.data?.title || 
                        error?.response?.data?.message || 
                        error?.response?.data || 
                        error?.response || 
                        t("Errore durante la modifica dei dati")
      setErrorMsg(t(errorMex));
    }
  }

  useEffect(()=>{
    setAutorizeSave(false);
    if(newPw.length > 0){
      const pwLength = newPw.length >= minPasswordLenght;
      const regTest = regex.test(newPw);
      if(pwLength && regTest && newPw === confirmNewPw)
        setAutorizeSave(true);
    }
  },[newPw, confirmNewPw])

  return (
    <>
      <div>
        <h5>{t("Camtbio Password")}</h5>
        <form>
          
          <TextFieldPasswordCustom
            label={t("Password Attuale")}
            value={password}
            errorText={t("Errore di inserimento nella password attuale")}
            onChange={(value:string) => setPassword(value)}
          />

          <TextFieldPasswordCustom
            label={t("Nuova Password")}
            regex={regex}
            value={newPw}
            errorText={t('Password non valida. Deve contenere almeno 1 minuscola, 1 maiuscola, a carattere speciale, 1 numero zero spazi e avere almeno 8 caratteri')}
            onChange={(value:string) => setNewlPW(value)}
            minPasswordLength={minPasswordLenght}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            value={confirmNewPw}
            name={confirmNewPw}
            label={t("Conferma Nuova Password")}
            type="password"
            id={confirmNewPw}
            onChange={(event) => setConfirmNewlPW(event.target.value)}
            onBlur={()=>{
              if(confirmNewPw !== newPw){
                setConfirmNewlPWError(true);
                setConfirmNewlPWErrorMsg("Le password non coincidono");
              }
            }}
            onFocus={() => {
              setConfirmNewlPW("");
              setConfirmNewlPWError(false);
              setConfirmNewlPWErrorMsg("")
            }}
            error={confirmNewPwError}
            helperText={
                confirmNewPwError ? t(confirmNewPwErrorMsg) : ""
            }
            
          />
          

        </form>

        {
          <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={()=>{handleChangePassword()}} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}
              disabled={!authorizeChange}
              >
                {t("Salva nuova Password")}
              </Button>
            </Grid>
          </Grid>
        }

        {error && <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
              {errorMsg}
            </Typography>
          </>
        }
        

      </div>
      {/* Popup che appare dopo il salvataggio */}
      <Snackbar open={showPopup} anchorOrigin={{vertical: 'bottom', horizontal: "right" }}>
        <Alert severity="success"
          sx={{ 
            border: '2px solid green',
            marginBottom:'30px'
          }}
          onClose={() => setShowPopup(false)}>
          {t("Cambio Password avvenuto con successo")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePassword;
