import React, { createContext, useState, useContext, ReactNode } from 'react';

interface IWaiterContext {
    waiterView: boolean;
    setWaiterView: React.Dispatch<React.SetStateAction<boolean>>;
}

// Crea il contesto
const WaiterContext = createContext<IWaiterContext | undefined>(undefined);
export const useWaiter = () => {
    const ctx = useContext(WaiterContext);
    if(!ctx) {
        throw new Error('Waiter Context Error');
    }
    return ctx;
}



interface WaiterProviderProps {
	children: ReactNode
}
export const WaiterProvider:React.FC<WaiterProviderProps> = ({ children }) => {
  const [waiterView, setWaiterView] = useState(false);

  return (
    <WaiterContext.Provider value={{ waiterView, setWaiterView }}>
      {children}
    </WaiterContext.Provider>
  );
};
