export interface IBuy {
    id?: string;
    userId: string;
    amount: number;
    fixedAmount: number;
    percentValue: number;
    percentAmount: number;
    netAmount: number;
    refCode: string;
    dataRequest?: Date;
    dataReceved?: Date;
    buyType?: number;
    status?: number;
    transactionNumber?: string;
    info?: string;
    rejectedInfo?: string;
    dataRejected?: Date;
    dataRefounded?: Date;
    refoundedInfo?: string;
    refoundedIbanCardAddress?: string;
    ibanReceiver: string;
    bicSwiftReceiver: string;
    bankInfosReceiver: string;
    ibanOwner:string;
}


export interface Buy {
    id: string;
    userId: string;
    amount: number;
    fixedAmount: number;
    percentValue: number;
    percentAmount: number;
    netAmount: number;
    refCode: string;
    dataRequest: Date;
    dataReceved: Date | null | undefined;
    buyType: EBuyType;
    status: EBuyStatus;
    transactionNumber?: string | null;
    info?: string | null;
    rejectedInfo?: string | null;
    dataRejected?: Date | null | undefined;
    dataRefounded?: Date | null | undefined;
    refoundedInfo?: string | null;
    refoundedIbanCardAddress?: string | null;
    ibanReceiver?: string | null;
    bicswiftReceiver?: string | null;
    bankInfosReceiver?: string | null;
    ibanOwner?: string | null;
    divisa: string;
    decimals: number;
}

export interface IBuyModifyRequestModel {
    id: string; // Required
    userName: string; // Required
    amount: number; // Required
    dataReceved: Date;
    status: EBuyStatus; // Required
    transactionNumber?: string | null | undefined;
    info?: string | null | undefined;
    rejectedInfo?: string | null | undefined;
    dataRejected: Date;
    dataRefounded: Date;
    refoundedInfo?: string | null | undefined;
    refoundedIbanCardAddress?: string | null | undefined;
    fixedAmount: number;
    percentValue: number;
  }

export interface IConfirmBuyRequestModels{
    id: string;
    userName: string;
    eBuyType: EBuyType;
}

export interface IBuyRequestModel {
    amount: number;
    buyType: number;
    acceptTerms: boolean;
}

export interface ILastBuyTransaction {
    id: string;
    amount: number;
    netAmount: number;
    dataReceved: Date;
    buyType: string;
    refCode: string;
    divisa: string;
    decimals: number;
    buyStatus: string;
}

export interface IBuyTransactionsResponse {
    totalRecords: number;
    transactions: ILastBuyTransaction[];
}

export interface AdminBuyResponseModel {
    buy: Buy;
    userName: string;
}

export enum EBuyType
{
  Bonifico = 0,
  Paypal = 1,
  CreditCard = 2,
  Bitcoin = 3
}

export enum EBuyStatus
{
    Request = 0,
    Pending = 1,
    Confirmed = 2,
    Rejected = 3
}

