import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { nationsList } from '../../hooks/actions/users';

interface SearchNationsProps {
  setNazione: (value: string) => void;
  disable?: boolean;
  nazione: string
  label: string;
  error?: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  required?: boolean;
}

const SearchNations: React.FC<SearchNationsProps> = ({ setNazione, nazione, disable = true, label, setError, error, required=true}) => {
    const { t } = useTranslation();
    const [nations, setNations] = React.useState<string[]>([])

    React.useEffect(()=>{
      nationsList().then(result => {
        setNations(result)
      }).catch(e=>{
        setNations([]);
      })
    },[])

    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={nations}
        clearOnEscape
        onFocus= {
          () => {
            if(setError)
              setError(false)
          }
        }
        onChange = {
          (event, newValue) => {
            setNazione(newValue as string); 
          }
        }
        onBlur={()=>{
          if(setError){
            if(nazione === '')
              setError(true)
            else
            setError(false);
          }          
        }}
        value={nazione}
        disabled={disable}
        renderInput={(params) => <TextField 
              margin="normal" 
              {...params} 
              required={required}
              id={label}
              name={label}
              autoComplete={label}
              label={label} 
              disabled={disable}
              error={error!}
              helperText={
                error ? t("Selezione obbligatoria") : ""
              }
            />
          }
        />
    );
  }

  export default SearchNations;