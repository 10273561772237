import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, Box, Checkbox, TextField, MenuItem,
  FormControlLabel,
  FormGroup,
  Grid,
  alpha
} from '@mui/material';
import { AssociationConfirmUserModel, ConfirmUser, EApprovedFromAssociation, UserInfosModel } from '../../../models/user/UserInfosModel';
import { useTranslationContext } from '../../../hooks/translations/translationsProvider';
import { BLACK, BLUE_LIGHT_RGB_BORDER, GRAY_LIGHT_RGB_BORDER, WHITE } from '../../../theme/colors';
import { associationUsersPrivateList, postAssociationConfirmUser, usersPrivateList } from '../../../hooks/actions/admin';
import { delay } from '../../../utilities/delay';
import CustomWaiter from '../../Waiter';
import Search from '../../Search';
import { theme } from '../../../theme';

interface Props {
  onUserSelect: (user: UserInfosModel) => void;
}

const AssociationPrivateUsersTable: React.FC<Props> = ({
  onUserSelect,
}) => {
  const [users, setUsers] = useState<UserInfosModel[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [enabledFilter, setEnabledFilter] = useState<boolean | null>(null);
  const [approvalFilter, setApprovalFilter] = useState<EApprovedFromAssociation | null>(EApprovedFromAssociation.FirstRequest);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchWaiter, setSearchWaiter] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const {t} = useTranslationContext();
  const minChars = 2;

  const fetchUsers = () => {
    associationUsersPrivateList(page, rowsPerPage, searchTerm, approvalFilter, enabledFilter)
      .then(response => {
        console.log("users", response.users)
        setUsers(response.users);
        setTotalRecords(response.totalRecords);
      })
      .catch(e => console.error(e));

    delay(500).then(()=>{
      setSearchWaiter(false);
    })
  };

  useEffect(() => {
    fetchUsers();
  },[]); // ricerca all'avvio

   useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    setSearchWaiter(true);
    debounceTimeout.current = setTimeout(() => {
      fetchUsers();
      debounceTimeout.current = null;
    }, 1200);
  }, 
  [page, rowsPerPage, enabledFilter, approvalFilter, searchTerm]);

  const handleInput = (newString: string) => {
    if (newString.length < minChars) {
      setSearchTerm(null);
      return;
    }
    if (newString === searchTerm) return;
    if (newString.length > 20) return;
    setSearchTerm(newString);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (value: boolean | null) => {
    setEnabledFilter((prev) => (prev === value ? null : value));
  };

  const handleConfirmUser = useCallback(async(
      user: UserInfosModel,
      confirm: ConfirmUser,
    ) => {
      try {
        debugger;
        const model: AssociationConfirmUserModel = {
          associationId: user.associationId || "",
          userId: user.userid,
          confirmUser: confirm,
          userInfosModel: user,
        };
    
        // Effettua la richiesta POST
        const response = await postAssociationConfirmUser(model);
        console.log("Conferma effettuata con successo", response);
    
        // Aggiorna lo stato della lista utenti se necessario // lo farei con la risposta
        if(confirm === ConfirmUser.Confirm){
          setUsers(prevUsers =>
            prevUsers.map(u =>
              u.userid === user.userid
                ? { ...u, approvedFromAssociation: response.approvedFromAssociation, enabledUser: response.enabledUser }
                : u
            )
          );
        }else if(confirm === ConfirmUser.Reject){
          setUsers(prevUsers =>
            prevUsers.filter(u => u.userid !== user.userid) // Filtra gli utenti rimuovendo quello con lo stesso userid          
          );
        }else{}
        
      } catch (error) {
        console.error("Errore nella conferma utente", error);
      }
    }, [setUsers]);

  useEffect(()=>{
    console.log("filtro utenti", enabledFilter)
  },[enabledFilter])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', padding: 4 }}>
        <Grid container>
          <Grid item xs={12} sm={6} display={'flex'}>
            <Search 
                handleInput={handleInput}
                search={searchTerm!!}
                setSearch={setSearchTerm}
            />
            
            <div style={{
                paddingTop:'3px',
                paddingLeft:'3px',
                width:'50px',
                height:'35px'
            }}>
                {searchWaiter && <CustomWaiter size={30} width={40}/>}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label={t("")}
              value={approvalFilter === null ? "" : approvalFilter} 
              onChange={(e) => setApprovalFilter(e.target.value === '' ? null : parseInt(e.target.value, 10))}
              variant="outlined"
              sx={{ 
                width: '80%',
                height:'35px',
                backgroundColor: alpha(theme.palette.primary.light, 0.05),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                },
                '& .MuiInputLabel-root': {
                  marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                  color:'grey'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius:'5px',
                  border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                },
              }}
              InputProps={{
                style: {
                  height: '35px', // Imposta altezza anche alla radice del componente
                  
                },
              }}
            >
              <MenuItem value="">
                <em>{t("Tutti gli status")}</em>
              </MenuItem>
              {Object.keys(EApprovedFromAssociation).map((key) => {
                const typedKey = key as keyof typeof EApprovedFromAssociation;

                if (isNaN(Number(typedKey))) {
                  const value = EApprovedFromAssociation[typedKey];
                  const displayValue = value === 0 ? 0 : value;

                  return (
                    <MenuItem key={typedKey} value={displayValue}>
                      {typedKey === 'FirstRequest' ? t('FirstRequest') : t(typedKey)}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}mt={2}>
            <FormGroup row sx={{justifyContent:'center'}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFilter === true}
                            onChange={() => handleFilterChange(true)}
                            color="primary"
                            sx={{
                              '&. MuiCheckbox-root': {
                              },
                            }}
                        />
                    }
                    label={
                        <span style={{ color: "black", fontSize: '0.8rem' }}>
                            {t("Utenti Abilitati")}
                        </span>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFilter === false}
                            onChange={() => handleFilterChange(false)}
                            color="primary"
                            sx={{
                              '&.MuiCheckbox-root': {
                              },
                            }}
                        />
                    }
                    label={
                        <span style={{ color: "black", fontSize: '0.8rem' }}>
                            {t("Utenti Disabilitati")}
                        </span>
                    }
                />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper} sx={{boxShadow:5}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor:WHITE,
                  minWidth:'90px !important',
                  fontWeight:'bold',
                }}
                >
                  {t("EDIT")}
              </TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Nome")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Cognome")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Email")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Status")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Abilitato")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Codice Fiscale")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Data di Nascita")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Indirizzo")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Comune di Nascita")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Stato di Nascita")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("CAP")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Provincia")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Nazione")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Referral Code")}</TableCell>
              <TableCell sx={{fontWeight:'bold', whiteSpace:'nowrap'}}>{t("Data Eliminazione dalla Associazione")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user.userid} sx={{
                backgroundColor: user.enabledUser && user.approvedFromAssociation === EApprovedFromAssociation.ApprovedFromAssociation ? 'RGB(0,255,0,0.15)' : 
                                !user.enabledUser && user.approvedFromAssociation === EApprovedFromAssociation.FirstRequest ? WHITE :
                                !user.enabledUser || user.approvedFromAssociation === EApprovedFromAssociation.RemovedFromAssociation ? 'RGB(255,0,0,0.15)' : WHITE,
                cursor:'pointer'
              }}>
                <TableCell sx={{
                    position: 'sticky',
                    borderRight:'1px solid ' + GRAY_LIGHT_RGB_BORDER,
                    left: 0,
                    backgroundColor: WHITE,
                    zIndex:1,
                    minWidth:'90px !important',
                  }}
                >
                  {user.approvedFromAssociation === EApprovedFromAssociation.FirstRequest ?
                    <>
                     <Tooltip title={t("Approva Utente")}>
                        <IconButton onClick={() => {handleConfirmUser(user,ConfirmUser.Confirm)}}>
                          <CheckCircleIcon sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Rifiuta Utente")}>
                        <IconButton onClick={() => {handleConfirmUser(user,ConfirmUser.Reject)}}>
                          <CancelIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </Tooltip>
                   </> : user.approvedFromAssociation === EApprovedFromAssociation.RemovedFromAssociation ?
                   <Tooltip title={t("Approva Utente")}>
                      <IconButton onClick={() => {}}>
                        <CheckCircleIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip> :
                   <>
                      <Typography variant="h6" gutterBottom>
                        {t("Utente Approvato")}
                      </Typography>
                   </>
                  }
                </TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.nome}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.cognome}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.email}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{t(EApprovedFromAssociation[user.approvedFromAssociation ?? 0])}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.enabledUser ? t('Sì') : t('No')}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.codiceFiscale}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.born ? new Date(user.privateInfo?.born).toLocaleDateString() : ''}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.address}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.comuneDiNascita}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.statoDiNascita}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.cap}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.provincia}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.privateInfo?.nation}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>{user.refCode}</TableCell>
                <TableCell onClick={() => onUserSelect(user)}>
                  {user.removedFromAssociation &&
                  new Date(user.removedFromAssociation).getFullYear() !== 1
                    ? new Date(user.removedFromAssociation).toLocaleDateString()
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default AssociationPrivateUsersTable;
