import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { useTranslationContext } from '../../hooks/translations/translationsProvider'
import UserManagement from './Admin/Users'
import { GRAY_LIGHT_RGB_BORDER, WHITE } from '../../theme/colors'
import PurchasesManagement from './Admin/Purchases'
import { isMobile } from 'react-device-detect'
import AssociationUserManagement from './Association'
import RedeemManagement from './Admin/Redeem'
import UserBalances from './Admin/Balances'

interface AdminPAnelProps {
  type: string | null;
}

const AdminPanel:React.FC<AdminPAnelProps> = ({type}) => {
  const firstSelection = type === 'admin' ? 'users' : type === 'association' ? 'usersAssociation' : 'user';
  const [selectedSection, setSelectedSection] = useState<string>(firstSelection)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { t } = useTranslationContext();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  // Automatically show sidebar on MD and up
  React.useEffect(() => {
    setIsSidebarOpen(isMdUp)
  }, [isMdUp])

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      
      {/* MENU */}
      <Box
        sx={{
          marginTop:'-40px',
          width: isMobile ? '200px' : '25%',
          backgroundColor: WHITE,
          position: {
            xs: 'fixed',
            md: 'relative',
          },
          left: {
            xs: isSidebarOpen ? '0' : (isMobile ? '-200px' : '-25%'),
            md: 0,
          },
          transition: theme.transitions.create(['left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
        //   height: '100vh', 
          minHeight: '100vh', // Ensures it won't shrink when content is small %
          borderRight: '1px solid ' + GRAY_LIGHT_RGB_BORDER,
          // borderTop: '1px solid ' + GRAY_LIGHT_RGB_BORDER,
          borderLeft: {md: '1px solid ' + GRAY_LIGHT_RGB_BORDER}
        }}
      >
        {/* GESTIONE BOTTONE SCORRIMENTO MENU*/}
        {!isMdUp && (
          <IconButton
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            sx={{
              position: 'absolute',
              top: '20px', // Top space from the sidebar
              left: 'calc(100% - 46px)', // Position at the right of the sidebar
              transform: 'translateX(100%)',
              bgcolor: 'background.paper',
              boxShadow: 1,
              borderRadius: '0px',
              border: '1px solid',
              borderColor: 'divider',
              transition: theme.transitions.create(['left'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
              }),
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}

        {/* MENU  INTESTAZIONE */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            justifyContent: 'space-between',
          }}
        >
          { type === "admin" ? 
            <Typography variant="h6">{t('Admin Panel')}</Typography> :
            type === "association" ? 
            <Typography variant="h6">{t('Association Admin Panel')}</Typography> :
            <></>
          }
        </Box>

        <Divider />

        {/* MENU  ITEMS */}
        <Box sx={{ padding: 2, overflow: 'auto' }}>
          { type === "admin" ? 
            <List>
              <ListItem
                button
                selected={selectedSection === 'users'}
                onClick={() => setSelectedSection('users')}
              >
                <ListItemText primary={t("Users Informations")} />
              </ListItem>

              <ListItem
                button
                selected={selectedSection === 'purchases'}
                onClick={() => setSelectedSection('purchases')}
              >
                <ListItemText primary={t("Gestione Aquisti")} />
              </ListItem>

              <ListItem
                button
                selected={selectedSection === 'redeem'}
                onClick={() => setSelectedSection('redeem')}
              >
                <ListItemText primary={t("Gestione Riscatto ASSO")} />
              </ListItem>

              <ListItem
                button
                selected={selectedSection === 'balances'}
                onClick={() => setSelectedSection('balances')}
              >
                <ListItemText primary={t("Gestione Finanziaria Utenti")} />
              </ListItem>

              {/* <ListItem
                button
                selected={selectedSection === 'settings'}
                onClick={() => setSelectedSection('settings')}
              >
                <ListItemText primary={t('Settings')} />
              </ListItem> */}
            </List> :
            type === "association" ?
            <List>
              <ListItem
                button
                selected={selectedSection === 'usersAssociation'}
                onClick={() => setSelectedSection('usersAssociation')}
              >
                <ListItemText primary={t("Users Informations")} />
              </ListItem>
            </List> :
            <></>
            }
        </Box>
      </Box>

      {/* CONTENITORE PRINCIPALE*/}
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '75%',
          },
          padding: 2,
          ml: {
            xs: 0,
            md: 0,
          },
          overflow: 'auto',
        }}
      >
        { type === "admin" ? 
          <>
            {selectedSection === 'users' && <UserManagement/>}
            {selectedSection === 'purchases' && <PurchasesManagement/>}
            {selectedSection === 'redeem' && <RedeemManagement/>}
            {selectedSection === 'balances' && <UserBalances />}
          </> :
          type === "association" ?
          <>
            {selectedSection === 'usersAssociation' && <AssociationUserManagement/>}
          </> :
          <></>
        }
      </Box>
    </Box>
  )
}

export default AdminPanel
