

import { IRedeemRequestModel, IRedeemResponseModel } from "../../models/redeem";
import httpClient from "../../utilities/http-client";

export const checkIban = async (iban: string) => {
	try {
		const { data } = await httpClient.get(`/services/verify-iban?iban=${iban}`);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const redeemRequest = async (model: IRedeemRequestModel) => {
	try {
		const { data } = await httpClient.post<IRedeemResponseModel>(`/redeem`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

