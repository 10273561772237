import { useAuth } from "react-oidc-context";
import { Box, Typography, styled, alpha} from '@mui/material'
import { BLUE_LIGHT, BLUE_LIGHT_RGB_BORDER, BLUE_MAIN, WHITE } from "../../theme/colors";
import { useTranslationContext } from "../../hooks/translations/translationsProvider";
import { AssociationItemProps } from "./interfaces";

const LinkBase = styled('a')(({ theme }) => ({
    textDecoration: 'none', 
    color:'inherit',
    '&:hover': {
        letterSpacing:'0.4px'
    },
}));

const AssociationsItem = (props:AssociationItemProps) => {
    const {t} = useTranslationContext();

    return (
        <Box sx={{
            width:'100%',
            border:'1px solid '+ BLUE_LIGHT_RGB_BORDER,
            minHeight:'120px',
            dispaly:'flex',
            flexDirection:'column',
            margin:'10px 0',
            borderRadius:'5px'
        }}>
            <Box sx={{
                display:'flex',
                width:'100%',
                height:'30px',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor: alpha(BLUE_LIGHT, 0.05),
                borderBottom:'1px solid '+ BLUE_LIGHT_RGB_BORDER,
                fontSize:'1.2em',
                fontWeight:600
            }}>
                {props.name}
            </Box>

            <Box sx={{
                width:'100%',
                display:'flex',
                flexDirection: 'column',
                textAlign:'start',
                padding:'5px',
            }}>
                <Typography variant='body2'>
                    <strong>{t("Città: ")}</strong> {props.city} {props.province}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Nazione: ")}</strong> {props.nation}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Email: ")}</strong> {props.mail ? <LinkBase href={`mailto:${props.mail}`} target="_blank">{props.mail}</LinkBase> : ''}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Telefono: ")}</strong> {props.phone}
                </Typography>

                <Typography variant='body2'>
                    <strong>{t("Sito internet: ")}</strong> {props.website ? <LinkBase href={props.website} target="_blank">{props.website}</LinkBase> : ''}
                </Typography>
                
            </Box>
            
        </Box>
    );
}

export default AssociationsItem;