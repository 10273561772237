import { Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useAuth } from 'react-oidc-context';
import {Box, Grid, Button} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResponsiveLayout } from '../../components/ResponsiveLayout';
import { isMobile } from 'react-device-detect';
import { GREEN_LOGO, GREEN_MAIN, WHITE } from '../../theme/colors';
import settings from '../../utilities/settings';
import { getFromLocalStorage } from '../../helpers/localStorage';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';

const RegisterEconomicsCompany = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [url, setUrl]= useState<string>('');
    const location = useLocation();
    const {t} = useTranslationContext();
    const ReturnUrl = settings.Domains.ServiceClient + "/company/register/callback"
    let referral: string | undefined = getFromLocalStorage("Referral");

    useEffect(()=>{
        if(auth.isAuthenticated)
            navigate('/home')
    },[auth.isAuthenticated])

    useEffect(()=>{
        setUrl(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`)
    },[location])

    const ImageContent = () => (
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: 2,
          }}
          alt="ASSO circuit board with tree"
          src="/Partecipa_attività_economica.jpg" 
        />
      )
    
    const TextContent = () => {
        const titleSize = isMobile ? "1.4rem" : "1.3rem"
        const bodySize = isMobile ? "1rem" : "1.1rem"
        const buttonSpacing = isMobile ? 1 : 2
    
        return (
          <Box
            sx={{
              color: "darkgreen",
            //   p: isMobile ? 2 : 4,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            {/* Main title */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontSize: titleSize,
                fontWeight: 500,
                mb: 3,
                // fontWeight: "bold",
                textAlign:"justify"
              }}
            >
              Partecipa come <strong>Attività economica</strong>
            </Typography>
    
            {/* First paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 0,
                textAlign:"justify"
              }}
            >
              Registra la tua Attività economica sul circuito ASSO! Ptrai utilizzare ben <strong>tre piattaforme dedicate </strong>e godere di tutti i vantaggi collegati all'utilizzo degli ASSO.
            </Typography>

            {/* Pre-Bullet paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              La tua Attività economica, inoltre, potrà:
            </Typography>

            {/* Bullet points */}
            <Box sx={{ my: 1, ml: 1 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  mb: 2
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                    ▸
                  </Box>
                  <Box component="span" textAlign="left">
                    <strong>vendere </strong>all'interno del circuito ASSO
                  </Box>
                </Box>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  textAlign:"left",
                  mb: 1,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                <Box component="span" textAlign="left">
                  <strong>vendere </strong>sul mercato on line di Assobay
                </Box>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  textAlign:"left",
                  mb: 1,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                <Box component="span" textAlign="left">
                  <strong>riscattare gli ASSO </strong>eventualmente incassati convertendoli in Euro
                </Box>
              </Typography>
            </Box>

            {/* Second paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify",
                // fontWeight:"bold"
              }}
            >
              La partecipazione al circuito ASSO è gratuita, le vendite sul mercato on line di Assobay pagano una piccola commissione.
            </Typography>

            {/* Third paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 6,
                textAlign:"justify",
                fontWeight:"bold"
              }}
            >
              Non solo un innovativo circuito economico con meravigliose finalità sociali: per la tua attività economica ASSO può essere anche un nuovo grande mercato.
            </Typography>
        
            {/* Participation section */}
            <Box sx={{ mt: isMobile ? 2 : 1 }}>
                  
              {/* Buttons - responsive grid */}
              <Grid
                container
                spacing={buttonSpacing}
                justifyContent="center"
                direction={isMobile ? "column" : "row"}
                alignItems="center"
              >
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                        const url = referral ? settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&Type=2&UserRef=${referral}`: settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&Type=2`
                        window.location.href = url
                    }}
                  >
                    {t("REGISTRATI")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      }

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <ResponsiveLayout imageContent={<ImageContent/>} textContent={<TextContent/>} />
                </Box>
            </Box>
        </>
    )
}

export default RegisterEconomicsCompany;