import { Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useAuth } from 'react-oidc-context';
import {Box, Grid, Button} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResponsiveLayout } from '../components/ResponsiveLayout';
import { isMobile } from 'react-device-detect';
import { GREEN_LOGO, GREEN_MAIN, WHITE } from '../theme/colors';
import settings from '../utilities/settings';
import { getFromLocalStorage } from '../helpers/localStorage';

const Home = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [url, setUrl]= useState<string>('');
    const location = useLocation();
    const ReturnUrl = settings.Domains.ServiceClient + "/company/register/callback"
    let referral: string | undefined = getFromLocalStorage("Referral");

    useEffect(()=>{
        if(auth.isAuthenticated)
            navigate('/home')
    },[auth.isAuthenticated])

    useEffect(()=>{
        setUrl(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`)
    },[location])

    const ImageContent = () => (
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: 2,
          }}
          alt="ASSO circuit board with tree"
          src="/home.jpeg" 
        />
      )
    
    const TextContent = () => {
        const titleSize = isMobile ? "1.4rem" : "1.3rem"
        const bodySize = isMobile ? "1rem" : "1.1rem"
        const buttonSpacing = isMobile ? 1 : 2
    
        return (
          <Box
            sx={{
              color: "darkgreen",
            //   p: isMobile ? 2 : 4,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            {/* Main title */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontSize: titleSize,
                fontWeight: 500,
                mb: 3,
              }}
            >
              Benvenuti su ASSO, il primo circuito economico:
            </Typography>
    
            {/* Bullet points */}
            <Box sx={{ my: 2, ml: 1 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                di proprietà delle associazioni
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                destinato alle associazioni
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                con finalità sociali
              </Typography>
            </Box>
    
            {/* First paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              Decine di Associazioni, provenienti da tutta Europa, sono le proprietarie di ASSO, il nuovo circuito economico
              riservato ai soci delle Associazioni stesse e ai soggetti convenzionati.
            </Typography>
    
            {/* Second paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              ASSO è un progetto internazionale che coinvolge migliaia di famiglie e che è caratterizzato da una
              meravigliosa finalità:
            </Typography>
    
            {/* Bold paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                fontWeight: "bold",
                mb: 4,
                textAlign:"justify"
              }}
            >
              tutte le risorse generate dal circuito vengono reinvestite nei territori in numerosi e concreti PROGETTI
              SOCIALI: proprietà diffusa, benessere per tutti.
            </Typography>
    
            {/* Participation section */}
            <Box sx={{ mt: isMobile ? 2 : 1 }}>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  my: 3,
                  fontSize: isMobile ? "1.4rem" : "1.3rem",
                  fontWeight: 500,
                }}
              >
                Partecipa come
              </Typography>
    
              {/* Buttons - responsive grid */}
              <Grid
                container
                spacing={buttonSpacing}
                justifyContent="center"
                direction={isMobile ? "column" : "row"}
                alignItems="center"
              >
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                        navigate(`/private/register`)
                    }}
                  >
                    PRIVATO
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                      navigate('/economics-activities/register')
                    }}>
                    ATTIVITA' ECONOMICA
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                        navigate('/associations/register');
                    }}
                  >
                    ASSOCIAZIONE
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      }

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <ResponsiveLayout imageContent={<ImageContent/>} textContent={<TextContent/>} />
                </Box>
            </Box>
        </>
    )
}

export default Home;