import React, { useEffect, useState } from 'react';
import { Snackbar, Button, Link } from '@mui/material';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import { BLACK, WHITE } from '../theme/colors';

const CopyToClipboard: React.FC<{ text: string}> = ({ text }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslationContext();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setShowPopup(true); 
    } catch (err) {
      console.error('Errore durante la copia negli appunti:', err);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <div>
        <Link href="#" sx={{ 
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',  
            wordWrap: 'break-word', 
            color: WHITE,
            textDecoration: 'none',
            maxWidth: '100%',
          }} 
            onClick={
                () =>{
                    copyToClipboard().finally()
                }
            }
        >
            {text}
        </Link>
      <Snackbar
        open={showPopup}
        autoHideDuration={2000} 
        onClose={handleClose}    
        message={t("Copiato negli appunti!")}
        anchorOrigin={{          // Posiziona il popup vicino al click del mouse
            vertical: 'top',
            horizontal: 'right',
        }}
        ContentProps={{
                sx:{
                    backgroundColor:WHITE,
                    color:BLACK,
                }
        }}
      />
    </div>
  );
};

export default CopyToClipboard;
