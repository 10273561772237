import { Container, styled, Box, Link, Typography } from '@mui/material';
import { WHITE, BLUE_LIGHT_RGB_BORDER, GREEN_MAIN, GREEN_LOGO } from '../theme/colors';
import { isMobile } from 'react-device-detect';

export const AppContainer = styled('div')(({theme})=>({
  display:'flex',
  textAlign:'center',
  flexDirection: 'column',
  // minHeight:'100vh'
}))
  
interface ContainerCustomProps{
  headerH:number,
  footerH: number 
}
export const ContainerCustom = styled(Container)<ContainerCustomProps>(({theme, headerH, footerH})=>({
  position:'relative',
  display:'flex',
  flexGrow:1,
  paddingTop: headerH >= 50 ? headerH +'px' : '150px',
  paddingBottom: footerH + "px"
}))
  
export const FooterContainer = styled('div')(({theme})=>({
  bottom:'0px',
  left:'0px',
  width:'100%',
  position:'fixed',
  display:'flex',
  justifyContent:"center",
  padding:"5px 0",
  height:!isMobile ? undefined : '56px',
  backgroundColor:GREEN_LOGO,
  borderTop:"1px solid " + GREEN_LOGO,
  zIndex:1000,
}))

export const Logo = styled('img')(({theme})=>({
  height: 35,
  // marginTop:!isMobile ? (!isScrolled ? '-35px': '0px') : '0px',
  // transition: 'height 0.5s ease', 
  // [theme.breakpoints.down('md')]: {
  //   marginTop: !isMobile ? '0px' : undefined,
  //   height: !isMobile ? (!isScrolled ? '90px' : '50px') : '40px',
  // },
  // [theme.breakpoints.only('xs')]: {
  //   marginTop: !isMobile ? '0px' : undefined,
  //   height: !isMobile ? (!isScrolled ? '60px' : '50px') : '40px',
  // },
}))

export const FooterLink = styled(Link)({
  color: WHITE,
  textDecoration: "none",
  fontSize: "0.7rem",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "block",
  marginBottom: "-1px",
})

export const FooterText = styled(Typography)({
  color: WHITE,
  fontSize: "0.7rem",
  marginBottom: "-1px",
})

export const WaiterContainer = styled('div')(({theme})=>({
  position:'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top:0,
  left:0,
  bottom:0,
  right:0,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex:1100
}))