import { Transactions } from "../../models/dashboard/Transactions";
import httpClient from "../../utilities/http-client";

export const lastTransactionsRequest = async (): Promise<Transactions[] | null> => {
	try {
		const { data } = await httpClient.get<Transactions[]>(`/tokens/last`);
		if(data && data.length > 0)
			return data;
		else
			return null;
	} catch (e:any) {
		throw e.response
	}
};