import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserInfos from '../../components/UserInfo/UserInfos';
import AccordionCustom from '../../components/AccordionCustom/AccordionCustom';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { useUserContext } from '../../hooks/auth/UserProvider';
import { useNavigate } from 'react-router-dom';
import AdminPanel from '../../components/AdminPanel';


const DefaultAdminOrAssociationPage = () => {
  const {t} = useTranslationContext();

  // utente autorizzato
  const {user, auth} = useUserContext();
  const navigate = useNavigate();
  const {checkIsAuth} = useUserContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAssociation, setIsAssociation] = useState<boolean>(false);
  const [type, setType] = useState<string | null>(null);

  useEffect(()=>{
    if (!auth.isLoading) {
        if (!auth.isAuthenticated) {
            navigate('/');
        } else {
          if(user){
            console.log("USER ROLES:", user.role);
            // ADMIN
            const adminRoles = ["DevSuperAdmin", "SuperAdmin", "Admin"];
            // Association
            const associationRoles = ["Association"];
            
            const hasRole = (userRoles: string | string[] | undefined | null, rolesToCheck: string[]): boolean => {
                if (Array.isArray(userRoles)) {
                    return userRoles.some((role: string) => rolesToCheck.includes(role));
                } else if (typeof userRoles === "string") {
                    return rolesToCheck.includes(userRoles);
                }
                return false; // Se userRoles è undefined o null
            };
            
            // ADMIN
            if (hasRole(user.role, adminRoles)) {
              setIsAdmin(true);
              setType("admin");
            }
            // ASSOCIATION
            else if (hasRole(user.role, associationRoles)) {
              setIsAssociation(true);
              setType("association");
            }
            // OTHER
            else  {
              setType(null)
              navigate('/');
            }
          }
        }
    }
    checkIsAuth().finally();
  },[auth.isAuthenticated, auth.isLoading, navigate, user])

  return (
    <>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight:'100%',
      }}>
        {
          auth.isAuthenticated && 
          <>
            {isAdmin && <AdminPanel type={type}/>}
            {isAssociation && <AdminPanel type={type}/>}
          </>
        }
        
      </Box>
    </>
  );
};

export default DefaultAdminOrAssociationPage;
