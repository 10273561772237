import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Grid, Box, IconButton, alpha, MenuItem, Divider } from '@mui/material';
import { useTranslationContext } from '../../../../hooks/translations/translationsProvider';
import { Close as CloseIcon } from '@mui/icons-material';
import AccordionCustom from '../../../AccordionCustom/AccordionCustom';
import UserTokens from './UserBalances/Tokens/UserTokens';
import UserCredits from './UserBalances/Credits/UserCredits';

interface PrivateProps {
  userId: string;
  userName: string;
  isPrivate: boolean;
  userAddress: string;
  onBack: () => void;
}

const AdminBalancesUser: React.FC<PrivateProps> = ({userId, userName, isPrivate, userAddress, onBack}) => {  
  const { t } = useTranslationContext();
  const [mainError, setMainError] = useState<string | null>(null);

  useEffect(() => {
    // Scrolla verso l'alto
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <Box
          sx={{
            display:'flex',
            justifyContent:'flex-end'
          }}
        >
          <IconButton onClick={() => { 
            onBack(); 
          }} 
             sx={{
              backgroundColor: 'white', // Sfondo bianco
              color: 'grey', // Colore dell'icona rosso
              '&:hover': {
                backgroundColor: 'rgba(128,128,128, 0.1)', // Sfondo rosso chiaro al passaggio del mouse
              },
              borderRadius: '50%', // Per renderlo rotondo
              padding: 1, // Margine interno
            }}
          >
            <CloseIcon sx={{
                fontSize:"40px",
                '&:hover': {
                  fontWeight:'bold'
                },
              }}
            />
          </IconButton>
        </Box>

        <h5>{isPrivate ? t("Utente Privato") : t("Azienda o Associazione")}</h5>
        <Box textAlign='left'>
          <Typography fontSize={18}><strong>{t("Nome:  ")}</strong>{userName}</Typography>
          <Typography fontSize={18}><strong>{t("Indirizzo:  ")}</strong>{userAddress}</Typography>
        </Box>

        <Box sx={{
          height:"30px"
        }}/>

        <AccordionCustom summayTxt={t('Tokens ASSO')}>
          <UserTokens userId={userId}/>
        </AccordionCustom>

        <AccordionCustom summayTxt={t('Crediti Utente')}>
          <UserCredits />
        </AccordionCustom>

        {mainError && 
          <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
              {mainError}
            </Typography>
          </>
        }
      </div>
    </>
  );
};


export default AdminBalancesUser;
