import React, { useState, useEffect, useCallback, useRef } from 'react';
import {Box, Typography, Grid, Button, Card, Link, Paper,IconButton } from '@mui/material';
import { BLUE_DARK, BLUE_LIGHT, BLUE_LIGHT_RGB_BORDER, BLUE_MAIN } from '../theme/colors';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import { useLocation } from 'react-router-dom';
import  Web  from '@mui/icons-material/Language';
import Search from '../components/Search';
import { associationsListSearch, associationsList } from '../hooks/actions/circuito';
import { LegalEntity } from '../models/circuito/LegacyEntity';
import { useWaiter } from '../hooks/waiter/WaiterProvider';
import { delay } from '../utilities/delay';
import CustomWaiter from '../components/Waiter';
import { isMobile } from 'react-device-detect';
import { marketsList, marketsListSearch } from '../hooks/actions/market';
import { LegacyMarket } from '../models/market/LegacyMarket';
import MarketItem from '../components/MarketsItems';
import settings from '../utilities/settings';

const MarketsList = () => {
    const {t} = useTranslationContext();
    const location = useLocation();
    const [url, setUrl] = useState<string>('');
    const [search, setSearch] = useState<string>('')
    const [listMarketsVip, setListMarketsVip] = useState<LegacyMarket[]|undefined>();
    const [listMarkets, setListMarkets] = useState<LegacyMarket[]|undefined>();
    const [searchWaiter, setSearchWaiter] = useState<boolean>(false)

    const {setWaiterView} = useWaiter();

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(()=>{ // carico i markets che hanno un 
        setWaiterView(true);
        marketsList().then((resp) => {
            setListMarketsVip(resp);
            delay(500).then(()=>{
                setWaiterView(false);
            })
        }).catch(e=>{
            console.log(e)
            delay(500).then(()=>{
                setWaiterView(false);
            })
        })
    },[])

    useEffect(()=>{
        setUrl(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`)
    },[location])

    useEffect(()=>{
        if(search.length >= 2) {
            setSearchWaiter(true)
        }else{
            setSearchWaiter(false)
            setListMarkets(undefined)
        }
            
        const performSearch = async () => {
            try {
                if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
                const minChars = 2;
                let respSearch;
                
                debounceTimeout.current = setTimeout(async () => {
                    if (search.length >= minChars) {
                        respSearch = await marketsListSearch(search);
                        setListMarkets(respSearch);
                        debounceTimeout.current = null;
                        setSearchWaiter(false)
                    }
                }, 1200);
            } catch (e) {
                console.log(e);
                setSearchWaiter(false)
            }
        };

        performSearch();
    },[search])

    const handleInput = async (input: string) => {
        const newString = input.replace(' ', '')
        if(newString === search) return; 

        if(newString.length > 20) return;

        setSearch(newString);
    }

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <Grid container spacing={0}  justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                        <Grid item xs={!isMobile ? 10 : 12} sm={6} md={4} display={'flex'}> 
                            <Search 
                                handleInput={handleInput}
                                search={search}
                                setSearch={setSearch}
                                palceHolder='Cerca per Prodotto, Fornitore, Città..'
                            />
                            
                            <div style={{
                                paddingTop:'3px',
                                paddingLeft:'3px',
                                width:'50px',
                                height:'35px'
                            }}>
                                {searchWaiter && <CustomWaiter size={30} width={40}/>}
                            </div>
                        </Grid>
                    </Grid>

                    <Box sx={{height:'30px'}}/>

                    <Typography variant='h3'>{t("IL MERCATO")}</Typography>

                    {
                        settings.MarketPlace.IsVisible && <>
                            <Box sx={{height:'40px'}}/>
                            <Typography variant='h4'>{t("Il nostro Market Place")}</Typography> 
                            <Box sx={{height:'30px'}}/>

                            <Box sx={{ }}>
                                <Link
                                    href={settings.MarketPlace.Url} // URL esterno al quale redirigere
                                    underline="none"
                                    sx={{ display: 'block', textDecoration: 'none' }}
                                    target="_blank" // Apre il link in una nuova scheda
                                    rel="noopener noreferrer" // Per motivi di sicurezza
                                >
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            cursor: 'pointer', // Mostra il cursore a forma di mano per indicare che è cliccabile
                                            transition: 'background-color 0.3s', // Transizione per il colore di sfondo al passaggio del mouse
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5', // Colore di sfondo quando si passa sopra
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{ padding:3 }}
                                        >
                                            <Box
                                                sx={{
                                                    display:'flex',
                                                    justifyContent:'flex-start'
                                                }}
                                            >
                                                <img
                                                    src={`/${settings.MarketPlace.Logo}`}
                                                    alt={settings.MarketPlace.Alt}
                                                    style={{ height: 30 }}
                                                />
                                            </Box>
                                            
                                            {/* Titolo */}
                                            <Typography variant="h5" gutterBottom sx={{ mb:4}}>
                                            {settings.MarketPlace.Name}
                                            </Typography>
                                            
                                            {/* Icona e URL */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                                                <IconButton>
                                                    <Web />
                                                </IconButton>
                                                <Typography variant="body1" sx={{ ml: 1 }}>
                                                    {settings.MarketPlace.UrlWithouthProtocol}
                                                </Typography>
                                            </Box>
                                            
                                            {/* Descrizione */}
                                            <Typography variant="body1">
                                                {t(settings.MarketPlace.Description)}
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Link>
                            </Box> 
                            <Box sx={{height:'50px'}}/>
                        </>
                    }    


                    <Box sx={{height:'40px'}}/>

                    {
                        listMarkets && listMarkets.length > 0 ? (
                            listMarkets.map((entity, index) => (
                            <div key={index}>
                                <Typography variant='h5'>{t("Elementi trovati dalla ricerca")}</Typography>  
                                <Box
                                    sx={{
                                        display:'flex',
                                        justifyContent:'center',
                                        padding:'20px 0 10px 0',
                                        borderTop:'1px solid '+BLUE_LIGHT_RGB_BORDER
                                    }}
                                >
                                    <Typography variant='h4' color={BLUE_DARK}>{ entity.nationSedeLegale }</Typography>
                                </Box>

                                {
                                    entity.markets.map((item, subindex)=>(
                                        <div key={subindex}>
                                            <MarketItem // SOSTITUIRE QUESTO CON GLI ITEM DEL MARKET
                                                name={item.marketName} 
                                                nation={item.marketState}
                                                mail={item.marketMail}
                                                website={item.marketWebSite} 
                                                phone={item.marketTelephone}
                                                city={item.marketCity}
                                                province={item.marketProvince}
                                                type={item.marketType}
                                                typeActivity={item.marketTypeActivity}
                                                description={item.description}
                                                tags={item.tags}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        ):(
                            listMarkets !== undefined && <Box
                                sx={{
                                    display:'flex',
                                    justifyContent:'center',
                                    padding:'50px 0 10px 0',
                                }}
                            >
                                <Typography variant='body1'>{
                                    search.length >= 2 && 
                                    t("Non ci sono elementi da visualizzare.")
                                }</Typography>
                            </Box>   
                        )
                    }
                </Box>
            </Box>
        </>
    )
}

export default MarketsList;