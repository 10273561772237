import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {theme} from './theme';
import { ThemeProvider } from '@mui/material';
import { TranslationProvider } from './hooks/translations/translationsProvider';
import './i18n.config'
import { UserProvider } from './hooks/auth/UserProvider';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import settings from './utilities/settings';
import { DrawerProvider } from './hooks/drawer/DrawerProvider';
import { BrowserRouter } from 'react-router-dom';
import { WebStorageStateStore } from 'oidc-client-ts';
import { WaiterProvider } from './hooks/waiter/WaiterProvider';
import Web3CustomProvider from '@w3connector/web3provider';
import '@fontsource/poppins';

const configuration:AuthProviderProps = {
  authority: settings.oidc.authority,
  client_id: settings.oidc.clientId,
  client_secret: settings.oidc.clientSecret,
  response_type: settings.oidc.responseType,
  scope: settings.oidc.clientScopes,
  redirect_uri: settings.oidc.redirectUri,
  post_logout_redirect_uri: settings.oidc.postLogoutRedirectUri,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({
    store: localStorage
  })
};

const chainId = settings.Token.Blockchain.ChainId;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Web3CustomProvider chains={settings.DefaultChain} chainId={chainId} projectId={settings.WalletConnectId}> 
      <BrowserRouter>
        <AuthProvider {...configuration}>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <TranslationProvider>
                <DrawerProvider>
                  <WaiterProvider>
                    <App />
                  </WaiterProvider>
                </DrawerProvider>
              </TranslationProvider>
            </ThemeProvider>
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </Web3CustomProvider> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
