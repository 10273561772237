import { useState, useEffect } from 'react';

const UseFooterHeight = () => {
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const footer = document.getElementById('footer');
    if (footer) {
      const updateFooterHeight = () => {
        setFooterHeight(footer.offsetHeight);
      };

      const resizeObserver = new ResizeObserver(() => {
        updateFooterHeight();
      });

      resizeObserver.observe(footer);

      // Initial update
      updateFooterHeight();

      return () => {
        resizeObserver.unobserve(footer);
      };
    }
  }, []);

  return footerHeight;
};

export default UseFooterHeight;