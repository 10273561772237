import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import settings from '../utilities/settings';
import { BLACK } from '../theme/colors';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import { useTranslation } from 'react-i18next';

const DropDownLanguages: React.FC = () => {
    const {changeLanguage, languages} = useTranslationContext();
    const { t, i18n } = useTranslation();

    return (
        <Box sx={{
            maxWidth:'120px'
        }}>
            <FormControl fullWidth variant="standard">
                <Select size="small"
                    value={i18n.language}
                    onChange={(event:any) => {
                        changeLanguage(event.target.value)
                        
                    }}
                >
                {languages.map((value, index) => {
                    return (
                        <MenuItem key={value.code} value={value.code} sx={{
                            fontSize:'0.8em'
                        }}>
                            {value.label.toUpperCase()}
                        </MenuItem>
                    )
                })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default DropDownLanguages;
