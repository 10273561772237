import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { HeaderContainer, ContainerHiddenSubHeader, Logo, GridLogin, GridMenuHiddenUp, GridMenuHiddenDown } from "./template";
import DropDownLanguages from "../DropDownLanguages";
import { BLACK, BLUE_MAIN, GREEN_MAIN, WHITE } from "../../theme/colors";
import { Grid, Box, Avatar, IconButton, styled } from "@mui/material";
import settings from "../../utilities/settings";
import LoginButton from "../LoginButton";
import RegisterButton from "../RegisterButton";
import { useAuth } from "react-oidc-context";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IDrawerItem } from "../../hooks/drawer/models";
import { useUserContext } from "../../hooks/auth/UserProvider";
import { useDrawer } from "../../hooks/drawer/DrawerProvider";
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@mui/icons-material/Apps';
import CloseIcon from '@mui/icons-material/Close';
import { BLUE_LIGHT_RGB_BORDER } from "../../theme/colors";
import { CenterFocusWeakTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Header = () =>{
    const { t, i18n } = useTranslation();
    const [isScrolled, setIsScrolled] = useState(false);
    const auth = useAuth();
    const {user} = useUserContext();
    const drawer = useDrawer();
    const [menuMobileOpen, setMenuMobileOpen] = useState(false);
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isAssociation, setIsAssociation] = useState<boolean>(false);
    const [isCompany, setIsCompany] = useState<boolean>(false);

    const toggleMenuMobile = () => {
        setMenuMobileOpen(!menuMobileOpen);
    };
  
    const handleScroll = () => {
        const bodyHeight = document.body.scrollHeight; // Altezza totale della pagina
        const windowHeight = window.innerHeight; // Altezza visibile della finestra

        const headerFullHeight = !isMobile ? 180 : 70; // Altezza dell'header normale (desktop)
        const headerReducedHeight = !isMobile ? 120 : 70; // Altezza dell'header ridotto (desktop)
        const headerDifference = headerFullHeight - headerReducedHeight; // Differenza altezza

        if (window.scrollY > headerDifference &&
            bodyHeight - windowHeight > headerDifference * 3
        ) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(()=>{
        if (!auth.isLoading) {
            if (auth.isAuthenticated) {
                if(user){
                    console.log("USER ROLES:", user.role);
                    // ADMIN
                    const adminRoles = ["DevSuperAdmin", "SuperAdmin", "Admin"];
                    // Association
                    const associationRoles = ["Association"];
                    // Company
                    const companyRoles = ["Company"];

                    const hasRole = (userRoles: string | string[] | undefined | null, rolesToCheck: string[]): boolean => {
                        if (Array.isArray(userRoles)) {
                            return userRoles.some((role: string) => rolesToCheck.includes(role));
                        } else if (typeof userRoles === "string") {
                            return rolesToCheck.includes(userRoles);
                        }
                        return false; // Se userRoles è undefined o null
                    };
                    
                    // ADMIN
                    if (hasRole(user.role, adminRoles)) {
                        setIsAdmin(true);
                    }
                    
                    // Association
                    if (hasRole(user.role, associationRoles)) {
                        setIsAssociation(true);
                    }
                    
                    // Company
                    if (hasRole(user.role, companyRoles)) {
                        setIsCompany(true);
                    }
                }
            }
        }
    },[auth.isAuthenticated, auth.isLoading, user])

    const userDrawerUnloggedItems: (IDrawerItem[] | null)[] = [
        !auth.isAuthenticated ? [
            {
                text : t("HOME"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/'
            },
            {
                text : t("GLI ASSO"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/asso'
            },
            {
                text : t("LA PIATTAFORMA"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/platform'
            },
            {
                text : t("IL CIRCUITO"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/network'
            },
            {
                text : t("IL MERCATO"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/market'
            },
            // {
            //     text : t("CONTATTI"),
            //     // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
            //     navigation: '/contacts'
            // },
        ] : [
            {
                text : t("HOME"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/home'
            },
            {
                text : t("TRANSAZIONI"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/transactions'
            },
            {
                text : t("ACQUISTA"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/buy'
            },
            {
                text : t("PRELEVA"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/withdraw'
            },
            {
                text : t("PROFILO"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/profile'
            },
        ],
        isAssociation || isCompany ? [
            {
                text : t("RISCATTA"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/redeem'
            },
            {
                text : t("DEPOSITA"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/deposit'
            },
        ] : null,

        isAdmin || isAssociation ? [
            {
                text : t("AMMINISTRAZIONE"),
                // avatar: <PersonOutlineIcon sx={{fontSize:'25px'}} />,
                navigation: '/admin'
            },
        ] : null
    ]
    
    const name = user?.userType == 'Private' ? user?.privateInfo?.Name : user?.companyAssociationInfo?.Name ?? ''
    const drawerTitle = (t("Benvenuto") + " " + (name === null ? '' : name) )

    return (
        <>
            {!isMobile ? (
                <HeaderContainer isScrolled={isScrolled} id="header">
                    <ContainerHiddenSubHeader isScrolled={isScrolled}>
                        <Grid container xs={12} spacing={0} justifyContent={{xs:'end'}} sx={{
                            backgroundColor:WHITE,
                        }}> 
                            {!auth.isAuthenticated && 
                                <GridLogin item margin={'0 5px'}>
                                    <RegisterButton />
                                </GridLogin>
                            }
                            <GridLogin item margin={'0 5px'}>
                                <LoginButton />
                            </GridLogin>
                            <GridLogin item margin={'0 5px'}>
                                <DropDownLanguages />
                            </GridLogin>
                        </Grid>
                    </ContainerHiddenSubHeader>
                    <Grid container spacing={1} flexGrow={1} marginTop={0} sx={{backgroundColor:WHITE, color: BLUE_MAIN}}>
                        <Grid item xs={6} sm={4} sx={{
                            flexGrow:1,
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            margin:'0px !important',
                            padding:'0px !important',
                            backgroundColor:WHITE
                        }}>
                            <Logo src={"/"+settings.Details.Logo} 
                                alt="Plutima Provider" 
                                isScrolled={isScrolled} 
                                onClick={()=>{navigate("/")}} 
                                sx={{cursor: 'pointer'}}
                            />
                        </Grid>
                        <Grid item xs={6} sm={8} sx={{
                            flexGrow:1,
                            display:'flex',
                            justifyContent:'end',
                            alignItems:'center',
                            backgroundColor:WHITE,
                        }}>
                            <Grid container md={12} spacing={1} justifyContent={'end'} marginRight={'15px'}
                                sx={{ 
                                    borderBottom: { xs: 'none', md: isScrolled ? 'none' : '1px solid green' }, 
                                    paddingBottom: { xs: '0px', md: isScrolled ? '0' : '15px' }, 
                                    marginTop: {md: isScrolled ? '0' : '-40px'}
                                }}> 
                                { /** Not Auth - Not Mobile */
                                !auth.isAuthenticated && 
                                    <>
                                        {/** OVER MD */}
                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/")}}
                                            
                                        > 
                                            {t("HOME")}
                                        </GridMenuHiddenDown>
                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/asso' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/asso")}}
                                            
                                        > 
                                            {t("GLI ASSO")}
                                        </GridMenuHiddenDown>
                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/platform' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/platform")}}
                                            
                                        > 
                                            {t("LA PIATTAFORMA")}
                                        </GridMenuHiddenDown>
                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/network' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/network")}}
                                            
                                        > 
                                            {t("IL CIRCUITO")}
                                        </GridMenuHiddenDown>

                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/market' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/market")}}
                                        > 
                                            {t("IL MERCATO")}
                                        </GridMenuHiddenDown>

                                        {/* <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/contacts' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/contacts")}}
                                        > 
                                            {t("CONTATTI")}
                                        </GridMenuHiddenDown> */}

                                        {/** UNDER MD */}
                                        <GridMenuHiddenUp breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        > 
                                            <IconButton onClick={() => {drawer.openOrCloseDrawer(
                                                    userDrawerUnloggedItems, 
                                                    drawerTitle!
                                                )}} >
                                                <Avatar sx={{ width:'40px', height:'40px', backgroundColor: WHITE, color: GREEN_MAIN, alignItems:'center' }}>
                                                    <AppsIcon sx={{fontSize:'35px'}} />
                                                </Avatar>
                                            </IconButton>
                                        </ GridMenuHiddenUp>
                                    </>
                                }
                                
                                {/** auth - NOT MOBILE*/}
                                {auth.isAuthenticated && 
                                    <>
                                        {/** OVER MD */}
                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/home' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/home")}}
                                            
                                        > 
                                            {t("HOME")}
                                        </GridMenuHiddenDown>

                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/transactions' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/transactions")}}
                                        > 
                                            {t("TRANSAZIONI")}
                                        </GridMenuHiddenDown>

                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/buy' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/buy")}}
                                        > 
                                            {t("ACQUISTA")}
                                        </GridMenuHiddenDown>

                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/withdraw' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/withdraw")}}
                                        > 
                                            {t("PRELEVA")}
                                        </GridMenuHiddenDown>

                                        { (isAssociation || isCompany) && 
                                            <>
                                                <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        fontWeight: window.location.pathname === '/redeem' ? 'bold' : undefined,
                                                        color:GREEN_MAIN
                                                    }}
                                                    onClick={()=>{navigate("/redeem")}}
                                                > 
                                                    {t("RISCATTA")}
                                                </GridMenuHiddenDown>
                                                
                                                <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        fontWeight: window.location.pathname === '/deposit' ? 'bold' : undefined,
                                                        color:GREEN_MAIN
                                                    }}
                                                    onClick={()=>{navigate("/deposit")}}
                                                > 
                                                    {t("DEPOSITA")}
                                                </GridMenuHiddenDown>
                                            </>
                                        }

                                        <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: window.location.pathname === '/profile' ? 'bold' : undefined,
                                                color:GREEN_MAIN
                                            }}
                                            onClick={()=>{navigate("/profile")}}
                                        > 
                                            {t("PROFILO")}
                                        </GridMenuHiddenDown>

                                        { (isAssociation || isAdmin) && 
                                            <GridMenuHiddenDown breackpoint='md' item margin={'0 5px'}
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontWeight: window.location.pathname === '/admin' ? 'bold' : undefined,
                                                    color:GREEN_MAIN
                                                }}
                                                onClick={()=>{navigate("/admin")}}
                                            > 
                                                {t("AMMINISTRAZIONE")}
                                            </GridMenuHiddenDown>
                                        }

                                        {/** UNDER MD */}
                                        <GridMenuHiddenUp breackpoint='md' item margin={'0 5px'}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        > 
                                            <IconButton onClick={() => {drawer.openOrCloseDrawer(
                                                    userDrawerUnloggedItems, 
                                                    drawerTitle!
                                                )}} >
                                                <Avatar sx={{ width:'40px', height:'40px', backgroundColor: WHITE, color: GREEN_MAIN, alignItems:'center' }}>
                                                    <AppsIcon sx={{fontSize:'35px'}} />
                                                </Avatar>
                                            </IconButton>
                                        </ GridMenuHiddenUp>
                                    </>   
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </HeaderContainer>
            ) : (
                <>
                {/** MOBILE */}
                    <HeaderContainer menuMobileOpen={menuMobileOpen} id="header">
                        <Box
                            sx={{
                                display:"flex",
                                justifyContent:'center',
                                alignItems:'center',
                                height:'65px'
                            }}
                        >
                            <Grid container spacing={1} flexGrow={1} marginTop={0} sx={{backgroundColor:WHITE}}>
                                <Grid item xs={4} sx={{
                                    flexGrow:1,
                                    display:'flex',
                                    justifyContent:'start',
                                    alignItems:'center',
                                    margin:'0px !important',
                                    padding:'0px 0px 0px 35px !important',
                                    backgroundColor:WHITE,
                                    minWidth:'80px'
                                }}>
                                    <Logo src={"/"+settings.Details.Logo} alt="Plurima Provider" isScrolled={isScrolled}/>
                                </Grid>
                                <Grid item xs={8} sx={{
                                    flexGrow:1,
                                    display:'flex',
                                    justifyContent:'end',
                                    alignItems:'center',
                                    margin:'0px !important',
                                    padding:'0px 25px !important',
                                    backgroundColor:WHITE
                                }}>
                                    <Grid container xs={12} spacing={0} justifyContent={'end'}> 
                                        <Grid item margin={'0 5px'}
                                            sx={{
                                                // width:'50px',
                                                display:'flex',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                padding:'0px !important',
                                            }}
                                        > 
                                            <IconButton onClick={() => {drawer.openOrCloseDrawer(
                                                    userDrawerUnloggedItems, 
                                                    drawerTitle!
                                                )}} >
                                                <Avatar sx={{ width:'40px', height:'40px', backgroundColor: WHITE, color: GREEN_MAIN, alignItems:'center' }}>
                                                    <AppsIcon sx={{fontSize:'35px'}} />
                                                </Avatar>
                                            </IconButton>
                                        </ Grid>
                                        
                                        <Grid item margin={'0 5px'} sx={{
                                                // width:'50px',
                                                display:'flex',
                                                justifyContent:'center',
                                                alignItems:'center',
                                                padding:'0px !important',
                                            }}>
                                            <IconButton
                                                edge="end"
                                                color="inherit"
                                                aria-label="menu"
                                                onClick={()=>toggleMenuMobile()}
                                                >
                                                    <Avatar sx={{ width:'40px', height:'40px', backgroundColor: WHITE, color: GREEN_MAIN, alignItems:'center' }}>
                                                        {menuMobileOpen ? <CloseIcon sx={{fontSize:'35px'}}/> : <MenuIcon sx={{fontSize:'35px'}}/>}
                                                    </Avatar>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {menuMobileOpen && 
                            <Box
                                sx={{
                                    height:'54px',
                                    borderTop: "1px solid "+BLUE_LIGHT_RGB_BORDER,
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                }}
                            >
                                <Grid container xs={12} justifyContent={'center'} sx={{
                                    backgroundColor:WHITE,
                                }}> 
                                    {!auth.isAuthenticated && 
                                        <GridLogin item margin={'0 5px'}>
                                            <RegisterButton />
                                        </GridLogin>
                                    }
                                    <GridLogin item margin={'0 5px'}>
                                        <LoginButton />
                                    </GridLogin>
                                    <GridLogin item margin={'0 5px'}>
                                        <DropDownLanguages />
                                    </GridLogin>
                                </Grid>
                            </Box>
                        }
                    </HeaderContainer>
                </>
            )} 
        </>
    )
}

export default Header