import React, { useState } from 'react';
import { Grid, Checkbox, FormControlLabel, Modal, Box, Typography, Button } from '@mui/material';
import { t } from 'i18next';
import { BLACK } from '../../theme/colors';

interface CheckboxProps {
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckBoxTermsAndConditions: React.FC<CheckboxProps> = ({isChecked, setIsChecked}) => {
  const [open, setOpen] = useState(false); 

  // Funzione per aprire e chiudere il modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Funzione per gestire il cambio di stato della checkbox
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label={
          <>
          <span
                style={{ 
                    color: BLACK,           // Colore nero per tutto il testo
                    fontSize: '0.8rem'        // Font size di 0.8rem per tutto il testo
                }}
            >
                {t("Confermo di aver letto e accettato tutte le clausole espresse in ")}
                <span
                    style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleOpen}
                >
                    {t("Termini e Condizioni")}
                </span>
            </span>
          </>
        }
      />

      {/* Modal per visualizzare i Termini e Condizioni */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="terms-and-conditions-title"
        aria-describedby="terms-and-conditions-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxWidth:'75%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="terms-and-conditions-title" variant="h6" component="h2">
          {t("Termini e Condizioni")}
          </Typography>
          <Typography id="terms-and-conditions-description" sx={{ mt: 2 }}>
            Qui inserisci i tuoi termini e condizioni... 
            Aggiungi tutto il testo che vuoi includere nei termini di servizio.
          </Typography>
          <Button onClick={handleClose} sx={{ mt: 2 }}>
            Chiudi
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};

export default CheckBoxTermsAndConditions;
