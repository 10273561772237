export const setToLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
};
  
export const getFromLocalStorage = (key: string): any | null => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};
  
export  const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};