import React, { useState, useEffect, useCallback, useRef } from 'react';
import {Box, Typography} from '@mui/material';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import { GoogleMap, LoadScript, useJsApiLoader, InfoWindow, Marker } from '@react-google-maps/api';
import settings from '../utilities/settings';
import { geoCircuitSearch } from '../hooks/actions/circuito';
import { MarketInfo } from '../models/circuito/MarketsGeo';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

const MarketsGeo = () => {
    const {t, getLanguage} = useTranslationContext();
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(null);
    const [bounds, setBounds] = useState<{ minLat: number; maxLat: number; minLng: number; maxLng: number } | null>(null);
    const [marketInfo, setMarketInfo] = useState<MarketInfo[]>([]);
    const [selectedMarket, setSelectedMarket] = useState<MarketInfo | null>(null);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: settings.Google.Maps.ApiKeyFE
    });

    useEffect(() => {
        const determineMapCenter = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setMapCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    const language = getLanguage();
                    let centerCoords: { lat: number; lng: number };

                    switch (language) {
                        case 'sl':
                            centerCoords = { lat: 46.0525213, lng: 14.506561 }; // Centra sulla Slovenia - Lubiana
                            break;
                        case 'en':
                            centerCoords = { lat: 51.4998627, lng: -0.1278278 }; // Centra sulla Gran Bretagna - Londra
                            break;
                        case 'de':
                            centerCoords = { lat: 52.5144617, lng: 13.3494617 }; // Centra sulla Germania - Berlino
                            break;
                        // Default per lingua 'it' o non riconosciuta
                        default:
                            centerCoords = { lat: 41.8719, lng: 12.5674 }; // Centra sull'Italia - Roma
                            break;
                    }

                    setMapCenter(centerCoords);
                }
            );
        };

        determineMapCenter();
    }, [getLanguage]);

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
        const bounds = map.getBounds();
        if (bounds) {
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            setBounds({
                minLat: sw.lat(),
                maxLat: ne.lat(),
                minLng: sw.lng(),
                maxLng: ne.lng(),
            });
        }
    }, []);

    const onIdle = useCallback(() => {
        if (map) {
            const bounds = map.getBounds();
            if (bounds) {
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                setBounds({
                    minLat: sw.lat(),
                    maxLat: ne.lat(),
                    minLng: sw.lng(),
                    maxLng: ne.lng(),
                });
            }
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            google.maps.event.addListener(map, 'idle', onIdle);
        }
    }, [map, onIdle]);

    useEffect(() => {
        if(bounds){
            geoCircuitSearch(bounds.minLat, bounds.minLng, bounds.maxLat, bounds.maxLng).then((resp)=> {
                const validMarkets = resp.map(market => ({
                    ...market,
                    Latitude: Number(market.latitude),
                    Longitude: Number(market.longitude)
                })).filter(market => !isNaN(market.Latitude) && !isNaN(market.Longitude));
                setMarketInfo(validMarkets);
            }).catch((error)=>console.log("ERROR: ", error));
        }
    }, [map, bounds]);

    useEffect(()=>{
        console.log("Market Info", marketInfo)
    },[marketInfo])
        
    const center = {
        lat: 41.8719,
        lng: 12.5674,
    };
    
    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    
                    <Typography variant='h3'>{t("IL CIRCUITO")}</Typography>

                    <Box sx={{height:'40px'}}/>
                        {isLoaded && <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter || center}
                            zoom={8}
                            onLoad={onLoad}
                        >
                            {marketInfo && marketInfo.map((market, index) => {
                                console.log("Market",market)
                                return (
                                    <Marker
                                        key={index}
                                        position={{ lat: Number(market.latitude), lng: Number(market.longitude) }}
                                        onMouseOver={() => setSelectedMarket(market)}
                                        // onMouseOut={() => setSelectedMarket(null)}
                                        onClick={() => setSelectedMarket(market)}
                                    />
                                )})}
                            
                            {selectedMarket && (
                                <InfoWindow
                                    position={{ lat: selectedMarket.latitude, lng: selectedMarket.longitude }}
                                    onCloseClick={() => setSelectedMarket(null)}
                                >
                                    <Box
                                        sx={{
                                            height:'150px',
                                            width:'200px'
                                        }}
                                    >
                                        <h4>{selectedMarket.marketName}</h4>
                                        <p>{selectedMarket.marketTypeActivity}</p>
                                        <p><a href={selectedMarket.marketWebSite} target="_blank" rel="noopener noreferrer">{selectedMarket.marketWebSite}</a></p>
                                        <p>{selectedMarket.marketTelephone}</p>
                                        <p>{selectedMarket.marketMail}</p>
                                        <p>{selectedMarket.description}</p>
                                    </Box>
                                </InfoWindow>
                            )}
                        </GoogleMap>}
                </Box>
            </Box>
        </>
    )
}

export default MarketsGeo;