import { TypographyVariantsOptions } from "@mui/material";
import '@fontsource/poppins';

export const roboto = [
	{
		fontFamily: "Roboto",
		fontWeight: "normal",
		src: `url(/fonts/Roboto/static/Roboto_Condensed-Regular.ttf)`,
	},
	{
		fontFamily: "Roboto",
		fontWeight: 600,
		src: `url(/fonts/Roboto/static/Roboto_Condensed-SemiBold.ttf)`,
	},
	{
		fontFamily: "Roboto",
		fontWeight: "bold",
		src: `url(/fonts/Roboto/static/Roboto_Condensed-Bold.ttf)`,
	},
	{
		fontFamily: "Roboto",
		fontWeight: 800,
		src: `url(/fonts/Roboto/static/Roboto_Condensed-ExtraBold.ttf)`,
	},
];

export const typography: TypographyVariantsOptions = {
	fontFamily:'Poppins',
	fontSize: 16,
	h1: {
		fontFamily:'Poppins',
		fontSize: 46,
		fontWeight: 800,
		lineHeight: 1.2,
		letterSpacing: "normal",
	},
	h2: {
		fontFamily:'Poppins',
		fontSize: 36,
		fontWeight: 800,
		lineHeight: 1.33,
		letterSpacing: "normal",
	},
	h3: {
		fontFamily:'Poppins',
		fontSize: 27,
		fontWeight: "bold",
		lineHeight: 1.19,
		letterSpacing: "normal",
	},
	h4: {
		fontFamily:'Poppins',
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: 1.23,
		letterSpacing: "normal",
	},
	h5: {
		fontFamily:'Poppins',
		fontSize: 18,
		fontWeight: "bold",
		lineHeight: 1.33,
		letterSpacing: "normal",
	},
	h6: {
		fontFamily:'Poppins',
		fontSize: 14,
		fontWeight: 600,
		lineHeight: 1.57,
		letterSpacing: "normal",
	},
	caption: {
		fontFamily:'Poppins',
		fontSize: 12,
		fontWeight: "normal",
		lineHeight: 1.33,
		letterSpacing: "normal",
	},
	body1: {
		fontFamily:'Poppins',
		fontSize: 20,
		fontWeight: "normal",
		lineHeight: 1.31,
		letterSpacing: "normal",
	},
	body2: {
		fontFamily:'Poppins',
		fontSize: 14,
		fontWeight: "normal",
		lineHeight: 1.29,
		letterSpacing: "normal",
	},
	subtitle1: {
		fontFamily:'Poppins',
		fontSize: 16,
		fontWeight: "bold",
		lineHeight: 1.38,
		letterSpacing: "normal",
	},
	subtitle2: {
		fontFamily:'Poppins',
		fontSize: 16,
		fontWeight: "bold",
		lineHeight: 1.38,
		letterSpacing: "normal",
	},
	overline: {
		fontFamily:'Poppins',
		fontSize: 18,
		fontWeight: "bold",
		lineHeight: "normal",
		letterSpacing: 0.5,
	},
	button: {
		fontFamily:'Poppins',
		fontSize: 14,
		fontWeight: "bold",
		lineHeight: "normal",
		letterSpacing: -0.1,
	},
};

export const fontWeightClasses = {
	"&.fontWeightBolder": {
		fontWeight: "800 !important",
	},
	"&.fontWeightBold": {
		fontWeight: "bold !important",
	},
	"&.fontWeightSemiBold": {
		fontWeight: "600 !important",
	},
	"&.fontWeightNormal": {
		fontWeight: "normal !important",
	},
};
