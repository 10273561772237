import { createTheme } from "@mui/material/";
import { breakpoints } from "./breakpoints";
import { palette, BLACK, BLUE_MAIN, GRAY_MAIN, WHITE, GRAY_LIGHT, BLUE_LIGHT, GREEN_LIGHT, BLUE_DARK, GREEN_MAIN, GREEN_LOGO } from "./colors";
import { spacing } from "./spacing";
import { fontWeightClasses, typography, roboto} from "./typography";

export const theme = createTheme({
	typography,
	palette,
	breakpoints,
	spacing,
	shape: {
		borderRadius: 2,
	},
	components:{
		// MuiCssBaseline: {
		// 	styleOverrides:{
		// 		"@global": {
		// 			"@font-face": poppins,
		// 		},
		// 	}
		// },
		MuiGrid:{
			styleOverrides:{
				root:{
					
				}
			}
		},
		MuiButton:{
			styleOverrides:{
				
				root:{
					height:'30px',
					width:'100%',
					"&:hover": { bgcolor: "darkgreen" },
					borderRadius:'15px',
					color: GREEN_LOGO,
					border:'2px solid green',
					fontSize:'0.9em',
					//padding:'0px',
				}
			}
		},
		MuiSelect:{
			styleOverrides:{
				root:{
					height:'30px',
					color: GREEN_MAIN,
					fontSize:'0.7em',
					'& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
						paddingTop: '6px',
						paddingLeft:'10px',
					},
					
					'& .MuiSelect-icon':{
						color: GREEN_MAIN
					},
					
					'& .MuiInputBase-root::before': {
						borderBottomColor: GREEN_MAIN
					},
					'& .MuiInputBase-root::after': {
						borderBottomColor: "darkgreen"
					},
				},
			}
		},
		MuiCard:{
			styleOverrides:{
				root:{
					minHeight:'50px',
					width:'100%',
					borderRadius:'5px',
					color: WHITE,
					backgroundColor: BLUE_MAIN,
					fontSize:'1em',
					display:'flex',
					flexDirection:'column',
					justifyContent:'center',
				}
			}
		},
		MuiIconButton:{
			styleOverrides:{
				root:{
					// bgcolor: GREEN_MAIN,
                    // "&:hover": { bgcolor: "darkgreen" },
					// color:WHITE,
					// border: "2px solid " + GREEN_MAIN
				}
			}
		},
		MuiBottomNavigationAction:{
			styleOverrides:{
				root:{
					color: GREEN_MAIN,
					"&.Mui-selected": {
						color: "darkgreen", // Colore del testo quando selezionato
					},
				}
			}
		}
		// MuiDialogTitle: {
		// 	styleOverrides:{
		// 		root:{
		// 			backgroundColor: 'blue',
		// 			padding: spacing(4),
		// 			[breakpoints.down("md")]: {
		// 				padding: spacing(3),
		// 			},
		// 			paddingBottom: 0,
		// 			fontSize: 27,
		// 			fontWeight: "normal",
		// 			textAlign: "center",
		// 		},
		// 	}
		// },
	},
});

