import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Grid, Box, IconButton, alpha, MenuItem } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { Snackbar, Alert } from '@mui/material';
import { EApprovedFromAssociation, PrivateUserInfoRequestModel, UserInfosModel } from '../../../models/user/UserInfosModel';
import { useTranslationContext } from '../../../hooks/translations/translationsProvider';
import { useWaiter } from '../../../hooks/waiter/WaiterProvider';
import lastDate18YearsAgo from '../../../utilities/lastDate18YearsAgo';
import { putMePrivate } from '../../../hooks/actions/users';
import TextFieldCustom from '../../TextField/TextFieldCustom';
import DatePickerCustom from '../../DatePicker/DatePickerCustom';
import SearchNations from '../../UserInfo/searchNation';
import { getAssociationStatusLabel } from '../../../helpers/approverFromAssociationHelper';
import { BLUE_LIGHT_RGB_BORDER, WHITE } from '../../../theme/colors';
import AlertSaveModal from '../../UserInfo/AlertSaveModal';
import { Close as CloseIcon } from '@mui/icons-material';
import SearchAssociations from '../../AssociationsList/searchAssociation';
import { putAdminUserPrivate, putAssociationUserPrivate} from '../../../hooks/actions/admin';
import { theme } from '../../../theme';
import AssociationApproval from '../Admin/Users/AssociationApproval';
import EnableUser from '../Admin/Users/EnableUser';

interface PrivateProps {
  user: UserInfosModel;
  onBack: () => void;
}

const AssociationPrivateUser: React.FC<PrivateProps> = ({user, onBack}) => {  
  const { t } = useTranslationContext();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<PrivateUserInfoRequestModel>({
    userid: user.userid || '', // ID dell'utente privato
    isPrivate: user.isPrivate,
    conventioned: user.conventioned,
    email: user.email,
    enabledUser: user.enabledUser === true,
    username: user.username,
    userType: user.userType,
    refCode: user.refCode || '',
    association: user.association || '',
    associationId: user.associationId || '',
    lastDateAssociationAction: user.lastDateAssociationAction || undefined,
    removedFromAssociation: user.removedFromAssociation || undefined,
    approvedFromAssociation: user.approvedFromAssociation || 0,

    // Dati specifici dell'utente privato (PrivateInfos)
    userInfoId: user.privateInfo?.id || '',
    nome: user.privateInfo?.nome || '',
    cognome: user.privateInfo?.cognome || '',
    born: user.privateInfo?.born || undefined,
    address: user.privateInfo?.address || '',
    comuneDiNascita: user.privateInfo?.comuneDiNascita || '',
    statoDiNascita: user.privateInfo?.statoDiNascita || '',
    city: user.privateInfo?.city || '',
    cap: user.privateInfo?.cap || 0,
    provincia: user.privateInfo?.provincia || '',
    nation: user.privateInfo?.nation || '',
    codiceFiscale: user.privateInfo?.codiceFiscale || ''
  });
  const [showPopup, setShowPopup] = useState(false);
  const {setWaiterView} = useWaiter();
  const [initialFormData, setInitialFormData] = useState({ ...formData }); // Salva i dati iniziali

  const [modalOpen, setModalOpen] = useState(false);
  const bornDate = initialFormData.born ? dayjs(initialFormData.born) : dayjs("01-01-1900")
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(bornDate); 
  const [bornError, setBornError] = useState(false);
  const [bornErrorMessage, setBornErrorMessage] = useState('');
  const [maxData] = useState<Dayjs | undefined>(dayjs(lastDate18YearsAgo()));

  const [mainError, setMainError] = useState<boolean>(false);
  const [mainErrorMex, setMainErrorMex] = useState<string>("");

  const handleChange = (value: string, key: string) => {
    if(key === 'enabledUser'){
      const boolVal = value === 'false' ? false : true;
      setFormData((prev:any) => ({
        ...prev,
        [key]: boolVal, // Directly update with the string value
      }));
    }else{
      setFormData((prev:any) => ({
        ...prev,
        [key]: value, // Directly update with the string value
      }));
    }
  };

  useEffect(()=>{
    console.log("FormData", formData)
  },[formData])

  const handleEdit = () => {
    setMainError(false); // cancello eventuali errori
    setMainErrorMex("");

    setIsEditing(true);
  };

  const handleSave = () => {
    setMainError(false); // cancello eventuali errori
    setMainErrorMex("");

    handleCloseModal(); // chiudo il modal

    if(JSON.stringify(formData) === JSON.stringify(initialFormData)){ // verifico che i dati non siano gli stessi
      setIsEditing(false);

      setShowPopup(true); // apertura e chiusura del popup
      setTimeout(() => {
        setShowPopup(false);
      }, 10000);

      return;
    }
    setWaiterView(true); // avvio il waiter

    putAssociationUserPrivate(formData).then(resp => {
      setFormData(resp);
      setInitialFormData(resp);
      setWaiterView(false); // termina il waiter
      
      setShowPopup(true); // apertura e chiusura del popup
      setTimeout(() => {
        setShowPopup(false);
      }, 10000);
      
      setIsEditing(false); // disabilito la modifica

    }).catch(error => {
      debugger;
      setMainError(true);
      const errorMex =  error?.response?.data?.message ||  
                        error?.response?.data?.title || 
                        error?.response?.data || 
                        error?.response || 
                        error?.message?.response?.data || 
                        error?.message?.response || 
                        error?.message || 
                        t("Errore durante la modifica dei dati")
      setMainErrorMex(errorMex);
      setIsEditing(true); // disabilito la modifica
    });
    setWaiterView(false); // termina il waiter
  };

  const handleCancel = () => {
    setMainError(false); // cancello eventuali errori
    setMainErrorMex("");

    setFormData(initialFormData); // Reset dei dati al valore iniziale
    setIsEditing(false);
  };

  // Conditionally render the component output
  if (!user.userid) {
    return <div>{t("Utente privato non disponibile.")}</div>;
  }

  const handleChangeDate = (date: Dayjs | null) => {
    setBornErrorMessage('')
    if(date !== null && date.isValid()){ // se la data è valida mi setti false, ma se per caso dovesse essere meno di 18 anni fai true all'errore
        setSelectedDate(date)
        setBornError(false)

        if(maxData !== undefined && date > maxData){
            setBornErrorMessage(t("Devi avere più di 18 anni per iscriverti"))
            setBornError(true)
        } 
        setFormData((prev:any) => ({
          ...prev,
          ['born']: date.toDate(), // Directly update with the string value
        }));
    } 
    if (date !== null && !date.isValid()){
        setBornErrorMessage(t("Data non valida"))
        setBornError(true)
    }
  };

  const handleChangeAssociation = (association: string, associationId: string) => {
    setFormData((prev:any) => ({
      ...prev,
      ['association']: association,      // Aggiorna il campo 'association' con il nuovo valore
      ['associationId']: associationId,    // Aggiorna il campo 'associationId' con il nuovo valore
    }));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div>
        <Box
          sx={{
            display:'flex',
            justifyContent:'flex-end'
          }}
        >
          <IconButton onClick={() => {
            handleCancel(); 
            setIsEditing(false); 
            onBack(); 
          }} 
             sx={{
              backgroundColor: 'white', // Sfondo bianco
              color: 'grey', // Colore dell'icona rosso
              '&:hover': {
                backgroundColor: 'rgba(128,128,128, 0.1)', // Sfondo rosso chiaro al passaggio del mouse
              },
              borderRadius: '50%', // Per renderlo rotondo
              padding: 1, // Margine interno
            }}
          >
            <CloseIcon sx={{
                fontSize:"40px",
                '&:hover': {
                  fontWeight:'bold'
                },
              }}
            />
          </IconButton>
        </Box>

        {!isEditing ? (
          <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleEdit} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Modifica")}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button variant="outlined" onClick={handleCancel} sx={{
                margin:"10px 0 0 0",
                height: '35px'
              }}>
                {t("Annulla")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleOpenModal} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Salva")}
              </Button>
            </Grid>
          </Grid>
        )}

        {mainError && 
          <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
              {mainErrorMex}
            </Typography>
          </>
        }

        <h5>{t("Utente Privato")}</h5>
        <form>
          
          <TextFieldCustom
            label={t("Nome")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.nome || ''}
            errorText={t("Richieste lettere e spazi")}
            onChange={(value) => handleChange(value, 'nome')}
            disable={true}
            required={false}
          />
          <TextFieldCustom
            label={t("Cognome")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.cognome || ''}
            errorText={t("Richieste lettere e spazi")}
            onChange={(value) => handleChange(value, 'cognome')}
            disable={true}
            required={false}
          />
          <DatePickerCustom
            label={t("Data di Nascita")}
            selectedDate={selectedDate}
            handleChangeDate={handleChangeDate}
            maxData={maxData}
            error={bornError}
            errorMessage={bornErrorMessage}
            disable={true}
          />

          {!isEditing ?
            (
              <Typography sx={{
                padding:'16px',
                color: 'GrayText',
                fontSize:'0.9rem'
              }}>
                {t(getAssociationStatusLabel(formData.approvedFromAssociation)) }
              </Typography>
          ):(
            <AssociationApproval 
              approvedFromAssociation={ formData.approvedFromAssociation ? formData.approvedFromAssociation : 0 }
              approvedFromAssociationReset={ initialFormData.approvedFromAssociation ? initialFormData.approvedFromAssociation : 0 }
              handleChange={handleChange}
            />
          )}

          {/** ABILITA e DISABILITA */}
          {!isEditing ? (
              <Typography sx={{
                padding:'16px',
                color: 'GrayText',
                fontSize:'0.9rem'
              }}>
                {formData.enabledUser ? t("Utente Abilitato") : t("Utente Disabilitato")}
              </Typography>
            ) : (
              <EnableUser 
                approved={formData.enabledUser}
                resetValue={initialFormData.enabledUser}
                handleChange={handleChange}
              />
            )
          }

          <TextFieldCustom
            label={t("Comune di nascita")}
            regex={/^(?=.*[A-Za-z])[A-Za-z -]+$/}
            value={formData.comuneDiNascita || ''}
            onChange={(value) => handleChange(value, 'comuneDiNascita')}
            required={false}
            errorText={t("Richieste lettere e spazi")}
            disable={true}
          />
          <SearchNations 
            label={t("Stato di nascita")}
            nazione={formData.statoDiNascita || ''} 
            setNazione={(value) => handleChange(value, 'statoDiNascita')} 
            disable={true}
          />
          <TextFieldCustom
            label={t("Indirizzo")}
            regex={/^[A-Za-z0-9 ,\/-]*$/}
            value={formData.address || ''}
            onChange={(value) => handleChange(value, 'address')}
            required={false}
            errorText={t("Richieste solo lettere, spazi, numeri, virgola e meno e /")}
            disable={true}
          />
          <TextFieldCustom
            label={t("Città")}
            regex={/^(?=.*[A-Za-z])[A-Za-z -]+$/}
            value={formData.city || ''}
            onChange={(value) => handleChange(value, 'city')}
            required={false}
            errorText={t("Richieste lettere e spazi")}
            disable={true}
          />
          <TextFieldCustom
            label={t("CAP")}
            regex={/^[0-9]+$/}
            value={formData.cap?.toString() || '' }
            onChange={(value) => handleChange(value, 'cap')}
            required={false}
            errorText={t("Richiesti solo Numeri")}
            disable={true}
          />
          <TextFieldCustom
            label={t("Provincia")}
            regex={/^[A-Za-z]+$/}
            value={formData.provincia || '' }
            onChange={(value) => handleChange(value, 'provincia')}
            required={false}
            errorText={t("Richieste solo Lettere")}
            disable={true}
          />

          <SearchNations 
            label={t("Nazione")}
            nazione={formData.nation || ''} 
            setNazione={(value) => handleChange(value, 'nation')} 
            disable={true}
            required={false}
          />

          <TextFieldCustom
            label={t("Codice Fiscale")}
            regex={/^[A-Za-z0-9]*$/}
            value={formData.codiceFiscale || '' }
            onChange={(value) => handleChange(value, 'codiceFiscale')}
            required={false}
            errorText={t("Richiesti lettere e numeri")}
            disable={true}
          />

          <TextFieldCustom
            label={t("Email")}
            regex={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
            value={formData.email || '' }
            //onChange={(value) => handleChange(value, 'mail')}
            errorText={t("richiesta mail")}
            // disable={!isEditing}
          />
          
        
          <TextFieldCustom
            label={t("Associazione di appartenenza")}
            value={formData.association || '' }
            errorText={t("")}
          />
        </form>

        {!isEditing ? (
          <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleEdit} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Modifica")}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button variant="outlined" onClick={handleCancel} sx={{
                margin:"10px 0 0 0",
                height: '35px'
              }}>
                {t("Annulla")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleOpenModal} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Salva")}
              </Button>
            </Grid>
          </Grid>
        )}


        {mainError && <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
            {mainErrorMex}
          </Typography>
        </>
        }
      </div>

      <AlertSaveModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
      />

      {/* Popup che appare dopo il salvataggio */}
      <Snackbar open={showPopup} anchorOrigin={{vertical: 'bottom', horizontal: "right" }}>
        <Alert severity="success"
          sx={{ 
            border: '2px solid green',
            marginBottom:'30px'
          }}
          onClose={() => setShowPopup(false)}>
          {t("Salvataggio dati avvenuto con successo")}
        </Alert>
      </Snackbar>
    </>
  );
};


export default AssociationPrivateUser;
