import { Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useAuth } from 'react-oidc-context';
import {Box, Grid, Button} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResponsiveLayout } from '../../components/ResponsiveLayout';
import { isMobile } from 'react-device-detect';
import {GREEN_LOGO, GREEN_MAIN, WHITE } from '../../theme/colors';
import settings from '../../utilities/settings';
import { getFromLocalStorage } from '../../helpers/localStorage';

const Circuito = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [url, setUrl]= useState<string>('');
    const location = useLocation();
    const ReturnUrl = settings.Domains.ServiceClient + "/company/register/callback"
    let referral: string | undefined = getFromLocalStorage("Referral");

    useEffect(()=>{
        if(auth.isAuthenticated)
            navigate('/home')
    },[auth.isAuthenticated])

    useEffect(()=>{
        setUrl(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`)
    },[location])

    const ImageContent = () => (
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: 2,
          }}
          alt="ASSO circuit board with tree"
          src="/circuito.jpg" 
        />
      )
    
    const TextContent = () => {
        const titleSize = isMobile ? "1.4rem" : "1.3rem"
        const bodySize = isMobile ? "1rem" : "1.1rem"
        const buttonSpacing = isMobile ? 1 : 2
    
        return (
          <Box
            sx={{
              color: "darkgreen",
            //   p: isMobile ? 2 : 4,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            {/* Main title */}
            {/* <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontSize: titleSize,
                // fontWeight: 500,
                mb: 3,
                fontWeight: "bold",
              }}
            >
              Benvenuti su ASSO, il primo circuito economico:
            </Typography> */}
    
            
    
            {/* First paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              Sono migliaia le attività economiche che fanno parte del circuito internazionale ASSO e ogni giorno se ne aggiungono di nuove!
            </Typography>
    
            {/* Pre-Bullet paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              All'interno della piattaforma trovi:
            </Typography>

            {/* Bullet points */}
            <Box sx={{ my: 1, ml: 1 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  mb: 2
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                    ▸
                  </Box>
                  <Box component="span" textAlign="left">
                    l'<strong>elenco completo </strong>di tutte le aziende registrate sul circuito
                  </Box>
                </Box>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  textAlign:"left",
                  mb: 1,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                <Box component="span" textAlign="left">
                  un <strong>motore di ricerca </strong>interno per aiutarti a trovare rapidamente quello che cerchi
                </Box>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: bodySize,
                  display: "flex",
                  textAlign:"left",
                  mb: 1,
                }}
              >
                <Box component="span" sx={{ mr: 1, color: "darkgreen" }}>
                  ▸
                </Box>
                <Box component="span" textAlign="left">
                  una <strong>mappa geolocalizzata </strong>che ti mostra tutte le attività economiche della tua zona registrate sul circuito
                </Box>
              </Typography>
            </Box>

            {/* Second paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              Il circuito ASSO è una grande opportunità, sia per gli utenti (che trovano centinaia di fornitori locali e ottimi prodotti) siaper le attività economiche (che possono entrare in un nuovo mercato ed acquisire nuovi clienti).
            </Typography>
    
            {/* Third paragraph */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: bodySize,
                mb: 2,
                textAlign:"justify"
              }}
            >
              Ah... la partecipazione al circuito ASSO è gratuita!
            </Typography>
    
            {/* Participation section */}
            <Box sx={{ mt: isMobile ? 2 : 1 }}>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  my: 3,
                  fontSize: isMobile ? "1.4rem" : "1.3rem",
                  fontWeight: 500,
                }}
              >
                Partecipa come
              </Typography>
    
              {/* Buttons - responsive grid */}
              <Grid
                container
                spacing={buttonSpacing}
                justifyContent="center"
                direction={isMobile ? "column" : "row"}
                alignItems="center"
              >
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                      navigate(`/private/register`)
                    }}
                  >
                    PRIVATO
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                      navigate('/economics-activities/register')
                    }}>
                    ATTIVITA' ECONOMICA
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    sx={{
                      bgcolor: GREEN_LOGO,
                      "&:hover": { bgcolor: "darkgreen" },
                      borderRadius: 10,
                      px: 3,
                      py: 1,
                      fontSize: isMobile ? "0.9rem" : "0.8rem",
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: isMobile ? "250px" : "120px",
                      color:WHITE,
                      border: "2px solid " + GREEN_LOGO,
                    }}
                    onClick={()=>{
                      navigate('/associations/register');
                    }}
                  >
                    ASSOCIAZIONE
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      }

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <ResponsiveLayout imageContent={<ImageContent/>} textContent={<TextContent/>} />
                </Box>
            </Box>
        </>
    )
}

export default Circuito;