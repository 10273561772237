import { DashboardModel } from "../../models/dashboard/Dashboard";
import { TransactionsResponse } from "../../models/dashboard/Transactions";
import httpClient from "../../utilities/http-client";

export const dashboard = async () => {
	try {
		const { data } = await httpClient.get<DashboardModel>(`/dashboard`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const getTransactions = async (page: number, rowsPerPage: number) => {
	try {
		const from = page * rowsPerPage;
		const count = rowsPerPage;
		const { data } = await httpClient.get<TransactionsResponse>(`/dashboard/transactions/${from}/${count}`);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const getTransactionsDataFilter = async (page: number, rowsPerPage: number, dataFrom: Date, dataTo: Date) => {
	try {
		const dataFromString = dataFrom.toISOString().substring(0, 16); // yyyy-MM-dd hh:mm
    	const dataToString = dataTo.toISOString().substring(0, 16); // yyyy-MM-dd

		const from = page * rowsPerPage;
		const count = rowsPerPage;
		const { data } = await httpClient.get<TransactionsResponse>(`/dashboard/transactions/${from}/${count}/${dataFromString}/${dataToString}`);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const getPdfTransactions = async (dataFrom: Date, dataTo: Date) => {
	try {
		const dataFromString = dataFrom.toISOString().substring(0, 10); // yyyy-MM-dd hh:mm = 16
    	const dataToString = dataTo.toISOString().substring(0, 16); // yyyy-MM-dd = 10
		const response = await httpClient.get(`/dashboard/transactions/pdf/${dataFromString}/${dataToString}`, {
			responseType: 'blob'
		});
		const blob = response.data;
        
        const url = window.URL.createObjectURL(blob);

		// codice che fa il download automatico
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'buy-transactions.pdf'; 
        // document.body.appendChild(a);
        // a.click(); 
        // document.body.removeChild(a); 
		
		//codice che apre nel browser la finestra pdf
		window.open(url, '_blank');

		setTimeout(() => window.URL.revokeObjectURL(url), 20000);
	} catch (e:any) {
		throw e;
	}
};

export const getPdfCredits = async () => {
	try {
		const response = await httpClient.get(`/dashboard/credits/pdf`, {
			responseType: 'blob'
		});
		const blob = response.data;
        
        const url = window.URL.createObjectURL(blob);

		// codice che fa il download automatico
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'buy-transactions.pdf'; 
        // document.body.appendChild(a);
        // a.click(); 
        // document.body.removeChild(a); 
		
		//codice che apre nel browser la finestra pdf
		window.open(url, '_blank');

		setTimeout(() => window.URL.revokeObjectURL(url), 20000);
	} catch (e:any) {
		throw e;
	}
};
