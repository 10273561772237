import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface AssociationApprovalsProps {
    approved: boolean; // Valore iniziale passato come prop (true/false)
    resetValue: boolean; // Valore da usare per il reset
    handleChange: (value: string, key: string) => void; // Funzione per aggiornare lo stato
}

const EnableUser: React.FC<AssociationApprovalsProps> = ({ approved, resetValue, handleChange }) => { 
    const { t } = useTranslation();

    const [selectedValue, setSelectedValue] = React.useState<boolean>(approved);

    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const boolVal: boolean = value === 'true' ? true : false;  
        setSelectedValue(boolVal);
        handleChange(value, 'enabledUser'); 
    };
    
    const handleReset = () => {
        const boolVal: string = resetValue ? 'true' : 'false'; 
        setSelectedValue(resetValue);
        handleChange(boolVal, 'enabledUser');
    };

    return (
        <Box 
            sx={{display:'flex'}}
        >
            <TextField
                select
                //label={t('Select approval status')} // Puoi usare la traduzione se necessario
                value={selectedValue.toString()} // Assicuriamoci che il valore sia una stringa
                onChange={handleSelectChange}
                sx={{
                    width: '100%',
                }}
                InputProps={{
                    style: {
                        marginTop: '16px',
                        marginBottom: '8px',
                    },
                }}
            >
                <MenuItem value="true">{t("Enable")}</MenuItem>
                <MenuItem value="false">{t("Disable")}</MenuItem>
            </TextField>
    
            {/* Bottone per resettare il valore */}
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <IconButton onClick={handleReset} sx={{ 
                    marginTop: '8px',
                    marginLeft:'8px',
                    width:'50px', 
                    height:'50px'
                 }}>
                    <ClearIcon />
                </IconButton>
            </Box>
        </Box>
      );
  }

  export default EnableUser;