import React, {ReactNode, useEffect, useState} from 'react';
import { AppContainer, 
  ContainerCustom, 
  FooterContainer,
  WaiterContainer,
  Logo,
  FooterLink,
  FooterText} from './template'
import { Grid, Typography, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import settings from '../utilities/settings';
import { BLACK, WHITE } from '../theme/colors';
import Header from '../components/Header';
import DrawerComponent from '../components/DrawerComponent';
import useHeaderHeight from '../hooks/UseHeaderHeight';
import UseFooterHeight from '../hooks/UseFooterHeight';
import CustomWaiter from '../components/Waiter';
import { useWaiter } from '../hooks/waiter/WaiterProvider';
import FooterNavigation from '../components/Footer/FooterNavigation';
import { useNavigate } from 'react-router-dom';
import { useTranslationContext } from '../hooks/translations/translationsProvider';

interface LayoutProps{
  children: ReactNode
}
const Layout: React.FC<LayoutProps> = ({children}) =>{
  const headerHeight = useHeaderHeight();
  const footerHeight = UseFooterHeight();
  const [headerH, setHeaderH] = useState(0)
  const [footerH, setFooterH] = useState(0)
  const {waiterView} = useWaiter();
  const navigate = useNavigate();
  const {t} = useTranslationContext();

  useEffect(()=>{
    setHeaderH(headerHeight + 40)
  },[headerHeight])

  useEffect(()=>{
    setFooterH(footerHeight + 30)
  },[footerHeight])

  return (
    <>
      <AppContainer>
        <Header/>

        <ContainerCustom headerH={headerH} footerH={footerH}
          maxWidth='lg'
          id='container-custom'
        >
          {children}
          {
            waiterView && <WaiterContainer>
              <CustomWaiter width={100} size={80}/>
            </WaiterContainer>
          }
        </ContainerCustom>
        
        { !isMobile ? (
            <FooterContainer id="footer">
              {/** Per dispositivi NON mobili */}
              <Grid container spacing={2} justifyContent="space-between" sx={{ maxWidth: "lg", padding:'5px 0' }}>
                {/* Logo Section */}
                <Grid item xs={12} sm={2} sx={{ 
                  textAlign: { xs: "center", sm: "left" }, 
                  justifyItems:"center",
                  alignContent:"center"
                  // marginTop: { xs: '5px', sm: 0 }
                }}>
                  <Box>
                    <Logo src={"/Logo_bianco.png"} 
                        alt="Plutima Provider" 
                        onClick={()=>{navigate("/")}} 
                        sx={{cursor: 'pointer'}}
                    />
                  </Box>
                </Grid>

                {/* Company Info Section */}
                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: "center", sm: "left" } }}>
                  <Box>
                    <FooterText><strong>{settings.Details.Owner.Name}</strong>, {settings.Details.Owner.Address}</FooterText>
                    <FooterText>VAT {settings.Details.Owner.VAT}</FooterText>
                    <FooterLink href={"mailto:"+settings.Details.Owner.Email}>{settings.Details.Owner.Email}</FooterLink>
                  </Box>
                </Grid>

                {/* Legal Section */}
                <Grid item xs={12} sm={2} sx={{ textAlign: { xs: "center", sm: "left" } }}>
                  <Box>
                    <FooterLink href="#">{t("Accordi legali")}</FooterLink>
                    <FooterLink href="#">{t("Termini e condizioni di utilizzo")}</FooterLink>
                    <FooterLink href="#">{t("Tariffe")}</FooterLink>
                  </Box>
                </Grid>

                {/* Conventions and Privacy Section */}
                <Grid item xs={12} sm={2} sx={{ textAlign: { xs: "center", sm: "left" } }}>
                  <Box>
                    <FooterLink href="#">{t("Convenzione Attività economiche")}</FooterLink>
                    <FooterLink href="#">{t("Convenzione Associazioni")}</FooterLink>
                    <FooterLink href="#">FAQ</FooterLink>
                  </Box>
                </Grid>

                {/* Conventions and Privacy Section */}
                <Grid item xs={12} sm={2} sx={{ 
                  textAlign: { xs: "center", sm: "left" }, 
                  // marginBottom: { xs: '5px', sm: 0 }
                }}>
                  <Box>
                    <FooterLink href="#">Privacy Policy</FooterLink>
                    <FooterLink href="#">Cookie</FooterLink>
                  </Box>
                </Grid>



                {/* <Grid xs={12} item sx={{
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems: 'center'
                }}>
                  <Typography sx={{
                    fontSize:'0.70rem',
                    fontWeight:'bold',
                    color: BLACK
                  }}>{settings.Details.Owner.Name} &reg;</Typography>
                  <Typography sx={{
                    fontSize:'0.68rem',
                    color: BLACK
                  }}>{settings.Details.Owner.Address}</Typography>
                  <Typography sx={{
                    fontSize:'0.68rem',
                    color: BLACK
                  }}>{settings.Details.Owner.VAT}</Typography>
                </Grid> */}
              </Grid>
            </FooterContainer>
          ) : (
            <FooterContainer id="footer">
              {/** Per dispositivi mobili */}
              <FooterNavigation /> 
            </FooterContainer>
          )
        }
      </AppContainer>
      <DrawerComponent />
    </>
  );
}

export default Layout;
