import { useEffect, useState, useRef } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { DoneAll } from "@mui/icons-material";
import { useWaiter } from "../../hooks/waiter/WaiterProvider";
import { delay } from "../../utilities/delay";
import { withdrawConfirm, withdrawReject } from "../../hooks/actions/withdraw";
import { IWithdrawConfirmModel } from "../../models/withdraw";
import { GREEN_MAIN } from "../../theme/colors";

export default function AcceptRejectWithdraw () {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url
    const initialized = useRef(false)
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState("black");

    const code = searchParams.get("code") as string;
    const userId = searchParams.get("userId") as string;
    const { action } = useParams();

    const [icoColor, setIcoColor] = useState<string | undefined>(undefined)
    const {setWaiterView} = useWaiter();
    
    useEffect(()=>{
        if(action && action === "confirm") setIcoColor(GREEN_MAIN)
        if(action && action === "reject") setIcoColor("orange")
    },[])

    useEffect(()=>{
        if (!initialized.current) { // evitare doppie chiamate
            initialized.current = true
            setWaiterView(true)
            delay(2000).then(()=>{
                if(userId === null || code === null)
                {
                    setMessage("Richiesta di validazione errata. Riprova.")
                    setMessageColor("red")
                    setWaiterView(false)
                }
                else
                {
                    if(action === undefined){
                        setMessage("Non hai definito se vuoi confermare o rifiutare il prelievo. Riprova!")
                        setMessageColor('red')
                        setWaiterView(false)
                    }
                    else if (action === "confirm" || action === "reject"){
                        handleAcceptReject().finally();
                    }else {
                        setMessage("Non hai definito se vuoi confermare o rifiutare il prelievo. Riprova!")
                        setMessageColor('red')
                        setWaiterView(false)
                    }
                }
            })
        }   
    },[action])

    const handleAcceptReject = async () =>{
        try{
            if (action === undefined) {
                setMessage("Non hai definito se vuoi confermare o rifiutare il prelievo. Riprova!")
                setMessageColor('red')
                setWaiterView(false)
                return;
            }
            
            const withModel:IWithdrawConfirmModel = {
                userId: userId,
                code: code
            }

            switch(action){
                case "confirm": 
                    {   
                        const data = await withdrawConfirm(withModel);
                        if(data){
                            setMessage("Richiesta di prelievo confermata con successo!")
                            setMessageColor('green')
                            setWaiterView(false)
                        }

                    }break
                case "reject": 
                    {
                        const data = await withdrawReject(withModel);
                        if(data){
                            setMessage("Richiesta di prelievo rifiutata dall'utente!")
                            setMessageColor('orange')
                            setWaiterView(false)
                        }
                    }break
            }
            
        }catch(e:any){
            const err = e;
            console.log("error", e)
            setMessage(err.data)
            setMessageColor('red')
            setWaiterView(false)
            setIcoColor("red")
        }
    }


    return (
        <Container 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent:'center',
            }}
        >
            
            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: icoColor , color: 'white', alignItems:'center', marginTop: '50px'  }}>
                <DoneAll sx={{ fontSize: 50 }}/>
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                {
                    action && action === "confirm" ? t("Conferma Prelievo ASSO...") : action && action === "reject" ? t("Prelievo ASSO Rifiutato...") : t("Prelievo ASSO...")
                }
            </Typography>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography color={messageColor} variant="h4">
                    {t(message)}
                </Typography>
            </Box>
            
        </Container>
    )
}

