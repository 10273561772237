
import { Box, styled, TableCell, TextField, Typography } from "@mui/material";
import TextFieldCustom from "../../TextField/TextFieldCustom";
import { useTranslationContext } from "../../../hooks/translations/translationsProvider";

const TabsCellCustom = styled(TableCell)(({theme})=>({
    fontSize:'0.7rem',                                      
    [theme.breakpoints.up('xs')]: {
    },
  }))

interface TableCellEditableProps {
    editRowIndex: number | null;
    index: number;
    value: any;
    onChange: (newVale:any) => void;
    regex: RegExp | undefined;
    errorText?: string | undefined;
    otherText?:string | undefined;
    onClickReset?: boolean | undefined
    parsedDecimals?: number | undefined
    disabled?: boolean | undefined
}

const TableCellEditable: React.FC<TableCellEditableProps> = ({editRowIndex, index, value, onChange, regex, errorText, otherText, onClickReset = false, parsedDecimals, disabled = false}) => {
    const {t} = useTranslationContext();

    const handleBlur = () => {
        if (parsedDecimals) {
          const formattedValue = parseFloat(value).toFixed(parsedDecimals);
          onChange(formattedValue);
        }
      };

    const valueParsed = (value: any) => {
        if(parsedDecimals){
            return parseFloat(value).toFixed(parsedDecimals);
        }
        return value;
    }

    return(
        <TabsCellCustom>
            {editRowIndex === index && !disabled ? (
                <Box sx={{display:'flex'}}>
                    <TextFieldCustom
                        label={''}
                        regex={regex}
                        value={value}
                        errorText={t(errorText  ?? "")}
                        onChange={(value) => onChange(value)}
                        disable={disabled}
                        props={{
                            sx:{
                                margin:0,
                            },
                            InputProps:{
                                style: {
                                    height: '35px',
                                    fontSize:'0.8rem',
                                    minWidth: '150px'
                                },
                            },
                            onClick: onClickReset ? () => onChange('') : () => {},
                            onBlur: handleBlur
                        }}
                    /> 
                    
                    {otherText && <Box sx={{fontSize:"0.7rem", paddingLeft:'5px', display:'flex', alignItems:'center'}}>{otherText}</Box>}
                </Box>
            ) : (
                otherText ? valueParsed(value) + " "+ otherText : valueParsed(value)
            )}
        </TabsCellCustom>
    );
}

export default TableCellEditable;