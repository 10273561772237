import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTranslationContext } from "../../../../hooks/translations/translationsProvider";
import { CompanyAssociationInformations, PrivateUserInformations, UserInfosModel } from "../../../../models/user/UserInfosModel";
import PrivateUsersTable from "../Users/PrivateUserTable";
import CompanyAssociationUsersTable from "../Users/CompanyAssociationUsersTable";
import AdminBalancesUser from "./AdminBalancesUser";

const UserBalances: React.FC = () => {
    const {t} = useTranslationContext();
    const [selectedPrivateUser, setSelectedPrivateUser] = useState<UserInfosModel | null>(null);
    const [selectedCompanyUser, setSelectedCompanyUser] = useState<UserInfosModel | null>(null);

    function formatLocationPrivate(privateInfo:PrivateUserInformations | undefined) {
        if (!privateInfo) return {
            address: "",
            name: ""
        };
        const { city, provincia, nation, nome, cognome } = privateInfo;
        let _address = "";
        let _name = "";
        if (city || provincia || nation) {
            _address =  [city, provincia, nation]
                .filter(Boolean) // Filtra i valori null o undefined
                .join(" - "); // Unisce i valori rimanenti con " - "
        }
        if (nome  || cognome){
            _name = [nome, cognome]
                .filter(Boolean) // Filtra i valori null o undefined
                .join(" ");
        }
        return {
            address: _address,
            name: _name
        };
    }

    function formatLocationCompany(companyInfo:CompanyAssociationInformations | undefined) {
        if (!companyInfo) return {
            address: "",
            name: ""
        };
        const { citySedeLegale, provinciaSedeLegale, nationSedeLegale, nomeAziendaAssociazione, nome, cognome } = companyInfo;

        let _address = "";
        let _name = "";
        if (citySedeLegale || provinciaSedeLegale || nationSedeLegale) {
            _address =  [citySedeLegale, provinciaSedeLegale, nationSedeLegale]
                .filter(Boolean) // Filtra i valori null o undefined
                .join(" - "); // Unisce i valori rimanenti con " - "
        }
        
        if (nomeAziendaAssociazione) {
            //_name = `${t("Azienda o Associazione")}: ${nomeAziendaAssociazione}`;
            _name = `${nomeAziendaAssociazione}`;
        }
    
        if (nome || cognome) {
            const adminInfo = [nome, cognome]
                .filter(Boolean) // Filtra i valori null o undefined
                .join(" "); // Unisce i valori rimanenti con " - "
                _name += _name 
                        ? ` - ${t('Amministratore')}: ${adminInfo}` 
                        : `${t('Amministratore')}: ${adminInfo}`;
        }

        return {
            address: _address,
            name: _name
        };
    }

    return (
        <Box >
            {!selectedPrivateUser && !selectedCompanyUser ? (
                <>
                    <Typography variant="h3" gutterBottom>
                        {t("Gestione Finanziaria Utenti")}
                    </Typography>

                    <Divider sx={{ mb: 3, mt:3 }} />

                    <Typography variant="h5" gutterBottom>
                        {t("Utenti Privati")}
                    </Typography>
                    <PrivateUsersTable onUserSelect={setSelectedPrivateUser}/>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h5" gutterBottom>
                        {t("Aziende o Associazioni")}
                    </Typography>
                    <CompanyAssociationUsersTable onUserSelect={setSelectedCompanyUser}/>
                </>
            ): selectedPrivateUser && selectedPrivateUser.userid ? (
                
                <AdminBalancesUser 
                    userId={selectedPrivateUser.userid}
                    userAddress={ formatLocationPrivate(selectedPrivateUser.privateInfo).address }
                    userName={ formatLocationPrivate(selectedPrivateUser.privateInfo).name }
                    isPrivate={true}
                    onBack={() => setSelectedPrivateUser(null)}
                />
            ): selectedCompanyUser && selectedCompanyUser.userid ? (
                <AdminBalancesUser 
                    userId={selectedCompanyUser.userid}
                    userAddress={ formatLocationCompany(selectedCompanyUser.companyAssociationInfo).address }
                    userName={  formatLocationCompany(selectedCompanyUser.companyAssociationInfo).name }
                    isPrivate={false}
                    onBack={() => setSelectedCompanyUser(null)}
                />
            ) : null
            }
        </Box>
    );
};

export default UserBalances;
