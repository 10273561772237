import React, { useState, ReactNode } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MyAccordionProps {
  children: ReactNode;
  summayTxt: string;
}

const AccordionCustom: React.FC<MyAccordionProps> = ({ children, summayTxt }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded && !isLoaded) {
      setIsLoaded(true); // Carica i children solo al primo click
    }
  };

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{summayTxt}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {isLoaded && children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCustom;
