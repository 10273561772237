import { useAuth } from "react-oidc-context";
import { useUserContext } from "../hooks/auth/UserProvider";
import { Button } from '@mui/material'
import { BLUE_MAIN, GREEN_LOGO, GREEN_MAIN, WHITE } from "../theme/colors";
import { useTranslationContext } from "../hooks/translations/translationsProvider";

const LoginButton = () => {
  const auth = useAuth()
  const {logout} = useUserContext();
  const {t} = useTranslationContext();

  const handleLogin = async () => {
    try {
      //(!isMobile) ? await auth.signinPopup() : await auth.signinRedirect()
      await auth.signinRedirect()
    } catch (error) {
      console.error("Errore durante il login:", error);
    }
  };


  return (
    auth.isAuthenticated ? <Button onClick={()=> logout()} > {t("Disconnetti")} </Button> :
    <Button sx={{
        bgcolor: GREEN_LOGO,
        "&:hover": { bgcolor: "darkgreen" },
        color:WHITE,
        border: "2px solid " + GREEN_LOGO,
      }} 
      onClick={handleLogin} 
      disabled={auth.isAuthenticated}
    >
      {t("Accedi")}
    </Button>
  );
};

export default LoginButton;