import axios from "axios";
import { CompanyAssociationInfoRequestModel, PrivateUserInfoRequestModel, UserInfosModel } from "../../models/user/UserInfosModel";
import httpClient from "../../utilities/http-client";
import settings from "../../utilities/settings";

export const checkUserAuth = async () => {
	try {
		const { data } = await httpClient.get(`/users/check-auth`);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const fetchMe = async () => {
	try {
		const { data } = await httpClient.get<UserInfosModel>(`/users/me`);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const putMePrivate = async (model: PrivateUserInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<PrivateUserInfoRequestModel>(`/users/me`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const putMeAssociationOrCompany = async (model:CompanyAssociationInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<CompanyAssociationInfoRequestModel>(`/users/company/me`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const nationsList = async () => {
	try {
		const httpClientIdentity = axios.create({
			baseURL: settings.Domains.IdentityApi+'/api/',
			headers: {
				"content-type": "application/json",
			},
			responseType: "json",
			withCredentials: true 
		});
		const { data } = await httpClientIdentity.get("/nations/list");
		return data;
	} catch (e: any) {
		throw e?.message || e || "exception during request associations list";
	}
}