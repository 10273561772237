import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, styled, Box, TextField, IconButton } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { GRAY_LIGHT } from '../../theme/colors';
import { formatToTwoDecimals } from '../../helpers/numberFormats';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { ILastBuyTransaction } from '../../models/buy/Buy';
import { getBuyTransactions, getPdfBuyTransactions } from '../../hooks/actions/buy';
import StatusIco from './StatusIco';
import PrintIcon from '@mui/icons-material/Print';

const TableCellDefault = styled(TableCell)(({ theme }) => ({
    backgroundColor: GRAY_LIGHT,
    fontWeight:'bold'
}));

const TableCellRowsDefault = styled(TableCell)(({ theme }) => ({
    fontSize:'0.85rem',
    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
}));

const getDate365DaysAgo = (): Date => {
    const date = new Date();
    date.setDate(date.getDate() - 365);
    return date;
};

const getDateOneMonthAgo = (): Date => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); 
    return date;
};

const BuyTransactionTable: React.FC = () => {
    const [transactions, setTransactions] = useState<ILastBuyTransaction[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const {t} = useTranslationContext();
    const [dateFrom, setDateFrom] = useState<Date>(getDateOneMonthAgo());
    const [dateTo, setDateTo] = useState<Date>(new Date());

    const fetchTransactions = () => {
        getBuyTransactions(page, rowsPerPage, dateFrom, dateTo)
            .then(response => {
                setTransactions(response.transactions);
                setTotalRecords(response.totalRecords);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        if(dateFrom >= dateTo){
            setDateFrom(dayjs(dateTo).add(-1, 'day').toDate())
        }
        fetchTransactions();
    }, [page, rowsPerPage, dateFrom, dateTo]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDateFromChange = (date: dayjs.Dayjs | null) => {
        if (date) {
            setDateFrom(date.toDate()); // Converti in Date
            setPage(0); // Reset to first page on date change
        }
    };

    const handleDateToChange = (date: dayjs.Dayjs | null) => {
        if (date) {
            setDateTo(date.toDate()); // Converti in Date
            setPage(0); // Reset to first page on date change
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={t("Data Da")}
                        value={dayjs(dateTo)}
                        onChange={(date) => handleDateToChange(date)}
                        disableFuture
                        views={['day', 'month', 'year']}
                        format="DD/MM/YYYY"
                        slotProps={{
                            textField: {
                                required: true,
                                error: false,
                                helperText: ''
                            }
                        }}
                        sx={{
                            '.MuiInputBase-root': { 
                                height: 48,
                                marginRight:'8px'
                            }
                        }}
                    />
                    <DatePicker
                        label={t("Data A")}
                        value={dayjs(dateFrom)}
                        onChange={(date) => handleDateFromChange(date)}
                        disableFuture
                        views={['day', 'month', 'year']}
                        format="DD/MM/YYYY"
                        maxDate={dayjs(dateTo).subtract(1, 'day')}
                        slotProps={{
                            textField: {
                                required: true,
                                error: false,
                                helperText: ''
                            }
                        }}
                        sx={{
                            '.MuiInputBase-root': { 
                                height: 48,
                                marginLeft:'8px'
                            }
                        }}
                    />
                    <IconButton
                        onClick={() => {getPdfBuyTransactions(dateFrom, dateTo).finally()}}
                        sx={{ }}
                    >
                        <PrintIcon />
                    </IconButton>
                </LocalizationProvider>
            </Box>
            <TableContainer>
                <Table stickyHeader aria-label="transactions table">
                    <TableHead>
                        <TableRow sx={{backgroundColor:GRAY_LIGHT}}>
                            <TableCellDefault>{t("Importo Totale")}</TableCellDefault>
                            <TableCellDefault>{t("Importo Netto")}</TableCellDefault>
                            <TableCellDefault>{t("Data")}</TableCellDefault>
                            <TableCellDefault>{t("Tipo di pagamento")}</TableCellDefault>
                            <TableCellDefault>{t("Codice transazione")}</TableCellDefault>
                            <TableCellDefault>{t("Esito")}</TableCellDefault>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions && transactions.map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCellRowsDefault>{transaction.amount.toFixed(transaction.decimals)} {transaction.divisa}</TableCellRowsDefault>
                                <TableCellRowsDefault>{transaction.netAmount.toFixed(transaction.decimals)} {transaction.divisa}</TableCellRowsDefault>
                                <TableCellRowsDefault>{new Date(transaction.dataReceved).toLocaleDateString()}</TableCellRowsDefault>
                                <TableCellRowsDefault>{transaction.buyType}</TableCellRowsDefault>
                                <TableCellRowsDefault>{transaction.refCode}</TableCellRowsDefault>
                                <TableCellRowsDefault>
                                    <StatusIco status={transaction.buyStatus} />
                                </TableCellRowsDefault>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default BuyTransactionTable;